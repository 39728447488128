import { AssetMixin, MetaMixin, EmbedMixin } from 'global-mixin';
import {
  gtmHeadScriptLoader,
  gtmBodyScriptLoader,
  gTagScriptHeadLoader,
  gTagScriptLoader
} from 'assets/js/bundled-assets';
import {
  parseOneIdInfoCookie,
  getSubscriptionId,
  getSubscriptionType
} from 'global-utils';

export default {
  mixins: [AssetMixin, MetaMixin, EmbedMixin],
  async assets() {
    if (this.$route.path.match('/standalone/footer')) {
      return;
    }

    // adding logged_in user info to dataLayer for gtm
    window.dataLayer = await window.dataLayer.filter((obj) => !Object.keys(obj).includes('userId'));
    if (this.$cookies.get('oneid_info')) {
      const oneIdInfo = parseOneIdInfoCookie(atob(this.$cookies.get('oneid_info')));
      await window.dataLayer.push({
        userId: getSubscriptionId(oneIdInfo),
        userSubscriptionType: getSubscriptionType(oneIdInfo)
      });
    }

    // injecting gtm scripts
    const [$, gtmProcessorHead] = await Promise.all(gtmHeadScriptLoader());
    const [gtmProcessorBody] = await Promise.all(gtmBodyScriptLoader());

    $('head').prepend(gtmProcessorHead(this));
    $('body').prepend(gtmProcessorBody(this));

    // injecting gtag scripts
    const [gTagScriptHead] = await Promise.all(gTagScriptHeadLoader());
    const [gTagScript] = await Promise.all(gTagScriptLoader());
    $('head').prepend(gTagScriptHead(this));
    $('body').prepend(gTagScript(this));
  },
};
