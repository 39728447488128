<template>
  <div
    v-show="show"
    class="category-widget mb-4">
    <div class="mb-3">
      <div :class="description ? 'd-block' : 'd-inline-block mr-3'">
        <div
          v-if="isBranded(id)"
          class="branded-logo">
          <img :src="$cdn.resource(`gfx/${$pubCtx.name}/branding/${id}.png`)">
        </div>
        <h2
          v-else
          v-text="nameToLabel"
          class="category-name d-inline-block ff-flama fs-xxl fw-light tt-uppercase mb-3"></h2>
      </div>
      <p
        v-if="description"
        v-text="description"
        class="category-description"></p>
      <a
        v-if="url"
        :href="url"
        v-text="__('see.all.articles')"
        :class="[`category-link ff-flama fs-xs fw-medium d-inline-block mb-2`, isBranded(id) && `branded ${id}`]">
      </a>
    </div>
    <AlertButton
      v-if="subType"
      :items="[{
        name: nameToUrl,
        label: nameToLabel,
      }]"
      :sub-type="subType"
      :alert-for="`<b>${subType === 'author' ? __('get.alerted.for.author', nameToLabel.split(' ')[0]) : nameToLabel}</b>`"
      class="d-print-none mr-3 mb-md-3"/>
    <slot></slot>
  </div>
</template>
<script>
import { titleCase } from 'global-utils';
import { isBranded } from 'global-utils/vue';
import { AlertButton } from 'global-components/src/Alert';

export default {
  name: 'category-widget',
  components: {
    AlertButton,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    description: String,
    url: String,
    id: String,
    containerSelector: String,
    subType: {
      type: [String, Boolean],
      default: null
    },
    username: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    nameToLabel() {
      return titleCase(this.name.replace('_', ' '));
    },
    nameToUrl() {
      if (this.name === 'Latest News') {
        return 'latest';
      }

      if (this.subType === 'author') {
        return this.username;
      }

      return this.url ? this.url.replace('/', '') : this.name.toLowerCase().replace(' ', '_');
    }
  },
  mounted() {
    if (!this.id) {
      const containerWidth = this.$el.parentElement.clientWidth;
      this.resolveCategoryNameTypography(containerWidth);
      window.onresize = () => {
        this.resolveCategoryNameTypography(containerWidth);
      };
    } else {
      this.show = true;
    }
  },
  methods: {
    isBranded,
    resolveCategoryNameTypography(containerWidth) {
      // replace all 'I' since it doesn't take too much space for the using font
      const words = this.nameToLabel.toUpperCase()
        .replace(/I/g, '').split(' ');
      const maxChars = Math.max(...words.map(c => c.length));
      let fontSize = Math.min(35, (containerWidth / maxChars) * 1.4);
      const lineHeight = fontSize / 8 + 'rem';
      fontSize = fontSize + 'px';
      const categoryName = this.$el.querySelector('.category-name');
      categoryName.style.fontSize = fontSize;
      categoryName.style.lineHeight = lineHeight;
      this.show = true;
    },
  },
};
</script>
