<template>
  <row>
    <column
      v-for="(jobChunk, idx) in jobs"
      :key="idx"
      sm="6"
      xs="12">
      <ul class="unstyled-list">
        <li
          v-for="({ title, detailsUrl, recruiterName }, idx2) in jobChunk"
          :key="idx2"
          class="mb-5">
          <h4 class="ff-flama fs-lg fw-medium mb-3">
            <a
              :href="detailsUrl"
              target="_blank"
              rel="noopener"
              class="text-reset">
              {{ title }}
            </a>
          </h4>
          <a
            :href="detailsUrl"
            rel="noopener"
            target="_blank"
            class="text-reset ff-sueca fs-lg">
            {{ recruiterName }}
          </a>
        </li>
      </ul>
    </column>
    <column
      offset-xs="6"
      xs="6">
      <a
        v-text="__('see.all.jobs')"
        href="https://www.tradewindsjobs.com/jobs/"
        target="_blank"
        class="category-link ff-flama fs-xs fw-medium float-right float-sm-none">
      </a>
    </column>
  </row>
</template>

<script>
export default {
  name: 'job-list',
  dataResolver: 'jobsList',
  data() {
    const allJobsForList = this.$store.state.jobsList;
    const jobs = [];
    if (allJobsForList.length > 0) {
      jobs.push(allJobsForList.slice(0, 5));
      jobs.push(allJobsForList.slice(5, allJobsForList.length));
    }
    return {
      jobs
    };
  }
};
</script>
