import { isEmpty } from 'global-utils';

const alertLocalStorageKey = 'my-alert-subscriptions';
const generalSettingsKey = 'alert-general-settings';
const alertLocalStorageTtl = 900000; // 15m in milliseconds
const defaultAlertItems = {
  topics: [],
  authors: [],
  searchQueries: []
};

function setItem(value) {
  if (!isEmpty(value.topics) || !isEmpty(value.authors) || !isEmpty(value.searchQueries)) {
    localStorage.removeItem(alertLocalStorageKey);

    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + alertLocalStorageTtl
    };
    localStorage.setItem(alertLocalStorageKey, JSON.stringify(item));
  }
}

function getItem() {
  const itemStr = localStorage.getItem(alertLocalStorageKey);
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(alertLocalStorageKey);
    return null;
  }
  return item.value;
}

function clearStorage() {
  localStorage.removeItem(alertLocalStorageKey);
  localStorage.setItem(alertLocalStorageKey, JSON.stringify(defaultAlertItems));
}

function setGeneralSettings(generalSettings) {
  localStorage.setItem(generalSettingsKey, JSON.stringify(generalSettings));
}

function getGeneralSettings() {
  const generalSettingsStr = localStorage.getItem(generalSettingsKey);
  return JSON.parse(generalSettingsStr);
}

export const alertLocalStorageUtil = {
  setItem,
  getItem,
  clearStorage,
  setGeneralSettings,
  getGeneralSettings
};
