export const publicationDefaultMetadata = {
  siteTitle: 'Latest shipping and maritime news',
  description: 'Visit NhstMvp for the best news, insight and ' +
    'opinion covering the global shipping business.',
  twitterSite: '@nhstmvp'
};

export const metadataByRoute = {
  LatestNews: {
    description: '',
  },
  Weekly: {
    pageTitle: 'Weekly',
  },
};
