const Config = {
  classVariant: {
    topBorder: {
      default: 'border-top pt-2',
      carousel: 'border-0 pt-0',
      extraextraLarge: 'border-0 pt-0',
      sponsorContent: 'border-0 pt-2 highlight'
    },
    mainCategoryClasses: {
      default: 'main-category ff-flama fs-sm fw-bold mb-2 d-block text-capitalize'
    },
    kickerClasses: {
      default: '',
      medium: 'medium-kicker'
    },
    titleClasses: {
      default: '',
      extraextraLarge: 'ff-sueca-bold fs-xxl fw-bold mb-0',
      extraLarge: 'ff-sueca fs-xxl fw-bold mb-0',
      large: 'ff-sueca fs-xl fw-normal mb-0',
      medium: 'ff-sueca fs-xl fw-normal mb-1',
      mediumAlternative: 'ff-flama fs-xl fw-normal mb-0',
      carousel: 'ff-flama fs-xl fw-normal mb-0',
      sponsorContent: 'ff-arial fs-xl fs-normal mb-1 mb-sm-1'
    },
    imageClasses: {
      default: '',
      extraextraLarge: 'mb-3',
      extraLarge: 'mb-3',
      large: 'mb-2',
      medium: 'mb-2 mb-sm-0 mr-3',
      mediumAlternative: 'mb-2 mb-sm-0 mr-3',
      sponsorContent: 'mb-2 mb-sm-0 mr-3 mr-sm-2'
    },
    leadtextClasses: {
      default: '',
      extraextraLarge: 'leadtext ff-sueca fs-md mb-0 mt-4',
      extraLarge: 'leadtext ff-sueca fs-md mb-0 mt-2',
      large: 'leadtext ff-sueca fs-lg mb-0 mt-2',
      medium: 'leadtext ff-sueca fs-lg mb-0 mt-2',
      sponsorContent: 'leadtext ff-arial fs-md mt-1 mt-sm-0 mb-lg-4'
    },
    teaserBodyClasses: {
      default: '',
      // medium: 'd-flex',
      // sponsorContent: 'd-flex'
    },
    imageHidden: false,
    leadtextVisible: false
  },
  imageAlignmentVariant: {
    left: {
      teaserBodyClasses: {
        // default: 'd-flex'
      },
      imageClasses: {
        default: 'mb-3 mb-sm-0 mr-3',
        medium: 'mb-3 mb-sm-0 mr-3',
        mediumAlternative: 'mb-3 mb-sm-0 mr-3',
        sponsorContent: 'mb-3 mb-sm-0 mr-3'
      }
    },
    top: {
      teaserBodyClasses: {
        default: 'd-block'
      },
      imageClasses: {
        default: 'mb-3'
      }
    }
  }
};
export default function getTeaserConfig(teaserType, imageAlignment) {
  const teaserConfig = {};
  Object.entries(Config.classVariant).forEach(
    ([key, obj]) => {
      teaserConfig[key] = obj[teaserType] || obj.default;
    }
  );

  const alignmentConfig = {};
  if (imageAlignment) {
    Object.entries(Config.imageAlignmentVariant[imageAlignment]).forEach(
      ([key, obj]) => {
        alignmentConfig[key] = obj[teaserType] || obj.default;
      }
    );
  }

  return Object.assign({}, teaserConfig, alignmentConfig);
}
