<template>
  <div class="settings-panel">
    <div
      v-if="!noHeading"
      class="heading">
      <h3 class="fw-normal">{{ __('alert.my.settings') }}</h3>
    </div>
    <div class="content p-0 px-md-3 pt-md-4">
      <AlertGeneralSettings v-if="isUserLoggedIn" />
      <AlertSearch class="my-5"/>
      <AlertSpinner :show="settingsLoading"/>
      <DefaultSubscriptions
        v-if="showDefaultSubscriptions"
        class="subscriptions-default" />
      <div v-else>
        <div
          v-for="(subscription, topic) in subscriptions"
          :key="topic"
          :style="settingsLoading && settingsBlurStyle">
          <div
            v-if="!$options.isEmpty(subscription)"
            class="mb-5 mt-2">
            <h5 class="ff-flama fs-lg fw-light tt-uppercase pb-1">
              {{ __(subscriptionTopicMaps[topic]) }}
            </h5>
            <template v-for="subElem in subscription">
              <div
                :key="subElem.term"
                class="settings-elem pb-3 my-3">
                <SubscriptionSettings
                  :settings-element="subElem"
                  :term-type="topic"/>
              </div>
            </template>
          </div>
          <template
            v-else
            class="mb-4 mt-4">
            <component :is="defaultTopicComponent(topic)"/>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionSettings from './SubscriptionSettings';
import DefaultSubscriptions from './DefaultSubscriptions';
import AlertSearch from './AlertSearch';
import AlertSpinner from './AlertSpinner';
import DefaultTopics from './DefaultTopics';
import DefaultAuthors from './DefaultAuthors';
import { AlertGeneralSettings } from './AlertGeneralSettings';

import {
  alertLocalStorageUtil,
  AlertSubscriptionClient,
  isEmpty,
  EventBus,
  syncAlertTopicSubType,
  checkUserLoginCookie,
} from 'global-utils';

export default {
  name: 'settings-panel',
  components: {
    AlertGeneralSettings,
    SubscriptionSettings,
    DefaultSubscriptions,
    AlertSearch,
    AlertSpinner,
    /* eslint-disable vue/no-unused-components */
    DefaultTopics,
    DefaultAuthors
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    noHeading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      settingsLoading: false,
      subscriptions: {},
      showDefaultSubscriptions: false,
      subscriptionTopicMaps: {
        topics: 'topics',
        authors: 'authors',
        searchQueries: 'more'
      },
      settingsBlurStyle: {
        '-webkit-filter': `blur(5px)`,
        '-moz-filter': `blur(5px)`,
        '-ms-filter': `blur(5px)`,
        '-o-filter': `blur(5px)`,
        filter: `blur(5px)`,
      },
      alertTopics: this.$store.state.alertTopics,
      isUserLoggedIn: checkUserLoginCookie(this),
    };
  },
  async beforeMount() {
    this.alertSubscriptionClient = new AlertSubscriptionClient(this);
    await this.initSettings();
  },
  async mounted() {
    EventBus.$on('alert:refresh', () => this.initSettings());
  },
  methods: {
    async initSettings() {
      this.settingsLoading = true;
      try {
        let subscriptionData = await alertLocalStorageUtil.getItem();
        if (!subscriptionData) {
          if (checkUserLoginCookie(this)) {
            subscriptionData = await this.alertSubscriptionClient.fetch();
          }
          if (isEmpty(subscriptionData.topics) && isEmpty(subscriptionData.authors) && isEmpty(subscriptionData.searchQueries)) {
            // load empty forms with text
            this.showDefaultSubscriptions = true;
          }
        } else {
          if (!isEmpty(subscriptionData.topics)) {
            subscriptionData.topics = subscriptionData.topics.map(val => ({ ...val, subType: syncAlertTopicSubType(this.alertTopics, val).subType }));
          }
          this.showDefaultSubscriptions = false;
          this.subscriptions = subscriptionData;
        }
      } catch (e) {
        // load empty forms with text
        this.showDefaultSubscriptions = true;
      }

      this.settingsLoading = false;
    },
    defaultTopicComponent(topic) {
      return topic === 'topics' ? DefaultTopics : (topic === 'authors' ? DefaultAuthors : '');
    }
  },
  isEmpty(val) {
    return isEmpty(val);
  }
};
</script>
<style lang="scss">
.settings-panel {
  .heading {
    border-bottom: 3px solid;
    @include borderColor('primary');
  }
  .content {
    background-color: white;
  }
}
</style>
