export const publicationDefaultMetadata = {
  siteLabel: 'Europower',
  siteTitle: 'Siste nyheter fra fornybarbransjen',
  description: `Besøk Europower for den beste nyhetsdekningen av kraft- og energibransjen`,
  twitterSite: '@europower',
};

const alertsPagesMeta = () => {
  return {
    title() {
      return () => 'Mine varsler – skreddersydde nyheter for abonnenter på Europower';
    }
  };
};
export const metadataByRoute = {
  LatestNews: {
    description: 'Les de viktigste analysene og nyhetene om kraftbransjen.',
  },
  Alerts: alertsPagesMeta(),
  AlertsAuthDenied: alertsPagesMeta(),
  AlertsAuthFailed: alertsPagesMeta(),
  AlertsTooManySessions: alertsPagesMeta(),
};
