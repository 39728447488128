<template>
  <div :class="[$options.name, $props.styleClass]">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'card-title',
  props: {
    styleClass: String
  }
};
</script>
