import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import search from '../server/modules/search';

Vue.use(Vuex);

export function createStore() {
  return new Vuex.Store({
    state: {
      error: null,
      curPlaceholderIdx: 0,
    },
    actions,
    mutations,
    getters,
    modules: {
      search,
    },
  });
}

export default createStore;
