export const publicationDefaultMetadata = {
  siteLabel: 'Upstream Online',
  siteTitle: 'Latest oil and gas news',
  description: 'The leading global oil, gas and energy news resource. Covering the latest oil and gas news including shale, lng, drilling, exploration and production.',
  twitterSite: '@UpstreamOnline',
  fbAppId: '',
};

const coronavirusMetadata = {
  pageTitle: 'Coronavirus Live news',
  description: 'Visit the Upstream Live Centre for the latest news and information on how the coronavirus pandemic is impacting the global oil and gas business and discover what it means for one of the world’s most important industries.',
};

export const metadataByRoute = {
  Lng: {
    pageTitle: 'Liquefied Natural Gas (LNG)',
  },
  LatestNews: {
    description: 'Read the key news headlines covering the global oil and gas business.'
  },
  Hardcopy: {
    pageTitle: 'Weekly',
  },
  Exclusive: {
    description: 'Read exclusive news and insight involving the world\'s leading energy companies.'
  },
  Coronavirus: coronavirusMetadata,
  CoronavirusAuthDenied: coronavirusMetadata,
  CoronavirusFailed: coronavirusMetadata,
  CoronavirusTooManySession: coronavirusMetadata,
};
