import { EventBus } from './EventBus';
import {
  AlertSubscriptionClient,
  alertLocalStorageUtil,
  _isNil,
  checkUserLoginCookie
} from 'global-utils';

const defaultAlertItems = {
  topics: [],
  authors: [],
  searchQueries: []
};

EventBus.$on('sync:alerts', async (vm) => {
  if (checkUserLoginCookie(vm)) {
    const subscriptionData = await alertLocalStorageUtil.getItem();
    if (!subscriptionData || (
      _isNil(subscriptionData.topics) &&
      _isNil(subscriptionData.authors) &&
      _isNil(subscriptionData.searchQueries)
    )) {
      const alertSubscriptionClient = new AlertSubscriptionClient(vm);
      try {
        await alertSubscriptionClient.fetch();
      } catch (e) {
        console.error(e);

        EventBus.$emit('alert:refresh');
      }
    }
  }
});

EventBus.$on('sync:alerts:fetched', async (subscriptionData) => {
  await alertLocalStorageUtil.setItem(subscriptionData);

  EventBus.$emit('alert:refresh');
  EventBus.$emit('newsPanel:refresh');
});

EventBus.$on('sync:alerts:saved', async (updates) => {
  await updateLocalData(updates, 'save');
});

EventBus.$on('sync:alerts:updated', async (updates) => {
  await updateLocalData(updates);
});

EventBus.$on('sync:alerts:deleted', async (updates) => {
  await updateLocalData(updates);
});

async function updateLocalData(updates, op = '') {
  let subscriptionData = await alertLocalStorageUtil.getItem();
  subscriptionData = subscriptionData || defaultAlertItems;
  subscriptionData[updates.type] = updates.data;
  await alertLocalStorageUtil.clearStorage();
  await alertLocalStorageUtil.setItem(subscriptionData);

  EventBus.$emit('alert:refresh');
  // if (op !== 'save') {
  EventBus.$emit('newsPanel:refresh');
  // }
}
