import { _isNil } from 'global-utils';

const myCompanyAccessKey = 'my-company-access';
const myCompanyAccessTtl = 1800000; // 30m in milliseconds

function setItem(value) {
  if (!_isNil(value)) {
    localStorage.removeItem(myCompanyAccessKey);

    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + myCompanyAccessTtl
    };
    localStorage.setItem(myCompanyAccessKey, JSON.stringify(item));
  }
}

function getItem() {
  const itemStr = localStorage.getItem(myCompanyAccessKey);
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(myCompanyAccessKey);
    return null;
  }
  return item.value;
}

function clearStorage() {
  localStorage.removeItem(myCompanyAccessKey);
}

export const companyAccessLocalStorageUtil = {
  setItem,
  getItem,
  clearStorage
};
