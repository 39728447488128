export const publicationDefaultMetadata = {
  siteTitle: 'Latest renewable energy news',
  description: 'Read Recharge for the best news, analysis and opinion covering the renewable energy transition, led by wind and solar',
  twitterSite: '@rechargenews'
};

export const metadataByRoute = {
  LatestNews: {
    description: 'Read the key news headlines covering the global renewable energy business.'
  }
};
