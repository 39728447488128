export default
{
  responsive: {
    0: {
      items: 2,
      nav: true,
      loop: true
    },
    576: {
      items: 2.5,
      nav: true,
      loop: true
    },
    768: {
      items: 3.5,
      nav: true,
      loop: true
    },
    992: {
      items: 4,
      nav: true,
      loop: true
    },
    1272: {
      items: 6,
      nav: true,
      loop: true
    }
  }
};
