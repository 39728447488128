<template>
  <div class="native-paywall mb-2">
    <div class="plus-ico mb-2"><img :src="`${$cdn.resource(`gfx/plusIco.svg`)}`"></div>
    <div class="main-text ff-flama mb-2">{{ __('paywall.native.title', $pubCtx.label) }}</div>
    <div class="sub-text ff-flama mb-2">{{ __('paywall.native.subTitle', $pubCtx.domain) }}</div>
  </div>
</template>

<script>
export default {
  name: 'native-paywall',
  props: {
    targetQuery: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
  .native-paywall {
    /* width: 390px; */
    /* height: 245px; */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    padding: 3rem;
    margin: 0px auto;

    .plus-ico {
      /* height: 67px; */
      /* width: 67px; */
    }

    .main-text {
      color: $black;
      font-size: 2.4rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 2.7rem;
      text-align: center;
    }

    .sub-text {
      color: $black;
      font-size: 1.8rem;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 2.2rem;
      text-align: center;
    }
  }
</style>
