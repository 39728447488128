<template>
  <Modal
    v-if="show"
    v-bind="$attrs"
    @close="$emit('close')"
    class="ff-flama">
    <template v-slot:header>
      <div class="alert-header">
        <FontAwesomeIcon :icon="icons.faBell"/>
        {{ __('get.alerted') }}
      </div>
    </template>
    <template v-slot:body>
      <slot></slot>
    </template>
  </Modal>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
export default {
  name: 'alert-modal',
  components: { FontAwesomeIcon },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        faBell,
      },
    };
  },
};
</script>
