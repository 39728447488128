export default {
  async mounted() {
    if (typeof this.$options.assets === 'function') {
      try {
        await this.$options.assets.apply(this);
      } catch (e) {
        console.error(`AssetMixinError on ${this.$options.name}`, e);
        throw e;
      }
    }
  }
};
