<template>
  <div
    v-if="items.length"
    class="action-menu-footer d-flex justify-content-center">
    <div class="action-menu-footer-container d-flex flex-wrap justify-content-between">
      <div
        v-for="(item, idx) in items"
        :key="item.label"
        :class="['action-menu-footer-item py-4', idx === 0 ? 'pr-4' : idx + 1 === items.length ? 'pl-4' : 'px-4']">
        <a :href="item.href">
          {{ item.label }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'action-menu-footer-item',
  data() {
    return {
      items: this.$pubCtx.menuConfigs.dropdownMenuFooterItems || [],
    };
  }
};
</script>
<style lang="scss">
.action-menu-footer {
  .action-menu-footer-container {
    width: 1272px;
  }
}
</style>
