<template>
  <div class="option-field">
    <label>{{ label }}</label>
    <div
      v-for="option in options"
      :key="option">
      <input
        :id="option"
        :name="name"
        @change="$emit('input', $event.target.value)"
        :value="option"
        type="radio">
      <label
        :for="option"
        class="option">{{ option }}</label>
    </div>
    <div
      v-for="msg in messages"
      :key="msg"
      class="error message">{{ msg }}</div>
  </div>
</template>
<script>
export default {
  name: 'option-field',
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    messages: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      name: Math.random().toString(36).substring(10),
    };
  },
};
</script>
