<template>
  <div class="pagination ff-flama border-top pt-2 mb-2">
    <row>
      <column
        sm="2"
        xs="3">
        <div
          :class="['fw-medium fs-md text-uppercase previous', { 'd-none': isFirstPage }]"
          @click="prev">
          <FontAwesomeIcon
            :icon="icons.faAngleLeft"/>
          {{ __('previous') }}
        </div>
      </column>
      <column
        sm="8"
        xs="6"
        class="text-center fs-sm my-auto">
        {{ __('showing.search.results', offset + 1, offset + size, total.toLocaleString("nb-NO")) }}
      </column>
      <column
        sm="2"
        xs="3">
        <div
          :class="['fw-medium fs-md text-uppercase text-right next', { 'd-none': isLastPage }]"
          @click="next">
          {{ __('next') }}
          <FontAwesomeIcon
            :icon="icons.faAngleRight"/>
        </div>
      </column>
    </row>
  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';

export default {
  name: 'archive-pagination',
  components: {
    FontAwesomeIcon,
  },
  props: {
    max: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      icons: {
        faAngleLeft,
        faAngleRight,
      },
    };
  },
  computed: {
    offset() {
      return this.$store.state.search.query.offset;
    },
    size() {
      return this.$store.state.search.query.size;
    },
    total() {
      return this.$store.state.search.query.total;
    },
    isFirstPage() {
      return this.offset < 1;
    },
    isLastPage() {
      return this.offset + this.max >= this.total;
    },
  },
  methods: {
    prev() {
      if (this.isFirstPage) {
        return false;
      }
      this.update(this.offset - this.max);
    },
    next() {
      if (this.isLastPage) {
        return false;
      }
      this.update(this.offset + this.max);
    },
    update(offset = 0) {
      this.$store.commit('search/page', { offset });
      this.$emit('update');
    }
  }
};
</script>
