export function loadScript(url, callback) {
  $.ajax({
    url: url,
    dataType: 'script',
    cache: true
  }).done(callback);
}

const browserModulesMap = {
  jQuery(resolve) {
    return import('jquery').then(jQuery => {
      window.jQuery = window.$ = jQuery;
      resolve(jQuery);
    });
  }
};

export function loadBrowserModules(parent, router, store) {
  if (typeof parent.browserModules === 'object') {
    for (const [name, resolver] of Object.entries(parent.browserModules)) {
      if (typeof browserModulesMap[name] !== 'function') {
        throw Error(`Browser module '${name}' not found for component '${parent.name}'.`);
      }
      browserModulesMap[name](resolver, router, store);
    }
  }
  if (!parent.components) return;
  for (const [, child] of Object.entries(parent.components)) {
    if (child) {
      loadBrowserModules(child);
    }
  }
}
