<template>
  <img
    :loading="eager ? 'eager' : 'lazy'"
    :src="source"
    :srcset="sourceSet"
    :class="['lazy-image', isLoaded && 'loaded']"
    @load="processLoadedImage">
</template>

<script>
export default {
  name: 'lazy-image',
  props: {
    sourceSet: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: true
    },
    placeholder: {
      type: [String, Boolean],
      default: false
    },
    eager: Boolean,
    isPortrait: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoaded: false
    };
  },
  methods: {
    processLoadedImage() {
      this.isLoaded = true;
      const orientation = (this.isPortrait || (this.$el.naturalWidth < this.$el.naturalHeight)) ? 'portrait' : 'landscape';
      if (this.isPortrait) {
        this.$emit('naturalHeight', (178 / this.$el.width) * this.$el.height);
      } else {
        this.$emit('naturalHeight', this.$el.height);
      }
      this.$emit('orientation', orientation);
      this.$emit('load', this);
    }
  }
};
</script>
