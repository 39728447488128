<template>
  <div
    v-if="mounted && !$pubCtx.isFreePublication && !isUserSubscribed"
    class="subscribe-menu-item io-subscription-block mr-3">
    <a
      :href="subscriptionUrl.href"
      :target="isStandaloneView ? '_parent' : false"
      v-text="__('subscribe')"
      class="fs-md io-subscription-button">
    </a>
  </div>
</template>
<script>
import { isUserSubscribed } from 'global-utils';

export default {
  name: 'subscribe-menu-item',
  inject: ['isStandaloneView'],
  data() {
    return {
      isUserSubscribed: isUserSubscribed(this),
      mounted: false
    };
  },
  computed: {
    subscriptionUrl() {
      return this.$pubCtx.menuConfigs.subscription;
    },

  },
  mounted() {
    this.isUserSubscribed = isUserSubscribed(this);
    this.mounted = true;
  }
};
</script>

<style lang="scss" scoped>
  .subscribe-menu-item {
    margin-top: 0.1rem;
  }
  .subscribe-menu-item a {
    padding-right: 1.2rem;
    border-right: 1px solid #363;
  }
</style>
