<template>
  <AlertSafeguard
    v-if="!isMobileView && items.length"
    :class="[mini && 'd-inline', 'fw-normal']">
    <span :class="['alert-btn', mini && 'mini mx-2', !mini && 'btn btn-secondary']">
      <span
        v-if="alreadySubscribedCondition"
        @click="showAlreadySubscribed">
        <FontAwesomeIcon
          v-if="mini"
          :icon="icons.faBellSolid"
          :title="__('alert.already.created')"
          width="12px"
          class="icon-blue-alert"/>
        <FontAwesomeIcon
          v-if="!mini"
          :icon="icons.faCheck"
          width="12px"/>
        <span v-if="!mini">{{ __('alert.already.created') }}</span>
      </span>
      <span
        v-else
        @click="showModal = true">
        <FontAwesomeIcon
          :icon="icons.faBell"
          width="12px"/>
        <template v-if="!mini">
          <span
            v-if="alertFor"
            v-html="__('alert.me.about', alertFor)"></span>
          <span
            v-else
            v-html="__('alert.me')"></span>
        </template>
      </span>
    </span>
    <AlertModal
      :show="showModal"
      @close="showModal = false">
      <AlertOptionChooser
        :items="items"
        :subscibed-items="alreadySubscribed"
        :sub-type="(subType === 'author') ? 'authors' : subType"
        @confirm="confirmSubscription"
        @confirmError="handleConfirmError"/>
    </AlertModal>
    <AlertConfirmModal
      :show="showConfirmModal"
      :show-confirm="showConfirmModal"
      :show-success="confirmSuccess"
      :show-already-subscribed="alreadySubscribedMsg"
      @close="showConfirmModal = false">
    </AlertConfirmModal>
  </AlertSafeguard>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faBell, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faBell as faBellSolid } from '@fortawesome/pro-solid-svg-icons';
import AlertModal from './AlertModal';
import AlertConfirmModal from './AlertConfirmModal';
import AlertOptionChooser from './AlertOptionChooser';
import AlertSafeguard from './AlertSafeguard';
import { alertLocalStorageUtil, isEmpty, EventBus } from 'global-utils';

export default {
  name: 'alert-button',
  components: {
    AlertModal,
    AlertConfirmModal,
    AlertOptionChooser,
    AlertSafeguard,
    FontAwesomeIcon
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    subType: {
      type: String,
      default: 'tag'
    },
    alertFor: {
      type: String,
      default: 'this',
    }
  },
  inject: ['isMobileView'],
  data() {
    return {
      showModal: false,
      showConfirmModal: false,
      alreadySubscribedCondition: false,
      alreadySubscribedMsg: '',
      confirmSuccess: true,
      alreadySubscribed: [],
      icons: {
        faBell,
        faCheck,
        faBellSolid,
      },
    };
  },
  async mounted() {
    EventBus.$on('alert:refresh', async () => {
      await this.initButton();
    });

    await this.initButton();
  },
  methods: {
    async initButton() {
      const subscriptionData = await alertLocalStorageUtil.getItem();
      if (subscriptionData && (
        !isEmpty(subscriptionData.topics) ||
          !isEmpty(subscriptionData.authors) ||
          !isEmpty(subscriptionData.searchQueries)
      )) {
        const subType = (this.subType === 'author') ? 'authors' : 'topics';
        const subscribedItems = (!isEmpty(subscriptionData[subType]))
          ? subscriptionData[subType].map(subItem => subItem.term)
          : [];

        const alertItems = this.items.map(item => item.name);
        const alreadySubscribedItems = this.intersected(alertItems, subscribedItems);
        const alreadySubscribedCondition = (JSON.stringify(alreadySubscribedItems) === JSON.stringify(alertItems));

        this.alreadySubscribed = alreadySubscribedCondition ? [] : alreadySubscribedItems;
        this.alreadySubscribedCondition = alreadySubscribedCondition;
      }
    },
    confirmSubscription(confirmData) {
      this.showModal = false;
      this.showConfirmModal = true;
      this.confirmSuccess = true;
    },
    handleConfirmError(err) {
      console.error('something went wrong saving subscription: ', err);
      this.showModal = false;
      this.showConfirmModal = true;
      this.confirmSuccess = false;
    },
    intersected(a, b) {
      return a.filter(x => b.includes(x));
    },
    showAlreadySubscribed() {
      this.showConfirmModal = true;
      this.confirmSuccess = false;
      this.alreadySubscribedMsg = this.subType;
    }
  }
};
</script>
<style lang="scss">
  .alert-btn {
    font-size: 1.2rem !important;

    &.mini {
      display: inline-block;
      cursor: pointer;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid;
        border-radius: 2px;
      }
    }
  }
  .alert-header {
    font-size: 3rem;
    svg {
      font-size: 3rem !important;
    }
  }
  .main-article {
    .alert-btn {
      font-size: 1.4rem;
    }
  }
</style>
