<template>
  <div
    :class="[
      $options.name,
      {[`${styleClass}`]:styleClass}]">
    <span
      v-if="this.$store.state.article.locations.length"
      class="prefix">
     &nbsp;{{ __('in') }}&nbsp;
    </span>
    <span v-html="listLocations()"></span>
  </div>
</template>

<script>
export default {
  name: 'article-location',
  props: {
    styleClass: String
  },
  methods: {
    listLocations() {
      var locations = '';
      for (var i = 0; i < this.$store.state.article.locations.length; i++) {
        if (i > 0) {
          locations += i === this.$store.state.article.locations.length - 1 ? this.__('and') : ', ';
        }
        locations += `&nbsp;<span class="fw-medium">&nbsp;${this.$store.state.article.locations[i]}&nbsp;</span>`;
      }
      return locations;
    }
  }
};
</script>
