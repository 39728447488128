<template>
  <Carousel
    :options="$options.defaultOptions"
    class="teaser-carousel">
    <card
      v-for="{title, image, date, url} in items"
      :key="combineKeys(date, title)">
      <span
        v-if="isAuthDenied"
        class="text-reset text-center">
        <card-image
          :source="image"
          :alt="title"/>
        <span
          v-if="showTitle === true"
          class="ff-flama fs-sm">{{ decodeTitle(title) }}</span>
        <span class="ff-flama fs-sm">{{ formatDate(date) }}</span>
      </span>
      <a
        v-else
        :href="generateLinks(url, date)"
        class="text-reset text-center"
        target="_blank">
        <card-image
          :source="image"
          :alt="title"/>
        <span
          v-if="showTitle === true"
          class="ff-flama fs-sm">{{ decodeTitle(title) }}</span>
        <span class="ff-flama fs-sm">{{ formatDate(date) }}</span>
      </a>
    </card>
  </Carousel>
</template>
<script>
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { decodeTitle, moment } from 'global-utils';

export default {
  name: 'edition-carousel',
  props: {
    items: Array,
    isAuthDenied: Boolean,
    showTitle: Boolean,
    requireLogin: Boolean
  },
  defaultOptions: {
    items: 6,
    loop: true,
    margin: 20,
    nav: true,
    dots: false,
    rewind: true,
    autoWidth: false,
    navText: [
      icon(faAngleLeft).html,
      icon(faAngleRight).html
    ],
    responsive: {
      0: {
        items: 1.5,
        nav: true
      },
      576: {
        items: 3,
        nav: true,
        loop: false
      },
      768: {
        items: 5,
        nav: true,
        loop: false
      },
      1272: {
        items: 7,
        nav: true,
        loop: false
      }
    }
  },
  methods: {
    decodeTitle: decodeTitle,
    generateLinks: function (url, date) {
      return url + '&requireLogin=' + this.requireLogin + '&date=' + moment(date).format('YYYY-MM-DD');
    },
    combineKeys: function (date, title) {
      return (date + '-' + title).replace(/ /gi, '-');
    },
    formatDate(date) {
      moment.locale(this.$store.state.publication.language);
      return moment(date).format('D MMM YYYY');
    }
  }
};
</script>
