import moments from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min';

moments.updateLocale('no', {
  months: [
    'januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'
  ],
  monthsShort: [
    'Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'aug', 'sept', 'okt', 'nov', 'des'
  ]
});

export const moment = moments;
