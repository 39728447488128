<template>
  <row class="align-items-lg-center">
    <column
      v-for="(footerItems) in footerMenu"
      :key="footerItems.order"
      :class="footerItems.class">
      <ul class="unstyled-list d-flex">
        <li
          v-for="(item) in filterMenuItems(footerItems.items)"
          :key="item.order"
          :class="item.class">
          <a
            v-if="item.href"
            :href="item.href"
            target="_blank"
            class="text-reset">
            <FontAwesomeIcon
              v-if="item.icon"
              :icon="icons[item.icon]"/>
            <span class="label">{{ item.label }}</span>
          </a>
          <div v-else>
            <span class="label">{{ item.label }}</span>
            <span class="text">{{ item.text }}</span>
          </div>
        </li>
      </ul>
    </column>
  </row>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
export default {
  name: 'footer-menu',
  components: {
    FontAwesomeIcon
  },
  inject: ['isStandaloneView'],
  data() {
    return {
      icons: {
        facebookIcon: faFacebookF,
        twitterIcon: faTwitter,
        linkedinIcon: faLinkedinIn,
        youtubeIcon: faYoutube,
        instagramIcon: faInstagram
      },
      footerMenu: this.$store.state.publication.menuConfigs.footer,
    };
  },
  methods: {
    filterMenuItems(items) {
      return items.filter(item => !this.isStandaloneView || item.class !== 'cookies-popup-trigger');
    }
  }
};
</script>
