<template>
  <div
    v-if="showEpaperPromobox && image"
    class="promobox py-2 px-3 d-flex">
    <div class="promobox-left-column d-flex flex-column mr-3">
      <span
        v-text="__('now.online')"
        class="ff-flama fs-xs fw-medium tt-uppercase btn-link d-block mb-3">
      </span>
      <span
        v-text="__('click.read.latest.edition', __('epaper.promo.text') || publicationLabel)"
        class="ff-flama fs-md d-block mb-3">
      </span>
      <div class="mt-auto">
        <a
          :href="url"
          v-text="__('click.here')"
          class="btn btn-sm btn-warning tt-uppercase"
          target="_blank">
        </a>
      </div>
    </div>
    <a
      :href="url"
      target="_blank">
      <img :src="image">
    </a>
  </div>
</template>

<script>
export default {
  name: 'promo-box',
  dataResolver: 'promoBox',
  data() {
    return {
      ...(this.$store.state.promobox.items && this.$store.state.promobox.items[0]),
      publicationLabel: this.$store.state.publication.label,
      showEpaperPromobox: this.$store.state.publication.showEpaperPromobox,
    };
  }
};
</script>
