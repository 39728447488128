<template>
  <footer class="container-fluid site-footer d-print-none">
    <div class="wrapper ff-flama">
      <EditorialResponsibility/>
      <FooterMenu/>
    </div>
    <PrivacyModal v-if="!isStandaloneView"/>
    <div
      v-show="!cookieUsageNotified && !enableOneTrust"
      class="cookie-info">
      <div class="wrapper pt-2 pb-3">
        <row>
          <column
            class="ff-flama"
            xs="10"
            offset="2">
            <h2
              v-text="__('privacy.cookie')"
              class="fs-lg fw-medium mb-0"></h2>
            <p
              v-html="__('cookie.usage.info', $store.state.publication.label)">
              class="fs-md"></p>
            <a
              :href="readMoreLink"
              :target="isStandaloneView ? '_parent' : false"
              v-text="__('read.more')"
              class="btn btn-secondary-dark float-left mr-4 text-uppercase"></a>
            <button
              @click="setCookieAccepted"
              v-text="__('ok.understand')"
              class="btn btn-primary-dark float-left text-uppercase"></button>
          </column>
        </row>
      </div>
    </div>
  </footer>
</template>

<script>
import { FooterMenu } from './action';
import EditorialResponsibility from './EditorialResponsibility';
const resetAcceptedCookiesBeforeTime = 1585119345863; // 25th March 2020
const notifiedCookieName = 'cookieUsageNotified';
const notifiedCookieTimeName = 'cookieUsageNotificationAcceptedAt';

export default {
  components: {
    PrivacyModal: () => import('global-components').then(m => m.PrivacyModal),
    EditorialResponsibility,
    FooterMenu
  },
  inject: ['isStandaloneView'],
  data() {
    return {
      cookieUsageNotified: true,
      readMoreLink: this.$store.state.publication.readMorePrivacyLink,
      enableOneTrust: this.$pubCtx.enableOneTrust
    };
  },
  mounted() {
    this.validateAcceptedCookie();
  },
  methods: {
    setCookieAccepted() {
      localStorage.setItem(notifiedCookieName, 'true');
      localStorage.setItem(notifiedCookieTimeName, `${new Date().getTime()}`);
      this.cookieUsageNotified = true;
    },
    validateAcceptedCookie() {
      const hasAcceptedCookie = localStorage.getItem(notifiedCookieName) === 'true';
      const cookieAcceptanceTime = localStorage.getItem(notifiedCookieTimeName) || false;
      const hasAcceptedAfterResetTime =
        cookieAcceptanceTime
          ? Number.parseInt(cookieAcceptanceTime) > resetAcceptedCookiesBeforeTime
          : false;
      this.cookieUsageNotified = hasAcceptedAfterResetTime && hasAcceptedCookie;
    },
  },
};
</script>
