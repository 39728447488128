<template>
  <section
    v-if="Object.keys(jobs).length"
    :class="['section_highlight jobs-block mb-3 pt-4 pb-4', isBranded && 'branded']">
    <div class="wrapper">
      <!--      nonbranded job header starts-->
      <row v-if="!isBranded">
        <column sm="12">
          <div class="mb-4 d-flex">
            <h2
              class="category-name ff-flama fs-xxl fw-light tt-uppercase mb-0 mr-4">
              {{ __('jobs') }}
            </h2>
            <a
              :href="link"
              v-text="__('see.all.jobs')"
              class="category-link ff-flama fs-xs fw-medium mb-2">
            </a>
          </div>
        </column>
      </row>
      <!--       nonbranded job header ends-->

      <!--      branded job header starts-->
      <row
        v-else
        class="branded-job">
        <column sm="12">
          <div class="mb-4 d-flex flex-sm-row flex-column justify-content-between">
            <div>
              <img
                :src="this.$cdn.resource(`gfx/brandedJobs/${logo}.png`)"
                :alt="logo"
                class="branded-job-image">
            </div>
            <div class="d-flex flex-row mt-sm-0 mt-4">
              <button
                v-if="buyAdLink"
                class="branded-job-btn1 btn btn-secondary mr-4" >
                <a
                  :href="`${buyAdLink}`"
                  target="_blank">
                  {{ __('buy.ad') }}
                </a>
              </button>
              <button
                v-if="link"
                class="branded-job-btn2 btn btn-secondary">
                <a
                  :href="link"
                  target="_blank">
                  {{ __('view.all.positions') }}
                </a>
              </button>
            </div>
          </div>
        </column>
        <column sm="12">
          <div class="mb-3 text-center">
            <Ad
              :ad-label="__('position.of.the.week')"
              :ad-label-link="`${buyAdLink}`"
              slot-id="jobCarousel"/>
          </div>
        </column>
      </row>
      <!--      branded job header ends-->

      <row>
        <column sm="12">
          <Carousel
            :options="carouselOptions()"
            @change="forceEnableNav"
            class="jobs-carousel owl-carousel owl-theme teaser-carousel ff-flama">
            <div
              v-for="({title, detailsUrl, logoUrl, recruiterName}, idx) in jobs"
              :key="idx"
              class="item p-2">
              <a
                :href="detailsUrl"
                target="_blank"
                rel="noopener">
                <img
                  :src="logoUrl"
                  :alt="title"
                  class="loading job-logo">
              </a>
              <h2 class="text-center fs-md fw-medium mb-1">
                <a
                  :href="detailsUrl"
                  rel="noopener"
                  target="_blank"
                  class="text-reset">
                  {{ title }}
                </a>
              </h2>
              <p class="text-center fs-sm mb-0">
                <a
                  :href="detailsUrl"
                  rel="noopener"
                  target="_blank"
                  class="text-reset">
                  {{ recruiterName }}
                </a>
              </p>
            </div>
          </Carousel>
        </column>
      </row>
    </div>
  </section>
</template>
<script>
import { AssetMixin } from 'global-mixin';
import { Ad } from 'global-elements';
import * as allCarouselOptions from './carouselOptions';
export default {
  name: 'job-carousel-placeholder',
  // eslint-disable-next-line vue/no-unused-components
  components: { Ad },
  mixins: [AssetMixin],
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    jobCarouselData: {
      type: Array,
      default: () => []
    },
    isPlaceholder: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      jobs: this.isPlaceholder ? this.info.items : this.jobCarouselData,
      link: (this.isPlaceholder && this.info) ? this.info.link : this.$pubCtx.jobCarouselLink,
      ...this.getBrandedJobsData()
    };
  },
  methods: {
    carouselOptions() {
      const defaultOptions = {
        loop: true,
        items: 6,
        nav: true,
        autoplay: true,
        startPosition: 0,
        autoplayTimeout: 3000,
        slideBy: 1,
        slideSpeed: 3000,
        responsive: {
          0: {
            items: 1.5,
            nav: true,
            loop: true
          },
          576: {
            items: 2,
            nav: true,
            loop: true
          },
          768: {
            items: 3,
            nav: true,
            loop: true
          },
          992: {
            items: 6,
            nav: true,
            loop: true
          },
          1272: {
            items: 6,
            nav: true,
            loop: true
          }
        }
      };
      return Object.assign(defaultOptions, this.isBranded && this.brandName && allCarouselOptions[`${this.brandName}CarouselOption`]);
    },
    forceEnableNav(vm, _, $) {
      $(vm).find('.owl-nav').removeClass('disabled');
    },
    getBrandedJobsData() {
      return this.$pubCtx.brandedJobs;
    }
  }
};
</script>
