export const publicationDefaultMetadata = {
  siteLabel: 'IntraFish.no',
  siteTitle: 'De siste nyhetene om oppdrettsnæringen.',
  description: 'Besøk IntraFish for å få den beste nyhetsdekningen av og innsikten i den norske og internasjonale oppdrettsnæringen.',
  twitterSite: '@IntraFishNorge'
};

export const metadataByRoute = {
  LatestNews: {
    description: 'Les de viktigste nyhetene om sjømatnæringen.',
  }
};
