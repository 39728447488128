<template>
  <span
    v-if="date && date.length"
    :class="styleClass">
    <span
      v-if="prefix"
      :class="['prefix', prefixStyleClass]">{{ prefix }}&nbsp;
    </span>
    {{ formattedDate }}
  </span>
</template>

<script>
import { dateByPublication } from 'global-utils';

export default {
  name: 'article-date',
  props: {
    styleClass: {
      type: String
    },
    prefixStyleClass: {
      type: String
    },
    showPublishPrefix: {
      type: Boolean,
      default: false
    },
    showUpdatePrefix: {
      type: Boolean,
      default: false
    },
    date: {
      type: String,
      required: true
    },
    format: {
      type: String,
      default: 'D MMMM YYYY H:mm'
    },
  },
  data() {
    return {
      showTimeZone: this.$store.state.publication.showTimeZone,
      zone: this.$store.state.publication.timeZone,
      locale: this.$store.state.publication.language,
    };
  },
  computed: {
    formattedDate() {
      const { date, showTimeZone } = this;
      return dateByPublication(this.$pubCtx, date, { includeZone: showTimeZone }).format();
    },
    prefix() {
      return this.showPublishPrefix ? this.__('published') : this.showUpdatePrefix ? this.__('updated') : '';
    },
  }
};
</script>
