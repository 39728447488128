export default [
  {
    numberOfArticles: 11
  },
  {
    numberOfArticles: 8
  },
  {
    numberOfArticles: 6
  },
  {
    numberOfArticles: 6
  },
  {
    numberOfArticles: 6
  },
  {
    numberOfArticles: 6
  },
  {
    numberOfArticles: 6
  },
  {
    numberOfArticles: 6
  },
  {
    numberOfArticles: 6
  },
  {
    numberOfArticles: 6
  },
  {
    numberOfArticles: 6
  }
];
