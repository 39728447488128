<template>
  <AlertSafeguard>
    <row class="wrapper alerts-promo mb-4 mt-4 pb-4 pt-4">
      <column xs="12">
        <row>
          <column md="10">
            <h2 class="category-name ff-flama fs-xxl fw-light tt-uppercase mb-3">
              {{ __('my.alerts') }}
            </h2>
            <p
              v-if="!showNewsStream"
              class="ff-flama fs-md fw-light d-inline-block mb-3">
              {{ __('alert.home.promo.subtitle') }}
            </p>
          </column>
          <column
            md="2"
            class="alert-close float-right fs-xl py-2">
            <div
              @click="goToMyAlerts"
              class="d-print-none ml-3 mb-3 float-right alert-btn-wrapper">
              <div class="alert-btn btn btn-secondary">
                <FontAwesomeIcon
                  :icon="icons.faBell"
                  width="12px"/>
                {{ showNewsStream ? __('manage.alerts') : __('get.my.alert') }}
              </div>
            </div>
          </column>
        </row>
        <row>
          <column md="12">
            <AlertSpinner :show="newsLoading"/>
          </column>
          <PromoNewsStream
            v-if="showNewsStream"
            :newsdata="newsStream"/>
          <AlertPromoWidget v-else/>
        </row>
        <AlertModal
          :show="showAlertPaywall"
          @close="showAlertPaywall = false">
          <AlertPaywall :target-url="loginTarget"/>
        </AlertModal>
      </column>
    </row>
  </AlertSafeguard>
</template>
<script>
import {
  checkUserLoginCookie,
  alertLocalStorageUtil,
  isEmpty,
  EventBus,
  AlertNewsStreamMiddleware
} from 'global-utils';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import AlertModal from './AlertModal';
import AlertPaywall from './AlertPaywall';
import AlertSafeguard from './AlertSafeguard';
import AlertPromoWidget from './AlertPromoWidget';
import PromoNewsStream from './PromoNewsStream';
import AlertSpinner from './AlertSpinner';

export default {
  name: 'alert-home-page-promo',
  components: {
    AlertModal,
    AlertPaywall,
    AlertSafeguard,
    FontAwesomeIcon,
    AlertPromoWidget,
    PromoNewsStream,
    AlertSpinner,
  },
  data() {
    const loginTargetPath = this.$route.query.target || this.$route.path;
    return {
      icons: {
        faBell
      },
      isGuestUser: false,
      loginTarget: loginTargetPath.replace(new RegExp('/preview/.*'), ''),
      showAlertPaywall: false,
      showNewsStream: false,
      newsLoading: false,
      newsStream: [],
      showErrors: false,
      newsBlurStyle: {
        '-webkit-filter': `blur(5px)`,
        '-moz-filter': `blur(5px)`,
        '-ms-filter': `blur(5px)`,
        '-o-filter': `blur(5px)`,
        filter: `blur(5px)`,
      }
    };
  },
  async beforeMount() {
    this.alertNewsStreamMiddleware = new AlertNewsStreamMiddleware(this, appEnv);
    this.alertNewsStreamMiddleware.newsLimit = 5;
    await this.init();
  },
  async mounted() {
    this.isGuestUser = !checkUserLoginCookie(this);

    EventBus.$on('newsPanel:refresh', async () => {
      await this.init();
    });
  },
  methods: {
    async init() {
      this.newsLoading = true;
      // check local storage if there is any subscription
      const subscriptionData = await alertLocalStorageUtil.getItem();
      if (!isEmpty(subscriptionData)) {
        this.newsStream = [];
        this.showErrors = false;

        try {
          const newsStreamData = await this.alertNewsStreamMiddleware.fetchMiniStream();
          if (!isEmpty(newsStreamData)) {
            this.showNewsStream = true;
            this.newsStream = newsStreamData;
          }
        } catch (e) {
          console.error('Error fetching NewsStream data.', e);
          this.showErrors = true;
        }
      } else {
        // load suggested topics at parent component
        this.showNewsStream = false;
      }
      this.newsLoading = false;
    },
    goToMyAlerts() {
      if (this.isGuestUser) {
        this.showAlertPaywall = true;
      } else {
        window.location.href = '/alerts';
      }
    }
  }
};
</script>

<style lang="scss">
  .btn.no-border {
    border: none !important;
  }

  .alert-btn-wrapper .alert-btn {
    width: 100% !important;
  }
</style>
