<template>
  <div id="terms-tabs">
    <header class="row d-flex justify-content-center">
      <div
        class="btn-group bg-primary"
        role="group"
        style="border-radius: 4px;"
        aria-label="Basic example">
        <template
          v-if="Object.keys(tabs).length > 1"
          v-for="([tabName, { title }], idx) in Object.entries(tabs)">
          <router-link
            :key="idx"
            :to="`?tab=${tabName}`"
            :class="`tab ${activeTab.name === tabName && 'active'}`">
            {{ title }}
          </router-link>
        </template>
      </div>
    </header>
    <section>
      <slot :tabComponent="activeTab.component"></slot>
    </section>
  </div>
</template>
<script>
export default {
  name: 'multi-terms-page',
  props: {
    tabs: {
      type: Object,
      default: () => ([]),
    },
    fallbackTab: {
      type: String,
    }
  },
  data() {
    return {
      activeTab: this.getActiveTab(),
    };
  },
  watch: {
    $route() {
      this.toggleTab();
    }
  },
  methods: {
    getActiveTab() {
      const activeTab = this.getFallbackTab();
      const tab = Object.keys(this.tabs)
        .find(t => t === this.$route.query.tab);
      if (tab) {
        activeTab.name = tab;
        activeTab.component = this.tabs[tab].componentName;
      }
      return activeTab;
    },
    getFallbackTab() {
      const tab = this.tabs[this.fallbackTab];
      if (tab) {
        return {
          name: this.fallbackTab,
          component: tab.componentName,
        };
      }
      // Use first tab as fallback
      const [name, { componentName: component }] = Object.entries(this.tabs)[0];
      return {
        name,
        component,
      };
    },
    toggleTab() {
      this.activeTab = this.getActiveTab();
    },
  }
};
</script>
