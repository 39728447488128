<template>
  <div
    v-if="enableGeneralSettings"
    class="ff-flama">
    <div class="fw-light tt-uppercase fs-lg mb-2">{{ __('general.settings') }}</div>
    <div class="mb-2 fw-light fs-md">{{ __('general.settings.note') }}</div>
    <div
      v-if="loading"
      class="mt-5 mb-7">Loading...</div>
    <div v-else>
      <div class="mt-3">
        <div>
          <label class="fs-md">
            <input
              v-model="emailEnabled"
              :disabled="disableCheckbox"
              @click="onClickEmailCheck"
              type="checkbox">
            {{ __('email') }}
          </label>
        </div>
        <div>
          <label class="fs-md">
            <input
              v-model="pushEnabled"
              :disabled="disableCheckbox"
              @click="onClickPushCheck"
              type="checkbox">
            {{ __('push.notification') }}
          </label>
        </div>
        <div
          v-show="emailPushError"
          class="email-push-error mb-3">{{ emailPushError }}</div>
      </div>
      <div
        v-html=" __('push.notification.note', $pubCtx.label)"
        class="fs-sm mb-2">
      </div>
      <div v-if="doNotDisturb !== undefined">
        <button
          @click="toggleAlertActivation"
          :class="`btn btn-${doNotDisturb ? 'primary' : 'secondary'} w-100`">
          {{ __(doNotDisturb ? 'activate.notifications' : 'pause.notifications') }}
        </button>
      </div>
    </div>
    <AlertPauseModal
      v-show="showPauseModal"
      @confirm="confirmPauseAlert"
      @cancel="showPauseModal = false"
      @close="showPauseModal = false"/>
  </div>
</template>
<script>
import { AlertGeneralSettingsClient } from './AlertGeneralSettingsClient';
import AlertPauseModal from './AlertPauseModal';
import { alertLocalStorageUtil, checkUserLoginCookie } from 'global-utils';

export default {
  name: 'alert-general-settings',
  components: { AlertPauseModal },
  data() {
    return {
      loading: true,
      emailEnabled: undefined,
      pushEnabled: undefined,
      doNotDisturb: undefined,
      disableCheckbox: false,
      emailPushError: '',
      enableGeneralSettings: this.$pubCtx.alert.enableGeneralSettings,
      showPauseModal: false,
    };
  },
  async mounted() {
    if (checkUserLoginCookie(this)) {
      this.withClient(async (client) => {
        const { notificationType, doNotDisturb } = await client.fetch();
        this.emailEnabled = notificationType.includes('EMAIL');
        this.pushEnabled = notificationType.includes('PUSH');

        alertLocalStorageUtil.setGeneralSettings({ notificationType, doNotDisturb });

        this.doNotDisturb = doNotDisturb;
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },
  methods: {
    async onClickEmailCheck(e) {
      if (this.emailEnabled && !this.pushEnabled) {
        e.preventDefault();
        this.emailPushError = this.$i18n('alert.default.email.push.error', this.$i18n('push').toLowerCase());
      } else {
        this.emailEnabled = !this.emailEnabled;
        await this.updateSettings();
        this.emailPushError = '';
      }
    },
    async onClickPushCheck(e) {
      if (this.pushEnabled && !this.emailEnabled) {
        e.preventDefault();
        this.emailPushError = this.$i18n('alert.default.email.push.error', this.$i18n('email').toLowerCase());
      } else {
        this.pushEnabled = !this.pushEnabled;
        await this.updateSettings();
        this.emailPushError = '';
      }
    },
    async updateSettings() {
      this.withClient(async (client) => {
        this.disableCheckbox = true;
        const notificationType = [];
        if (this.emailEnabled || this.pushEnabled) {
          if (this.pushEnabled) notificationType.push('PUSH');
          if (this.emailEnabled) notificationType.push('EMAIL');
        } else {
          notificationType.push('NO_ALERT');
        }

        const generalSettings = {
          notificationType: notificationType.join('_'),
          doNotDisturb: this.doNotDisturb,
        };

        await client.update(generalSettings);
        alertLocalStorageUtil.setGeneralSettings(generalSettings);
        this.disableCheckbox = false;
      });
    },
    async confirmPauseAlert() {
      this.doNotDisturb = true;
      await this.updateSettings();
      this.showPauseModal = false;
    },
    async toggleAlertActivation() {
      if (this.doNotDisturb) {
        this.doNotDisturb = false;
        await this.updateSettings();
      } else {
        this.showPauseModal = true;
      }
    },
    async withClient(handler) {
      try {
        handler(new AlertGeneralSettingsClient(this));
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
<style scoped>
input[type=checkbox] {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 5px;
}
.email-push-error {
  color: maroon;
  font-size: 1.3rem;
}
</style>
