export const publicationDefaultMetadata = {
  siteTitle: 'Hydrogen Insight',
  siteLabel: 'Hydrogen news and intelligence',
  description: 'Independent news, analysis and opinion on the global hydrogen industry'
};

export const metadataByRoute = {
  LatestNews: {
    description: '',
  }
};
