<template>
  <nav class="main-nav d-flex justify-content-center px-3">
    <header class="d-flex justify-content-between align-items-center">
      <div class="d-flex w-100 justify-content-between">
        <div class="site-logo d-flex">
          <div class="mr-5 brand-logo">
            <a
              :target="isStandaloneView ? '_parent' : false"
              href="/">
              <img :src="`${$cdn.resource(`gfx/${$pubCtx.name}/logo.svg`)}`">
            </a>
          </div>
          <RegularMenu/>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between">
            <SubscribeMenuItem/>
            <LoginMenuItem/>
            <ActionMenu/>
          </div>
        </div>
      </div>
    </header>
  </nav>
</template>
<script>
import { ActionMenu } from './ActionMenu';
import { RegularMenu } from './RegularMenu';
import SubscribeMenuItem from './SubscribeMenuItem';
import LoginMenuItem from './LoginMenuItem';

export default {
  name: 'main-navbar',
  components: {
    ActionMenu,
    RegularMenu,
    SubscribeMenuItem,
    LoginMenuItem,
  },
  inject: ['isStandaloneView'],
};
</script>
<style lang="scss">
  .main-nav {
    header {
      width: 1272px;
      font-family: "Flama", $fallback-sans-serif-fonts;
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
</style>
