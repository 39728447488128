<template>
  <div class="card border-0">
    <section>
      <h5>{{ __('read.also') }}</h5>
      <slot></slot>
    </section>
  </div>
</template>

<script>
import InlineNews from './InlineNews';

export default {
  name: 'inline-news-group',
  components: {
    /* eslint-disable vue/no-unused-components */
    InlineNews,
    /* eslint-enable vue/no-unused-components */
  },
};
</script>

<style lang="scss">
.article-body .dn-mulitple-relations-block {
  background-color: #FEFEFE;
  padding: 10px 5px 10px 5px;
}

.article-body .dn-mulitple-relations-block h5 {
  font-size: 1.8rem;
  font-weight: 600;
}

.article-body .dn-mulitple-relations-block ul {
  margin-left: 2vw !important;
}
</style>>
