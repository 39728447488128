export const publicationDefaultMetadata = {
  siteLabel: 'IntraFish.com',
  siteTitle: 'Latest seafood, aquaculture and fisheries news',
  description: 'Visit IntraFish for the best news, insight and opinion covering the global seafood, fisheries and aquaculture business.',
  twitterSite: '@IntraFish'
};

export const metadataByRoute = {
  LatestNews: {
    description: 'Read the key news headlines covering the global seafood business.',
  }
};
