export function inlineAdProcessor(body, slotId, vm) {
  try {
    if (
      !vm || !vm.$pubCtx || !vm.$pubCtx.inlineArticleAds || !vm.$pubCtx.inlineArticleAds[slotId] ||
      !vm.$pubCtx.inlineArticleAds[slotId].show || vm.$pubCtx.inlineArticleAds[slotId].blackListedRouteName.includes(vm.$route.name)
    ) {
      return body;
    }

    body.trim();
    const cheerio = require('cheerio');
    const $ = cheerio.load(body, {
      xmlMode: true
    });
    const children = $('div').children(); // parent tag in the body field from content api is always div
    const {
      searchTagName = 'p',
      showAdAfterNumberOfSearchTags = 6,
      minimumTagCountToShowAd = 9
    } = vm.$pubCtx.inlineArticleAds[slotId];
    let totalElementCount = 0;
    let elementBeforeInlineAd = null;
    const adTemplate = `<Ad slot-id=${slotId} ad-label="${vm.$i18n('article.continue.below.ad')}" adLabelClassNames="inline-ad-label clear-both" className="inline-ad"/>`;

    for (let idx = 0; idx < children.length; ++idx) {
      if (children[idx] && children[idx].name === searchTagName) {
        totalElementCount++;
        if (totalElementCount === showAdAfterNumberOfSearchTags) {
          elementBeforeInlineAd = children[idx];
        }
      }
    }
    if ((totalElementCount >= minimumTagCountToShowAd) && elementBeforeInlineAd !== null) {
      $(elementBeforeInlineAd).after(adTemplate);
    }
    return $.html();
  } catch (e) {
    if (process.env.VUE_ENV === 'server') {
      logger.error('Exception occurred while processing a single inline ad in article body' + e);
    }
    return body;
  }
}
