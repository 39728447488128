<template>
  <figure class="card float-left">
    <div class="card-body ff-flama fs-sm fw-medium">
      <div class="ff-flama fs-md fw-normal text-uppercase border-bottom mb-2 pb-2 card-title">
        Media
      </div>
      <div class="d-flex align-items-center media-download-link">
        <FontAwesomeIcon
          :icon="downloadIcon"
          class="mr-2 fs-md" />
        <slot>
          <a
            v-if="binaryUrl && title"
            :href="binaryUrl">
            {{ title }}
          </a>
        </slot>
      </div>
    </div>
  </figure>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
export default {
  name: 'inline-media',
  components: {
    FontAwesomeIcon
  },
  props: {
    refId: String
  },
  data() {
    const { title, url, binaryUrl } = this.$store.state.article.inlineElements[this.refId];
    return {
      downloadIcon: faDownload,
      title,
      url,
      binaryUrl
    };
  }
};
</script>
