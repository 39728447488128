<template>
  <section
    v-if="adSlots"
    class="section_highlight jobs-block mb-3 pt-4 pb-4">
    <div class="wrapper">
      <row>
        <column sm="12">
          <div class="mb-4 d-flex">
            <h2
              class="category-name ff-flama fs-xxl fw-light tt-uppercase mb-0 mr-4">
              {{ info.title }}
            </h2>
            <a
              href="https://fiskeribladet.ocast.com/nb/"
              class="category-link ff-flama fs-xs fw-medium mb-2">
              Kjøp annonse
            </a>
          </div>
        </column>
      </row>
      <row>
        <column sm="12">
          <Carousel
            :options="$options.carouselOptions()"
            @change="forceEnableNav"
            class="fiskekroken-carousel owl-carousel owl-theme teaser-carousel ff-flama">
            <template v-for="(slot) in adSlots">
              <Ad
                :key="slot"
                :slot-id="slot"
                kind="fiskekroken"/>
            </template>
          </Carousel>
        </column>
      </row>
    </div>
  </section>
</template>
<script>
import { AssetMixin } from 'global-mixin';
import { Ad } from 'global-elements';

export default {
  name: 'fiskekroken-carousel-placeholder',
  components: { Ad },
  mixins: [AssetMixin],
  props: {
    info: {
      type: Object
    }
  },
  data() {
    const adSlots = this.$store.state.adConfig.fiskekroken &&
      Object.keys(this.$store.state.adConfig.fiskekroken);
    return {
      adSlots
    };
  },
  carouselOptions() {
    return {
      loop: false,
      nav: true,
      items: 6,
      startPosition: 0,
      autoplay: true,
      autoplayTimeout: 3000,
      slideBy: 1,
      slideSpeed: 3000,
      rewind: true,
      mouseDrag: true,
      touchDrag: true,
      responsive: {
        0: {
          items: 1.5,
          nav: true
        },
        576: {
          items: 2.5,
          nav: true
        },
        768: {
          items: 3.5,
          nav: true
        },
        992: {
          items: 6,
          nav: true
        },
        1272: {
          items: 6,
          nav: true
        }
      }
    };
  },
  methods: {
    forceEnableNav(vm, _, $) {
      $(vm).find('.owl-nav').removeClass('disabled');
    }
  }
};
</script>
