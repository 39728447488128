import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import search from './modules/search';

Vue.use(Vuex);

export function createStore() {
  return new Vuex.Store({
    state: {
      /* some sample states
      application: {},
      publication: {},
      error: null,
      page: {},
      article: {},
      articleList: [],
      editions: []
      */
      curPlaceholderIdx: 0,
      bodyClasses: [],
    },
    actions,
    mutations,
    getters,
    modules: {
      search,
    },
  });
}

export default createStore;
