<template>
  <modal
    class="ff-sueca-sans verify-user-email">
    <div class="modal-header verify-user-email-header">
      <div> Hei! Det ser ut til at vi ikke har e-postadressen din. </div>
      <button
        @click="removeCookie()"
        type="button"
        class="close"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body verify-user-email-body">
      <p>Vi trenger denne blant annet for at du skal kunne be om nytt passord hvis du glemmer det.</p>
      <p>Vennligst skriv inn e-postadressen din og klikk Lagre før du går videre. </p>
      <p
        v-if="isValidationError"
        class="validationError text-danger">Dette ser ikke ut til å være en gyldig e-postadresse. Vennligst prøv igjen. </p>
    </div>
    <form
      id="verifyUserEmailAddressForm"
      @submit="verifyAddress"
      class="modal-footer"
      action="/external/userEmailAddress"
      method="post">
      <slot name="footer">
        <input
          id="verifyUserEmailAddress"
          v-model="verifyUserEmailAddress"
          name="verify-user-email-address"
          class="verify-user-email-address"
          placeholder="E-post">
        <input
          type="submit"
          class="modal-default-button post-email-address"
          value="Lagre">
      </slot>
    </form>
  </modal>
</template>
<script>
import { removeUserEmailVerificationCookie } from 'global-utils';
export default {
  name: 'verify-user-email',
  components: { },
  props: {
  },
  data() {
    return {
      isValidationError: false,
    };
  },
  methods: {
    verifyAddress(event) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(this.verifyUserEmailAddress)) {
        return true;
      } else {
        this.isValidationError = true;
        event.preventDefault();
      }
    },
    removeCookie(events) {
      this.$emit('close');
      removeUserEmailVerificationCookie(this);
    },
  }
};
</script>
<style lang="scss">
  .verify-user-email {
    width: 400px;
    border: #005288 3px solid;
    position: fixed;
    z-index: 9998;
    top: 25%;
    left: 33%;
    background-color: rgba(255, 255, 255, 1);
    display: table;

    p {
      margin-bottom: 0.7rem;
    }
    .modal-body {
      padding-bottom: 0.2rem;
    }

    .modal-footer {
      display: block;
      padding-top: 0.2rem;
    }

    .verify-user-email-header {
      font-weight: bold;
    }
    input.verify-user-email-address {
      width: 100%;
      display: block;
    }
    input.post-email-address {
      width: 20%;
      background-color: #005288;
      color: white;
      border: none;
      margin-top: 15px;
    }
    .validationError {
      border: 1px lightgrey solid;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
</style>
