<template>
  <Container class="inline-promo-box">
    <Card>
      <Row>
        <Column style="width: 100%">
          <CardBody>
            <Row :class="hideImage ? 'pr-3' : 'pl-2 pr-2'">
              <Column
                v-if="!hideImage && selectedImage"
                sm="4"
                xs="12"
                class="pr-sm-1">
                <CardImage
                  :source="selectedImage"
                  class="image mt-2"/>
              </Column>
              <Column
                :sm="setColumnWidthSmall"
                :class="[hideImage && 'mt-3 ml-2', !hideImage && 'mt-3']"
                xs="12">
                <div class="ff-flama fs-lg fw-medium headline">{{ title }}</div>
                <div class="ff-flama fs-md mt-3 mb-2 fw-light">{{ text }}</div>
                <div>
                  <a
                    :href="url"
                    class="btn btn-primary mt-3 mb-2">{{ buttonLabel }}</a>
                </div>
              </Column>
            </Row>
          </CardBody>
        </Column>
      </Row>
    </Card>
  </Container>
</template>

<script>
export default {
  name: 'inline-promo-box',
  props: {
    promoBoxValue: {
      type: String,
      default: ''
    }
  },
  data() {
    const { template, type, design, title, selectedImage, hideImage, text, buttonLabel, url } = JSON.parse(decodeURIComponent(`${this.promoBoxValue}`));
    return { template, type, design, title, selectedImage, hideImage, text, buttonLabel, url };
  },
  computed: {
    setColumnWidthSmall() {
      return this.hideImage ? 12 : 8;
    }
  }
};
</script>

<style lang="scss">
.inline-promo-box{
  color: #323232;
  .card{
    border: none;
  }
  .card-body{
    min-height: 13.3rem;
    }
  .image {
    max-width: 100%;
  }
  .headline {
    line-height: 2.2rem;
  }
  .btn {
    padding: 0.32rem 7rem;
  }
}
</style>
