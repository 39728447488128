<template>
  <section
    v-if="trendingArticles.length > 0">
    <h5
      v-text="__('trending.today')"
      class="tt-uppercase"></h5>
    <div
      v-for="teaserElem in trendingArticles"
      :key="teaserElem.index">
      <Teaser
        :grid="[5,7]"
        :teaser-element="teaserElem"
        :show-lead-text="false"
        :show-kicker="false"
        teaser-type="medium"
        image-size="small"/>
    </div>
  </section>
</template>

<script>
export default {
  name: 'article-trending-today',
  dataResolver: 'trendingArticles',
  data() {
    const trendingArticles = this.$store.state.trendingArticles
      ? this.$store.state.trendingArticles
        .filter(item => item.id !== this.$store.state.article.id)
        .slice(0, 3)
      : [];
    return {
      trendingArticles
    };
  },
};
</script>
