<template>
  <form
    :action="search.action"
    :target="isStandaloneView ? '_parent' : false"
    method="get"
    class="search d-flex">
    <input
      :value="search.language"
      type="hidden"
      name="languages">
    <input
      :value="search.language"
      type="hidden"
      name="locale">
    <input
      :placeholder="__('search.placeholder')"
      type="text"
      name="q"
      autocomplete="off"
      value=""
      class="form-control">
    <button
      type="submit"
      class="btn btn-primary btn-rounded-right">
      <FontAwesomeIcon :icon="faSearch" />
    </button>
  </form>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

export default {
  name: 'search-menu-item',
  components: {
    FontAwesomeIcon,
  },
  inject: ['isStandaloneView'],
  data() {
    return {
      faSearch,
      search: this.$pubCtx.menuConfigs.search
    };
  }
};
</script>
<style lang="scss">
  .menu-item.search {
    max-width: 486px;

    button {
      padding: 0 5px;

      &:hover {
        background-color: transparent;
      }
    }
  }
</style>
