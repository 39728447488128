<template>
  <div class="ff-flama fs-xs border-top border-bottom pt-3 pb-3 mb-3">
    <ArticleDate
      :date="publishedAt"
      class="pr-3"/>
    <ArticleDate
      :date="updatedAt"
      show-update-prefix
      prefix-style-class="tt-uppercase"
      class="st-italic"/>
    <div class="mt-2">
      <ArticleAuthors class="d-inline" />
      <ArticleLocation class="d-inline" />
    </div>
  </div>
</template>

<script>
import ArticleDate from './Date';
import ArticleAuthors from './Authors';
import ArticleLocation from './Location';

export default {
  name: 'article-text-byline',
  components: {
    ArticleDate,
    ArticleAuthors,
    ArticleLocation
  },
  data() {
    return {
      updatedAt: this.$store.state.article.updatedAt,
      publishedAt: this.$store.state.article.publishedAt,
    };
  },
};
</script>
