<template>
  <component
    :is="resolveAssetType()"
    v-bind="$props"
    :class="['lead-asset', $props.styleClass]"/>
</template>
<script>
import * as leadComponents from './lead-assets';

export default {
  name: 'article-lead-asset',
  components: {
    ...leadComponents
  },
  props: {
    styleClass: String,
    sizes: String,
  },
  methods: {
    resolveAssetType() {
      return this.$store.state.article.leadAsset.type;
    }
  }
};
</script>
