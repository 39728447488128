import { getHubspotTrackingScript } from './hubspotTracker';
import { getOneTrustTrackingScript } from './oneTrust';

export const getTrackingScripts = vm => {
  return getHubspotTrackingScript() +
    getOneTrustTrackingScript(vm);
};

export * from './gtagProcessor';
export * from './gtmProcessor';
export * from './oneTrust';
