var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alert-sub-settings"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('div',{staticClass:"main-category ff-flama fs-md fw-medium pb-3"},[_c('a',{attrs:{"href":("" + _vm.topicLink),"target":"_blank"}},[_vm._v(_vm._s(_vm.settingsElement.label))])]),_vm._v(" "),(!_vm.showOptions)?_c('div',{staticClass:"d-flex ff-flama-book fs-xs fw-light text-capitalize"},[_c('div',{staticClass:"notification mr-2 fw-bold"},[_vm._v("\n          "+_vm._s(_vm.__(_vm.resolveNotificationLabel(_vm.settingsElement.notification.type)))+"\n        ")]),_vm._v(" "),(_vm.settingsElement.notification.interval)?_c('div',{staticClass:"notification mr-2"},[_vm._v("\n          "+_vm._s(_vm.__('alert.option.'+ _vm.settingsElement.notification.interval.toLowerCase()))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.settingsElement.notification.day)?_c('div',{staticClass:"notification mr-2"},[_vm._v("\n          "+_vm._s(_vm.__(_vm.subscriptionDay.toLowerCase()))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.settingsElement.notification.time)?_c('div',{staticClass:"notification mr-2"},[_vm._v("\n          "+_vm._s(_vm.subscriptionTime)+" "+_vm._s(_vm.$pubCtx.timeZone)+"\n        ")]):_vm._e()]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"my-auto"},[(!_vm.showOptions)?_c('button',{staticClass:"edit btn btn-sm btn-outline-primary float-right",on:{"click":function($event){_vm.showOptions = true}}},[_vm._v("\n        "+_vm._s(_vm.__('edit'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.showOptions)?_c('button',{staticClass:"btn btn-sm float-right fs-lg",on:{"click":function($event){_vm.showOptions = false}}},[_c('FontAwesomeIcon',{attrs:{"icon":_vm.closeIcon,"width":"30"}})],1):_vm._e()])]),_vm._v(" "),(_vm.showOptions)?_c('div',[_c('AlertOptionChooser',{staticClass:"medium",attrs:{"edit-mode":true,"items":[_vm.settingsElement],"subs-options":{
        notificationType: _vm.settingsElement.notification.type,
        selectedOption: _vm.settingsElement.notification.interval
          ? _vm.settingsElement.notification.interval.toLowerCase()
          : 'never',
        selectedDay: _vm.settingsElement.notification.day ? _vm.subscriptionDay.toLowerCase() : '',
        selectedTime: _vm.settingsElement.notification.time ? ("" + _vm.subscriptionTime) : ''
      },"sub-type":_vm.settingsElement.subType || _vm.termType},on:{"edit":_vm.onEdit,"confirm":_vm.updateSubscription,"confirmError":_vm.handleConfirmError,"delete":_vm.deleteSubscription,"deleteError":_vm.handleDeleteError}})],1):_vm._e(),_vm._v(" "),_c('AlertActionStatus',{directives:[{name:"show",rawName:"v-show",value:(_vm.showActionStatus),expression:"showActionStatus"}],key:_vm.actionKind + _vm.actionSuccess,attrs:{"action":_vm.actionKind,"success":_vm.actionSuccess},on:{"close":_vm.onContinueOrClose,"continue":_vm.onContinueOrClose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }