<template>
  <div>
    <AlertPaywall
      v-if="isGuestUser && !editMode"
      :target-url="loginTarget"/>
    <div
      v-else
      class="alert-option-chooser">
      <div
        v-if="!editMode"
        class="mb-4">
        <div class="mb-4">
          {{ (subType === 'authors') ? __('get.alerted.author.body.note') : __('get.alerted.body.note') }}
        </div>
        <div
          v-if="alreadySubscribedItems && alreadySubscribedItems.length"
          class="d-flex flex-row flex-wrap mb-4">
          <FontAwesomeIcon
            :icon="icons.faCheck"
            width="12px"/>
          <span>{{ __('alert.already.created.with') }}&nbsp;</span>
          <div
            v-for="item in alreadySubscribedItems"
            :key="item.index"
            class="item ml-3 mr-3">
            <label class="text-capitalize ff-flama fs-sm fw-bold">
              <FontAwesomeIcon
                :icon="(subType === 'authors') ? icons.faUserEdit : icons.faTag"
                width="8px"/>
              <a
                :href="(subType === 'authors') ? `/author/${item.name}` : ((subType === 'tag') ? `/tag/${item.name}` : `/${item.name}`)"
                class="d-inline-block align-middle"
                style="line-height: 2.8rem">
                {{ item.label }}
              </a>
            </label>
          </div>
        </div>
        <div class="d-flex flex-row flex-wrap">
          <div
            v-for="item in itemsToSubscribe"
            :key="item.index"
            class="item mr-5">
            <label>
              <input
                :value="item.name"
                v-model="topicOptionNames"
                checked="checked"
                type="checkbox">
              {{ item.label }}
            </label>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="mb-3 ff-flama fw-light fs-md">{{ __('how.often.receive.alert') }}</div>
        <div class="option-wrapper">
          <div
            v-for="([value, label]) in Object.entries(alertOptions)"
            :key="value"
            :class="['option', { selected: selectedOption === value }]">
            <label>
              <input
                v-model="selectedOption"
                :value="value"
                name="alertOption"
                type="radio">
              {{ label }}
            </label>
          </div>
        </div>
      </div>
      <transition name="push-email-selectors">
        <div
          v-if="editMode && selectedOption !== 'never'"
          :class="['push-email-edit', showPushEmailRequiredMessage && 'error']">
          <div
            v-if="enableGeneralSettings"
            class="d-flex">
            <div>
              <label>
                <input
                  v-model="pushType"
                  @change="showPushEmailRequiredMessage = !(pushType || emailType)"
                  type="checkbox">
                {{ __('alert.notification.type.push') }}
              </label>
            </div>
            <div class="ml-3">
              <label>
                <input
                  v-model="emailType"
                  @change="showPushEmailRequiredMessage = !(pushType || emailType)"
                  type="checkbox">
                {{ __('alert.notification.type.email') }}
              </label>
            </div>
          </div>
          <div
            v-show="enableGeneralSettings && showPushEmailRequiredMessage"
            class="required-message">{{ __('alert.push.email.check.required') }}</div>
        </div>
      </transition>
      <transition name="day-time-options">
        <div
          v-if="selectedOption === 'weekly' || selectedOption === 'daily'"
          class="d-flex flex-wrap flex-row mb-3">
          <transition name="day-time-options">
            <div
              v-if="selectedOption === 'weekly'"
              class="mr-3">
              <div class="ff-flama fw-light fs-md lh-35">{{ __('which.day') }}</div>
              <div>
                <select
                  v-model="selectedDay"
                  class="p-2">
                  <option
                    v-for="(day, idx) in days"
                    :key="idx"
                    :value="day">{{ __(day) }}</option>
                </select>
              </div>
            </div>
          </transition>
          <transition name="day-time-options">
            <div v-if="selectedOption === 'weekly' || selectedOption === 'daily'">
              <div class="ff-flama fw-light fs-md lh-35">{{ __('what.time') }}</div>
              <div>
                <select
                  v-model="selectedTime"
                  class="p-2">
                  <option
                    v-for="(hour, idx) in hours"
                    :key="idx"
                    :value="hour">{{ hour }} {{ $pubCtx.timeZone }}</option>
                </select>
              </div>
            </div>
          </transition>
        </div>
      </transition>
      <div>
        <div
          v-if="!editMode"
          v-html="confirmAlertNote"
          class="confirm-alert-note mb-3">
        </div>
        <div class="mt-2">
          <button
            @click="deleteAlert"
            v-if="editMode"
            class="delete btn btn-secondary w-100 mb-2">
            {{ __('delete.alert') }}
            <AlertSpinner :show="deleteClicked"/>
          </button>
        </div>
        <div>
          <button
            @click="confirmAlert"
            class="confirm btn btn-primary w-100">
            {{ __('confirm.alert') }}
            <AlertSpinner :show="confirmClicked"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  checkUserLoginCookie,
  AlertSubscriptionClient,
  buildNotificationObject,
  isEmpty,
  EventBus,
  alertLocalStorageUtil,
  convertCETToGMTDayAndHour
} from 'global-utils';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTag, faUserEdit, faCheck } from '@fortawesome/pro-regular-svg-icons';
import AlertPaywall from './AlertPaywall';
import AlertSpinner from './AlertSpinner';

export default {
  name: 'alert-option-chooser',
  components: { AlertPaywall, FontAwesomeIcon, AlertSpinner },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false
    },
    subsOptions: {
      type: Object,
      required: false,
      default: null
    },
    fromPage: {
      type: Boolean,
      required: false,
      default: false
    },
    subType: {
      type: String,
      default: 'topics'
    },
    fromSearch: {
      type: Boolean,
      default: false
    },
    subscibedItems: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  data() {
    const editOptionsProvided = this.editMode && this.subsOptions;
    const loginTargetPath = this.$route.query.target || this.$route.path;

    const itemsToSubscribe = !isEmpty(this.subscibedItems)
      ? this.items.filter(item => !this.subscibedItems.includes(item.name))
      : this.items;

    const alreadySubscribedItems = !isEmpty(this.subscibedItems)
      ? this.items.filter(item => this.subscibedItems.includes(item.name))
      : [];

    const enableGeneralSettings = this.$pubCtx.alert.enableGeneralSettings;
    const localGeneralSettings = alertLocalStorageUtil.getGeneralSettings();
    const localNotificationTypes = localGeneralSettings ? localGeneralSettings.notificationType : '';
    const generalSettings = enableGeneralSettings
      ? localNotificationTypes.replace('_', ', ')
      : '';
    const generalSettingsInfo = generalSettings
      ? this.$i18n('general.settings.info', this.$i18n(generalSettings.replace(',', '.').replace(' ', '').toLowerCase()).toUpperCase()) + ' '
      : '';

    return {
      selectedOption: (editOptionsProvided && this.subsOptions.selectedOption) ? this.subsOptions.selectedOption : 'immediate',
      selectedDay: (editOptionsProvided && this.subsOptions.selectedDay) ? this.subsOptions.selectedDay : 'monday',
      selectedTime: (editOptionsProvided && this.subsOptions.selectedTime) ? this.subsOptions.selectedTime : '10',
      topicOptionNames: itemsToSubscribe.map(item => item.name),
      topicOptions: itemsToSubscribe,
      confirmClicked: false,
      deleteClicked: false,
      isGuestUser: false,
      loginTarget: loginTargetPath.replace(new RegExp('/preview/.*'), ''),
      itemsToSubscribe,
      alreadySubscribedItems,
      icons: { faCheck, faTag, faUserEdit },
      alreadySubscribedTopicSubType: '',
      pushType: (editOptionsProvided && this.subsOptions.notificationType.includes('PUSH')) || (generalSettings.includes('PUSH')),
      emailType: (editOptionsProvided && this.subsOptions.notificationType.includes('EMAIL')) || (generalSettings.includes('EMAIL')),
      confirmAlertNote: generalSettingsInfo + this.$i18n('default.alert.notification.note'),
      showPushEmailRequiredMessage: false,
      editOptionsProvided,
      enableGeneralSettings,
    };
  },
  computed: {
    alertOptions() {
      return {
        immediate: this.$i18n('alert.option.immediate'),
        daily: this.$i18n('alert.option.daily'),
        weekly: this.$i18n('alert.option.weekly'),
        never: this.$i18n('alert.option.never'),
      };
    },
    days() {
      return [
        'monday', 'tuesday', 'wednesday',
        'thursday', 'friday', 'saturday', 'sunday'
      ];
    },
    hours() {
      return Array.from(Array(24).keys());
    }
  },
  mounted() {
    this.isGuestUser = !checkUserLoginCookie(this);
    this.alertSubscriptionClient = new AlertSubscriptionClient(this);
  },
  methods: {
    resolveNotificationType() {
      if (this.selectedOption === 'never') { return 'NO_ALERT' }
      if (!this.enableGeneralSettings) { return 'EMAIL' }

      const { pushType, emailType } = this;
      if (pushType && emailType) return 'PUSH_EMAIL';
      if (pushType) return 'PUSH';
      if (emailType) return 'EMAIL';
      return null;
    },
    async confirmAlert() {
      if (this.editOptionsProvided && this.enableGeneralSettings && !this.pushType && !this.emailType && this.selectedOption !== 'never') {
        this.showPushEmailRequiredMessage = true;
        return;
      }
      this.confirmClicked = true;
      const confirmData = {
        selectedOption: this.selectedOption,
        selectedDay: this.selectedDay,
        selectedTime: this.selectedTime,
      };

      if (this.$pubCtx.timeZone === 'CET') {
        const { day, hour: time } = convertCETToGMTDayAndHour(this.selectedDay, this.selectedTime);
        confirmData.selectedTime = time;
        confirmData.selectedDay = day;
      }

      const notificationObj = buildNotificationObject(confirmData);
      const topicOptionNames = this.topicOptionNames;
      let selectedTopics = [];
      if (this.fromSearch && ['author', 'authors'].includes(this.subType)) {
        selectedTopics = this.itemsToSubscribe;
      } else {
        selectedTopics = this.itemsToSubscribe.filter(item => {
          return topicOptionNames.includes(item.name);
        });
      }

      notificationObj.type = this.resolveNotificationType();
      const subsData = selectedTopics.map(item => {
        return {
          subscriptionId: (this.editMode) ? item.subscriptionId : 0,
          term: (this.editMode) ? item.term : item.name.toLowerCase(),
          label: item.label,
          subType: (this.editMode || item.subType) ? item.subType : this.subType,
          notification: notificationObj
        };
      });
      const subscriptionType = ['author', 'authors'].includes(this.subType) ? 'authors' : 'topics';
      try {
        if (subsData.length === 0) {
          throw new Error('No data to save subscription!');
        }

        // call save api with this.settingsElement updated
        let updatedData = [];
        let error = false;
        if (this.editMode) {
          try {
            updatedData = await this.alertSubscriptionClient.update(subscriptionType, subsData[0].subscriptionId, subsData[0]);
          } catch (e) {
            error = e;
          }
          this.$emit('edit', { error, data: updatedData });
        } else {
          updatedData = await this.alertSubscriptionClient.save(subscriptionType, subsData);
        }
        this.$emit('confirm', updatedData);
        if (this.selectedOption === 'never') {
          EventBus.$emit('alerts:no:email');
        }
      } catch (e) {
        this.$emit('confirmError', e);
      }
    },
    async deleteAlert() {
      this.deleteClicked = true;
      if (window.confirm(this.$i18n('alert.delete.confirmation'))) {
        const confirmData = {
          selectedOption: this.selectedOption,
          selectedDay: this.selectedDay,
          selectedTime: this.selectedTime,
        };

        const notificationObj = buildNotificationObject(confirmData);

        const subsData = {
          subscriptionId: this.itemsToSubscribe[0].subscriptionId,
          term: this.itemsToSubscribe[0].term,
          label: this.itemsToSubscribe[0].label,
          subType: this.itemsToSubscribe[0].subType,
          notification: notificationObj
        };

        const subscriptionType = ['author', 'authors'].includes(this.subType) ? 'authors' : 'topics';

        try {
          // call update api with this.settingsElement removed
          await this.alertSubscriptionClient.delete(subscriptionType, subsData.subscriptionId, subsData);
          this.$emit('delete', {});
        } catch (e) {
          console.error('something went wrong deleting: ', e);
          this.deleteClicked = false;
          this.$emit('deleteError', {});
        }
      } else {
        this.deleteClicked = false;
      }
    },
    goToMyAlerts() {
      window.location.href = '/alerts';
    },
  }
};
</script>
<style lang="scss">
  .alert-option-chooser {
    font-size: 1.6rem;

    .clearfix::after {
      display: block;
      clear: both;
      content: "";
    }

    &.medium {
      font-size: 1.4rem;
      .option {
        label {
          font-size: 1.4rem;
        }
      }
    }

    .item label {
      font-size: 1.6rem;
    }
    .option-wrapper {
      border: 1px solid lightgrey;
      border-bottom: 0px;
    }
    .option {
      border-bottom: 1px solid lightgrey;

      &.selected,
      &:hover {
        background-color: #EFF2F6;
      }

      label {
        margin-bottom: 0;
        padding: 10px 15px;
        font-size: 1.6rem;
        font-family: 'Flama-Book';
        color: #4A4A4A;
      }
      input {
        margin-right: 5px;
      }
    }

    .day-time-options-enter,
    .day-time-options-leave-to,
    .push-email-selectors-enter,
    .push-email-selectors-leave-to {
      visibility: hidden;
      height: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }

    .day-time-options-enter-active,
    .day-time-options-leave-active,
    .push-email-selectors-enter-active,
    .push-email-selectors-leave-active {
      transition: all 0.7s;
    }

    .confirm-alert-note {
      font-size: 1.3rem;
    }

    .push-email-edit {
      padding: 5px;
      &.error {
        border: 1px solid maroon;
      }
      label {
        font-size: 1.5rem;
      }
      .required-message {
        color: maroon;
        font-size: 1.3rem;
      }
    }
  }
</style>
