<template>
  <card-image
    v-if="!hideImage && imageSrc"
    :class="['teaser-image', imageClasses]"
    v-bind="$props"
    :source="imageSrc"
    :fallback="imageFallback"
    :sizes="calculateSize"
    :imgWrapperClasses="imgWrapperClasses"/>
</template>

<script>
import { imageSource } from './teaserUtils';

export default {
  name: 'teaser-image',
  props: {
    hideImage: {
      type: Boolean,
      default: false
    },
    imageClasses: {
      type: String,
      required: true
    },
    leadAsset: {
      type: Object,
      required: true
    },
    imageSize: {
      type: String,
      required: true,
      default: 'medium'
    }
  },
  data() {
    return {
      imageSrc: imageSource(this.leadAsset)
    };
  },
  computed: {
    calculateSize() {
      switch (this.imageSize) {
      case 'big':
        return '(min-width: 576px) 700px, 600px';
      case 'small':
        return '(min-width: 576px) 300px, 200px';
      default:
        return '(min-width: 1001px) 300px, (min-width: 701px) 33.33vw, (min-width: 401) 50vw, 100vw';
      }// TODO adjust later
    },
    imageFallback() {
      let width = 480;
      if (this.imageSize === 'big') {
        width = 640;
      }
      return imageSource(this.leadAsset) + '?image_version=' + width;
    },
    imgWrapperClasses() {
      const imageRepresentation = this.leadAsset.imageRepresentation || '';
      let classes = '';
      if (imageRepresentation === 'three_two') {
        classes = 'aspect-three-two';
      }
      return classes;
    }
  }
};
</script>
