<template>
  <div>
    <AppLink />
    <TopBar/>
    <container
      :class="[publicationName, $route.meta.pageContext.type, `page-${$route.meta.pageContext.type}`]"
      fluid>
      <NorkonTicker v-show="showNorkonTicker"/>
      <div
        v-if="!error && showLeaderBoardAd"
        class="mb-3 text-center">
        <ad slot-id="leaderBoard"/>
      </div>
      <ErrorAwareView
        :error="error"
        :fallback="fallback">
        <router-view></router-view>
      </ErrorAwareView>
    </container>
    <FooterLogo/>
    <Footer/>
    <VerifyUserEmail
      v-if="showVerifyUserEmail"
      @close="showVerifyUserEmail = false"/>
    <!--    <VersionSticky-->
    <!--      v-if="serverName !== 'prod' && showLeaderBoardAd"/>-->
  </div>
</template>
<script>
import Error404Page from 'pages/404Page';
import Error500Page from 'pages/500Page';
import { shouldShowUserEmailVerification } from 'global-utils';

import {
  VerifyUserEmail,
  NorkonTicker,
  FooterLogo,
  Footer,
} from 'global-components';

import TopBar from './TopBar';

export default {
  name: 'web-view',
  components: {
    VerifyUserEmail,
    FooterLogo,
    Footer,
    NorkonTicker,
    TopBar,
  },
  data() {
    return {
      showNorkonTicker: false,
      showVerifyUserEmail: false,
      serverName: this.$store.state.serverName,
      publicationName: this.$store.state.publication.name,
    };
  },
  computed: {
    error() {
      return this.$store.state.error;
    },
    showLeaderBoardAd() {
      return (!['frontpage', 'order', 'twthirtyyears', 'career'].includes(this.$route.meta.pageContext.type)) &&
        (this.$route.meta.pageContext.edition !== 'sponsor') &&
        (!['contactus', 'kontaktoss'].includes(this.$route.meta.pageContext.pathId));
    },
    fallback() {
      /* README FIRST
      Here error object cannot be checked as 'instanceof', like
      `this.error instanceof NotFoundError`
      The above code won't work in client/browser since `error` object
      stored in vue store(vuex) and in vuex `state` wrap with Observer type
      which have only fields/properties of that object(eliminate all methods)
       */
      if (this.error && this.error.httpStatus === 404) {
        return Error404Page;
      }
      return Error500Page;
    },
  },
  mounted() {
    this.showNorkonTicker = this.shouldShowNorkonTicker();

    setTimeout(() => {
      this.showVerifyUserEmail = shouldShowUserEmailVerification(this);
    }, 3 * 1000);
  },
  methods: {
    // @TODO: remove this two condition checking via publication context
    shouldShowNorkonTicker() {
      return this.$pubCtx.enableNorkonTicker &&
        (this.$route.query.showNorkonTicker === 'true' || !dconf.disableNorkonTicker) &&
        this.$route.meta.pageContext.edition !== 'sponsor' &&
        !['aboutus', 'help', 'chinese', 'terms', 'contactus', 'advertise']
          .includes(this.$route.meta.pageContext.type);
    },
  },
};
</script>
