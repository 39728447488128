import commonEditionConfig from './commonEditionpage';
import commonFrontpageConfig from './commonFrontpage';
const defaultConfig = {
  frontpage: commonFrontpageConfig,
  test_frontpage: commonFrontpageConfig,
  hardcopy: commonEditionConfig,
  focus_editions: commonEditionConfig,
  shale: commonEditionConfig,
  lng: commonEditionConfig,
  exclusive: commonEditionConfig,
  energy_security: commonEditionConfig,
  carbon_capture: commonEditionConfig,
  energy_transition: commonEditionConfig
};

export default (edition) => {
  return {
    newsBlocks: defaultConfig[edition],
    jobsBlock: { }
  };
};
