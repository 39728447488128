export const publicationDefaultMetadata = {
  siteTitle: 'Latest shipping and maritime news',
  description: 'Visit TradeWinds for the best news, insight and ' +
    'opinion covering the global shipping business.',
  twitterSite: '@tradewindsnews'
};

const coronavirusMetadata = {
  pageTitle: 'Coronavirus Live news',
  description: 'Visit the TradeWinds Live Centre for the latest news and information on how the coronavirus pandemic is impacting the global shipping industry and discover what it means for world trade, business and finance.',
};

export const metadataByRoute = {
  LatestNews: {
    description: 'Read the key news headlines covering the global shipping business.',
  },
  Weekly: {
    pageTitle: 'Weekly',
  },
  TWPlus: {
    pageTitle: 'TW Plus',
  },
  Chinese: {
    pageTitle: 'Chinese Edition',
  },
  Sustainability: {
    pageTitle: 'The latest news and insight covering environment, social and governance (ESG) in shipping',
  },
  Coronavirus: coronavirusMetadata,
  CoronavirusAuthDenied: coronavirusMetadata,
  CoronavirusFailed: coronavirusMetadata,
  CoronavirusTooManySession: coronavirusMetadata,
};
