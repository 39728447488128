<template>
  <div class="mb-4 mt-4">
    <h5
      class="main-category text-uppercase ff-flama fs-md fw-sm">
      {{ __('alert.topics') }}
    </h5>
    <div class="settings-elem help-text lh-22 pt-3 pb-3">
      {{ __('alert.topics.default') }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'default-topics'
};
</script>
