<template>
  <card
    v-if="title || body"
    :class="['inline factbox',
             `float-${alignment}`]">
    <card-body>
      <card-title
        v-if="title"
        @click.native="toggleBody"
        v-html="header"
        class="ff-flama fs-md text-uppercase border-bottom mb-0 d-flex justify-content-between"/>
      <div :class="{'d-none': bodyHidden, 'd-sm-block': true, 'mt-2': true}">
        <card-image
          v-if="imageSrc"
          :image-src="imageSrc"
          :source="imageSrc"
          :sizes="sizes"
          class="mb-3"/>
        <div
          v-html="body"
          class="body ff-flama fs-sm"></div>
        <div
          v-if="source"
          class="ff-flama-book fs-xs source mt-2">
          {{ __('source') }}: {{ source }}
        </div>
      </div>
    </card-body>
  </card>
</template>

<script>
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
export default {
  name: 'inline-factbox',
  props: {
    refId: String
  },
  data() {
    const inlineFactbox = this.$store.state.article.inlineElements[this.refId];
    if (!inlineFactbox || !inlineFactbox.title) return {};
    return {
      toggleIcon: icon(faChevronDown).html,
      title: inlineFactbox.title,
      header: inlineFactbox.title + icon(faChevronDown).html,
      body: inlineFactbox.body,
      source: inlineFactbox.source,
      alignment: (inlineFactbox.alignment && inlineFactbox.alignment.replace('factbox_', '')),
      imageSrc: inlineFactbox.imageSrc,
      bodyHidden: true
    };
  },
  computed: {
    sizes() {
      return '(min-width: 576px) 500px, 400px'; // todo: implement correctly
    }
  },
  methods: {
    toggleBody: function () {
      this.bodyHidden = !this.bodyHidden;
      if (this.bodyHidden) {
        this.toggleIcon = icon(faChevronDown).html;
      } else {
        this.toggleIcon = icon(faChevronUp).html;
      }
      this.header = this.title + this.toggleIcon;
    }
  }
};
</script>
