<template>
  <div class="text-field">
    <label :for="label">{{ label }}</label>
    <textarea
      v-if="multiline"
      :id="label"
      :placeholder="placeholder_"
      :maxlength="maxlength"
      @input="$emit('input', $event.target.value)"
      class="w-100"/>
    <input
      v-else
      :id="label"
      :placeholder="placeholder_"
      :maxlength="maxlength"
      @input="$emit('input', $event.target.value)"
      class="w-100">
    <div
      v-for="msg in messages"
      :key="msg"
      class="error message">{{ msg }}</div>
  </div>
</template>
<script>
export default {
  name: 'text-field',
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: Array,
      default: () => ([]),
    },
    maxlength: {
      type: String,
    }
  },
  data() {
    return {
      field: ''
    };
  },
  computed: {
    placeholder_() {
      return this.placeholder || this.label;
    }
  }
};
</script>
