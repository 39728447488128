import { _isNil, extractTextFromHtml, imageSourceResolver, titleCase } from 'global-utils';

export function siteLabel({ pubCtx }) {
  return pubCtx.label;
}

export function title() {
  return ({ metadata }) => `${metadata.pageTitle} - ${metadata.siteTitle} | ${metadata.siteLabel}`;
}

export function ogSiteName({ metadata }) {
  return `${metadata.siteLabel} | ${metadata.siteTitle}`;
}

export function ogImage({ pubCtx }) {
  return `https://www.${pubCtx.domain}/resources/gfx/${pubCtx.name}/share-to-facebook.png`;
}

export function twitterImage({ pubCtx }) {
  return `https://www.${pubCtx.domain}/resources/gfx/${pubCtx.name}/share-to-twitter.png`;
}

export function url() {
  return ({ route, pubCtx }) => `https://www.${pubCtx.domain}${route.path.replace(new RegExp('/preview/.*'), '')}`;
}

export function resolvePageTitle(valuesByTerm = {}) {
  return () => ({ route, store }) => {
    if (valuesByTerm[store.state.term]) {
      return valuesByTerm[store.state.term];
    } else if (route.meta.pageContext.iPageTitle) {
      return store.$i18n(route.meta.pageContext.iPageTitle);
    } else if (route.meta.pageContext.pageTitle) {
      return route.meta.pageContext.pageTitle;
    } else if (store.state.term) {
      return titleCase(store.state.term.replace('_', ' '));
    } else if (route.meta.pageContext.type) {
      return titleCase(route.meta.pageContext.type.replace('_', ' '));
    }
  };
}

export function resolveArticlePageTitle() {
  return ({ metadata, store }) => `${store.state.article.title} | ${metadata.siteLabel}`;
}

export function resolveArticlePageTitleWithoutSiteLabel() {
  return ({ metadata, store }) => `${store.state.article.title}`;
}

export function resolveArticleDescription() {
  return ({ store }) => {
    const article = store.state.article;
    const metaDesc = (_isNil(article.leadText) || article.leadText.trim().length <= 0)
      ? _isNil(article.body) ? '' : extractTextFromHtml(article.body)
      : extractTextFromHtml(article.leadText);
    return metaDesc.trim().slice(0, 160);
  };
}

export function resolveArticleImageSource() {
  return ({ store }) => {
    const { imageSource } = imageSourceResolver(store.state.article);
    return imageSource;
  };
}

export function mapRouteMetadata(routeNames = [], metadata = {}) {
  return routeNames.reduce((cum, routeName) => {
    cum[routeName] = { ...metadata };
    return cum;
  }, {});
}

export function updateOgUrl(metadata, ssrCtx) {
  try {
    const ogUrl = new URL(metadata.ogUrl);
    ogUrl.searchParams.set('abtest', ssrCtx.req.variantQueryParamValue);
    metadata.ogUrl = ogUrl.toString();
  } catch (e) {
    logger.error(`Could not add abtest param, articleUrl: ${metadata.ogUrl}`);
  }
}
