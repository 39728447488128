<template>
  <div class="full-menu w-100">
    <Row
      v-for="(submenu, idx) in fullMenu"
      :key="submenu.label"
      :class="['submenu', { last: idx + 1 === fullMenu.length }, submenu.styleClass]">
      <Column
        :lg="submenuGrid[0]"
        class="pl-0 d-flex align-items-center">
        <div class="label">
          <a
            v-if="submenu.href"
            :href="submenu.href"
            :target="isStandaloneView ? '_parent' : false">
            {{ submenu.label }}
          </a>
          <span v-else>
            {{ submenu.label }}
          </span>
        </div>
      </Column>
      <Column
        :lg="submenuGrid[1]"
        class="pl-0">
        <ul class="items pl-5">
          <li
            v-for="(item, i) in submenu.items"
            :key="item.order"
            :class="[i + 1 === submenu.items.length && 'last']">
            <a
              :href="item.href"
              :target="item.target ? item.target : (isStandaloneView ? '_parent' : false)">
              {{ item.label }}
            </a>
          </li>
        </ul>
      </Column>
    </Row>
  </div>
</template>
<script>
export default {
  name: 'full-menu',
  inject: ['isStandaloneView'],
  data() {
    return {
      fullMenu: this.$pubCtx.menuConfigs.dropdownMenuItem
    };
  },
  computed: {
    submenuGrid() {
      return ['europower'].includes(this.$pubCtx.name) ? [2, 10] : [1, 11];
    }
  },
};
</script>
<style lang="scss">
  .full-menu {
    width: 100%;
    max-width: 100% !important;

    .submenu {
      padding: 12px 0;
      border-bottom: 1px solid;

      &.last {
        border: none;
      }

      .label {
        text-transform: uppercase;
        font-weight: bold;
      }

      .items {
        padding-inline-start: 0;

        li {
          margin: 5px 0;
          list-style: none;
          float: left;
          border-right: 1px solid;
          font-size: 1.8rem;

          &.last {
            border: 0;
          }

          a {
            margin: 0 12px;
          }
        }
      }
    }
  }
</style>
