<template>
  <card :class="`float-${alignment}`">
    <card-body>
      <card-image
        v-if="inlinePicture"
        :source="source"
        :sizes="sizes"
        :caption="fullCaption"
        :caller="callerName()"
        :is-portrait="isPortrait()"
        :crop-width="width"
        :crop-height="height"
        :is-free-crop="isFreeCrop()"
        class="mb-0"></card-image>
    </card-body>
  </card>
</template>
<script>
import { stripPhotoPrefix, moment } from 'global-utils';

export default {
  name: 'inline-picture',
  props: {
    refId: String
  },
  computed: {
    inlinePicture() {
      return this.$store.state.article.inlineElements[this.refId];
    },
    source() {
      return this.inlinePicture.imageSource;
    },
    fullCaption() {
      const credit = stripPhotoPrefix(this.inlinePicture.credit, this.__);
      if (!credit) {
        return this.inlinePicture.caption;
      } else {
        return `${this.inlinePicture.caption} ${credit}`;
      }
    },
    alignment() {
      return this.inlinePicture.alignment;
    },
    width() {
      return this.inlinePicture.width;
    },
    height() {
      return this.inlinePicture.height;
    },
    sizes() {
      return this.alignment === 'free' ? `(min-width: 576px)` : '(min-width: 576px) 600px, 400px'; // todo: implement correctly
    }
  },
  methods: {
    fromFiskeriOld() {
      return ((['tekfisk', 'fiskeribladet'].includes(this.$pubCtx.name)) &&
        (moment(this.$store.state.article.publishedAt) <= moment('2020-09-22T00:00:00Z')));
    },
    isPortrait() {
      return (this.alignment === 'portrait-left') || (this.alignment === 'portrait-right');
    },
    callerName() {
      return (this.isPortrait() || this.fromFiskeriOld()) ? this.$options.name : '';
    },
    isFreeCrop() {
      return (this.alignment === 'free') || (this.alignment === 'free-left') || (this.alignment === 'free-right');
    }
  }
};
</script>

<style lang="scss">
  .float-portrait-right, .float-free-right {
    float: right !important;
  }

  .float-portrait-left, .float-free-left {
    float: left !important;
  }

  .card.float-portrait-left,
  .card.float-portrait-right,
  .card.float-free-right,
  .card.float-free-left {
    clear: both;
    overflow: hidden;
    max-width: 192px;
    margin-right: 2.4rem;
  }
</style>
