import commonEditionConfig from './commonEditionpage';
import commonFrontpageConfig from './commonFrontpage';
const defaultConfig = {
  frontpage: commonFrontpageConfig,
  test_frontpage: commonFrontpageConfig,
  twplus: commonEditionConfig,
  interviews: commonEditionConfig,
  sustainability: commonEditionConfig
};

export const shippingBlock = {
  dataSrc: 'https://tradewinds.asp.manamind.com/frontpage',
  javaScriptSrc: 'https://tradewinds.asp.manamind.com/assets/tradewinds.js',
  cssSrc: 'https://tradewinds.asp.manamind.com/assets/tradewinds.css',
  openingHours: 'https://tradewinds.asp.manamind.com/openinghours',
  exchangeOverview: 'https://tradewinds.asp.manamind.com/overview',
  exchangeSectors: 'https://tradewinds.asp.manamind.com/sectormap',
  userShares: 'https://tradewinds.asp.manamind.com/usershares',
  quotesListAll: 'https://tradewinds.asp.manamind.com/quotespage'
};

export default (edition) => {
  return {
    newsBlocks: defaultConfig[edition],
    jobsBlock: { },
    shippingBlock
  };
};
