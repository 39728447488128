const resolveTitle = vm => {
  const { title, documentTitle } = vm.$store.state.page.meta;
  if (!documentTitle && !title) {
    throw Error('Could not resolved Title, MetaData Not Found');
  }
  return documentTitle || title;
};

const MetaInServer = {
  created() {
    const title = resolveTitle(this);
    if (title) {
      this.$ssrContext.title = title;
    }
  }
};

const MetaInClient = {
  mounted() {
    const title = resolveTitle(this);
    if (title) {
      document.title = title;
    }
  }
};

export default process.env.VUE_ENV === 'client' ? MetaInClient : MetaInServer;
