<template>
  <Component :is="navbar"/>
</template>
<script>
import MainNavbar from './MainNavbar';
import SponsoredNavbar from './SponsoredNavbar';

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    MainNavbar,
    SponsoredNavbar,
    /* eslint-enable vue/no-unused-components */
  },
  computed: {
    navbar() {
      return this.$route.meta.pageContext.edition === 'sponsor'
        ? SponsoredNavbar : MainNavbar;
    }
  }
};
</script>
