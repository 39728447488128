<template>
  <div class="news-panel">
    <div class="heading d-flex justify-content-between">
      <h3 class="fw-normal">{{ showSettingsPanel ? $store.state.i18nMessages['alert.my.settings'] : $store.state.i18nMessages['alert.my.news.stream'] }}</h3>
      <div
        @click="showSettingsPanel = !showSettingsPanel"
        class="toggle-btn d-block d-lg-none mb-2">
        <button :class="['btn', showSettingsPanel ? 'btn-primary' : 'btn-outline-primary']" >
          <span v-if="!showSettingsPanel">
            <FilterIcon/>
            {{ __('alert.settings') }}
          </span>
          <span v-else>
            <FontAwesomeIcon :icon="faTimes"/>
            {{ __('alert.close') }}
          </span>
        </button>
      </div>
    </div>
    <div class="content p-3">
      <SettingsPanel
        v-if="showSettingsPanel"
        no-heading/>
      <div v-else>
        <AlertSpinner :show="newsLoading"/>
        <AlertPromoSuggestedTopics v-if="showSuggestions"/>
        <template
          v-else
          v-for="(newsChunk, timestamp) in newsStream">
          <NewsStreamItem
            :key="newsChunk.canonicalUrl"
            :timestamp="parseInt(timestamp)"
            :news-chunk="newsChunk"/>
        </template>
        <div
          v-if="showErrors"
          class="canonical-url ff-flama fs-md fw-medium pb-3 pt-3">
          <h5>{{ __('alert.error.fetching.news.stream') }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  alertLocalStorageUtil,
  isEmpty,
  EventBus,
  AlertNewsStreamMiddleware,
  AlertSubscriptionClient,
  moment,
  checkUserLoginCookie
} from 'global-utils';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import NewsStreamItem from './NewsStreamItem';
import AlertPromoSuggestedTopics from './AlertPromoSuggestedTopics';
import SettingsPanel from './SettingsPanel';
import FilterIcon from './FilterIcon.vue';
import AlertSpinner from './AlertSpinner';

export default {
  name: 'news-panel',
  components: {
    NewsStreamItem,
    AlertPromoSuggestedTopics,
    SettingsPanel,
    FilterIcon,
    FontAwesomeIcon,
    AlertSpinner,
  },
  data() {
    return {
      newsLoading: false,
      showSuggestions: false,
      suggestions: [],
      newsStream: {},
      showErrors: false,
      showSettingsPanel: false,
      newsBlurStyle: {
        '-webkit-filter': `blur(5px)`,
        '-moz-filter': `blur(5px)`,
        '-ms-filter': `blur(5px)`,
        '-o-filter': `blur(5px)`,
        filter: `blur(5px)`,
      },
      faTimes,
    };
  },
  async beforeMount() {
    this.alertNewsStreamMiddleware = new AlertNewsStreamMiddleware(this, appEnv);
    this.alertSubscriptionClient = new AlertSubscriptionClient(this);
    await this.init();
  },
  async mounted() {
    EventBus.$on('newsPanel:refresh', this.init);
    this.refreshLocalStorage(); // for syncing with app data with web data everytime we sync
  },
  methods: {
    async init() {
      this.newsLoading = true;

      // check local storage if there is any subscription
      const subscriptionData = await alertLocalStorageUtil.getItem();
      if (!isEmpty(subscriptionData)) {
        this.newsStream = [];
        this.showSuggestions = false;
        this.showErrors = false;

        try {
          const newsStreamData = await this.alertNewsStreamMiddleware.fetchAndDecorate();
          /**
           * Article information in newsStreamData always contains firstPublishedTime in user time zone
           * */
          for (const timestamp in newsStreamData) {
            newsStreamData[timestamp].forEach(article => {
              article.firstPublishedTime = moment.utc(article.firstPublishedAtWithDateAndTime).tz(this.$pubCtx.timeZone).format(this.$pubCtx.dateFormatConfig.alertPage.time);
            });
          }
          if (isEmpty(newsStreamData)) {
            this.showSuggestions = true;
          } else {
            this.newsStream = newsStreamData;
          }
        } catch (e) {
          console.error('Error fetching NewsStream data.', e);
          this.showErrors = true;
        }
      } else {
        // load suggested topics
        this.showSuggestions = true;
      }
      this.newsLoading = false;
    },
    async refreshLocalStorage() {
      await alertLocalStorageUtil.clearStorage();
      if (checkUserLoginCookie(this)) {
        await this.alertSubscriptionClient.fetch();
      }
    }
  }
};
</script>
<style lang="scss">
.news-panel {
  .heading {
    border-bottom: 3px solid;
    @include borderColor('primary');
  }
  .content {
    background-color: white;
  }
}
</style>
