<template>
  <div
    v-on-clickaway="close"
    @click="toggle"
    class="position-relative mr-5">
    <a
      :href="href"
      :target="target ? target : (isStandaloneView ? '_parent' : false)">
      {{ label }}
    </a>
    <template v-if="children.length">
      <FontAwesomeIcon :icon="icon"/>
      <ul :class="['dropdown', {expand}]">
        <li
          v-for="child in children"
          :key="child.label">
          <a
            :href="child.href"
            :target="isStandaloneView ? '_parent' : false">
            {{ child.label }}
          </a>
        </li>
      </ul>
    </template>
  </div>
</template>
<script>
import VueClickaway from 'vue-clickaway';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons';

export default {
  name: 'regular-menu-item',
  components: {
    FontAwesomeIcon
  },
  inject: ['isStandaloneView'],
  mixins: [
    VueClickaway.mixin,
  ],
  props: {
    label: String,
    href: String,
    target: String,
    children: {
      type: Array,
      default: () => ([]),
    }
  },
  data() {
    return {
      expand: false,
      icon: faChevronDown,
    };
  },
  methods: {
    open() {
      if (this.isStandaloneView && this.children.length > 0) {
        this.openFlyoutView();
      }

      this.expand = true;
      this.icon = faChevronUp;
    },
    close() {
      this.expand = false;
      this.icon = faChevronDown;

      if (this.isStandaloneView) {
        this.closeFlyoutView();
      }
    },
    toggle() {
      this.expand ? this.close() : this.open();
    },
    openFlyoutView() {
      console.log(`RegularMenuItem :: Header - isFlyoutOpen : true`);
      window.parent.postMessage({ 'header-is-flyout-open': true }, '*');
    },
    closeFlyoutView() {
      if (this.$parent.$parent.$children
        .filter(e => e.hasDropDownMenu !== undefined && e.hasDropDownMenu)
        .flatMap(e => e.$children)
        .filter(e => ((e.show !== undefined && e.show) || (e.expand !== undefined && e.expand))).length === 0) {
        console.log(`RegularMenuItem :: Header - isFlyoutOpen : false`);
        window.parent.postMessage({ 'header-is-flyout-open': false }, '*');
      }
    },
  }
};
</script>
<style lang="scss">
.main-nav .dropdown {
  display: none;
  position: absolute;
  top: 46px;
  width: 180px;
  padding: 5px;
  margin: 0;
  font-size: 1.8rem;
  list-style: none;
  background-color: white;
  background-clip: padding-box;
  box-shadow: 2px 3px 6px 0 gray;

  &.expand {
    display: block;
  }

  li {
    margin-bottom: 0;

    a {
      display: block;
      width: 100%;
      padding: 5px;
    }
  }
}
</style>
