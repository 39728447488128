<template>
  <column md="12">
    <div class="news-panel d-flex">
      <div class="alerts-news-row pl-4 pr-4 mt-1 center d-flex flex-row flex-wrap">
        <div
          v-for="(news, index) in newsStream"
          :key="index"
          class="card alerts-teaser teaser px-3 py-3 mb-3 mt-3 mr-3 d-flex flex-column justify-content-between flex-wrap">
          <div>
            <card-link
              :text="news.category.title ? news.category.title.replace(/_/gi, ' ').replace(/-/gi, ' '): ''"
              :url="`/${news.category.id}`"
              style-class="card-link main-category ff-flama fs-sm fw-bold mb-2 d-block text-capitalize highlight"/>
            <h5>
              <a
                :href="news.canonicalUrl"
                v-html="news.title"
                class="card-link text-reset"
                target="_blank"></a>
            </h5>
          </div>
          <div class="alert-matches">
            <small class="ff-flama fw-light d-inline-flex alert-topics-mini-label">{{ __('alert.because.you.subscribed.for') }}</small>
            <a
              :href="`${$store.state.baseUrl}/${news.alertTopics[0].url.replace(/^\/+/, '')}`"
              class="ff-flama fw-light d-inline-flex alert-topics-mini"
              target="_blank">{{ news.alertTopics[0].label }}</a>
            <a
              v-if="news.alertTopics.length > 1"
              :href="`${$store.state.baseUrl}/alerts`"
              class="ff-flama fw-light d-inline-flex alert-topics-mini"
              target="_blank">{{ __('more.alert.items', news.alertTopics.length - 1) }}</a>
          </div>
        </div>
      </div>
    </div>
  </column>
</template>

<script>
export default {
  name: 'alert-promo-news-stream',
  props: {
    newsdata: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      newsStream: this.newsdata || []
    };
  }
};
</script>

<style lang="scss" scoped>
  .alert-topics-mini {
    font-size: 1.2rem;
    font-weight: 300;
  }

  .alert-topics-mini-label {
    font-size: 1.2rem;
  }
</style>
