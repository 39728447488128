<template>
  <div class="order-page wrapper ff-flama">
    <Row class="d-flow order-action mb-4">
      <h3 class="text-center tt-uppercase px-2 py-2">Vil du annonsere i Fiskeribladet?</h3>
      <Column
        class="order-xs-2 my-4"
        xs="12"
        md="6">
        <button
          @click="goToPage('privat')"
          class="btn btn-primary order-action-btn my-4 px-4 py-4 float-right">Privat</button>
      </Column>
      <Column
        class="order-xs-1 my-4"
        xs="12"
        md="6">
        <button
          @click="goToPage('bedrift')"
          class="btn btn-secondary order-action-btn my-4 px-4 py-4 float-left">Bedrift</button>
      </Column>
    </Row>
  </div>
</template>

<script>
export default {
  name: 'order-landing',
  methods: {
    goToPage(pagename) {
      if (pagename === 'bedrift') {
        Object.assign(document.createElement('a'), {
          target: '_blank',
          href: 'https://fiskeribladet.ocast.com/',
        }).click();
      } else {
        this.$router.push({ path: '/bestill-annonse/privat' });
      }
    }
  }
};
</script>
