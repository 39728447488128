import commonEditionConfig from './commonEditionpage';
import commonFrontpageConfig from './commonFrontpage';
const defaultConfig = {
  frontpage: commonFrontpageConfig,
  test_frontpage: commonFrontpageConfig,
  markets: commonEditionConfig,
  power_players: commonEditionConfig,
  technology: commonEditionConfig,
  transition: commonEditionConfig,
  wind: commonEditionConfig
};

export default (edition) => {
  return {
    newsBlocks: defaultConfig[edition],
    jobsBlock: { }
  };
};
