<template>
  <div
    v-on-clickaway="close"
    :class="['action-menu-item ml-4']">
    <button
      @click="toggle"
      type="button"
      class="action-button">
      <FontAwesomeIcon :icon="icon_"/>
    </button>
    <div :class="['action-menu-item-container-wrapper', { show, fullBg }]">
      <div class="d-flex justify-content-center">
        <div class="action-menu-item-container">
          <SearchMenuItem
            v-if="isFullMenu"
            class="menu-item d-lg-none"/>
          <Component
            :is="itemComponent"
            class="menu-item"/>
        </div>
      </div>
      <ActionMenuFooterItem v-if="isFullMenu"/>
    </div>
  </div>
</template>
<script>
import VueClickaway from 'vue-clickaway';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { SearchMenuItem, FullMenuItem, UserMenuItem } from './Items';
import ActionMenuFooterItem from './ActionMenuFooterItem';

import './ActionMenuItem.scss';

export default {
  name: 'action-menu-item',
  components: {
    FontAwesomeIcon,
    ActionMenuFooterItem,
    /* eslint-disable vue/no-unused-components */
    SearchMenuItem,
    FullMenuItem,
    UserMenuItem,
    /* eslint-enable vue/no-unused-components */
  },
  mixins: [
    VueClickaway.mixin,
  ],
  props: {
    of: {
      type: String,
      required: true,
    },
    icon: {
      type: Object,
      required: true,
    },
    fullBg: {
      type: Boolean,
      default: false,
    }
  },
  inject: ['isStandaloneView'],
  data() {
    return {
      show: false,
      icon_: this.icon,
    };
  },
  computed: {
    isFullMenu() {
      return this.of === 'fullMenu';
    },
    itemComponent() {
      switch (this.of) {
      case 'user':
        return UserMenuItem;
      case 'search':
        return SearchMenuItem;
      case 'fullMenu':
        return FullMenuItem;
      default:
        throw new Error('No action menu item component found.');
      }
    },
  },
  methods: {
    open() {
      if (this.isStandaloneView) {
        this.openFlyoutView();
      }

      this.show = true;
      this.icon_ = faTimes;
    },
    close() {
      this.show = false;
      this.icon_ = this.icon;

      if (this.isStandaloneView) {
        this.closeFlyoutView();
      }
    },
    openFlyoutView() {
      console.log(`ActionMenuItem :: Header - isFlyoutOpen : true`);
      window.parent.postMessage({ 'header-is-flyout-open': true }, '*');
    },
    closeFlyoutView() {
      if (this.$parent.$parent.$children
        .filter(e => e.hasDropDownMenu !== undefined && e.hasDropDownMenu)
        .flatMap(e => e.$children)
        .filter(e => ((e.show !== undefined && e.show) || (e.expand !== undefined && e.expand))).length === 0) {
        console.log(`ActionMenuItem :: Header - isFlyoutOpen : false`);
        window.parent.postMessage({ 'header-is-flyout-open': false }, '*');
      }
    },
    toggle() {
      this.show ? this.close() : this.open();
    },
  },
};
</script>
