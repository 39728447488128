/**
 * Vue plugin for createI18n
 *
 * @param Vue
 */
export default (Vue) => {
  Vue.mixin({
    beforeCreate() {
      if (this.$options.pubCtx) {
        this.$pubCtx = this.$options.pubCtx;
      } else if (this.$options.parent && this.$options.parent.$pubCtx) {
        this.$pubCtx = this.$options.parent.$pubCtx;
      }
    }
  });
};
