<template>
  <nav class="main-nav d-flex justify-content-center sponsor-content mb-3 d-print-none">
    <header class="d-flex justify-content-between align-items-center">
      <div class="d-flex flex-wrap w-100 justify-content-between">
        <div>
          <a
            href="/"
            class="site-logo">
            <img :src="`${$cdn.resource(`gfx/${$pubCtx.name}/logo.svg`)}`">
          </a>
        </div>
        <div class="fs-xl tt-uppercase m-sm-auto">{{ __('sponsor.content.header.title') }}</div>
        <div
          @click="showSponsorInfo = !showSponsorInfo"
          class="sponsor-content-info">
          <FontAwesomeIcon :icon="faInfoCircle"/>
          <div
            v-show="showSponsorInfo"
            class="popover fs-lg lh-22 p-3">
            <div class="point"></div>
            {{ __('sponsor.content.header.info', $pubCtx.label) }}
          </div>
        </div>
      </div>
    </header>
  </nav>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

export default {
  name: 'sponsored-navbar',
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faInfoCircle,
      showSponsorInfo: false,
    };
  }
};
</script>

<style lang="scss" scoped>
  .main-nav {
    header {
      width: 1272px;
      font-family: "Flama", $fallback-sans-serif-fonts;
      font-size: 1.8rem;
      line-height: 2.2rem;

      nav {
        width: 100%;
      }
    }
  }
</style>
