<template>
  <div class="latest-news-ticker ff-flama fs-md fw-medium tt-uppercase d-flex">
    <a
      :href="tickerHeaderUrl"
      class="alert-dot">
    </a>
    <a
      v-if="latestArticles.length > 0"
      :href="tickerHeaderUrl"
      class="d-none d-lg-block ticker-header">
      {{ tickerHeader }}
    </a>

    <div class="ticker">
      <div
        id="latest-article-list"
        class="item-container">
        <a
          v-for="(articleData) in latestArticles"
          :key="articleData.order"
          :href="articleData.articleUrl"
          class="ticker-item">
          {{ articleData.articleTitle }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'latest-news-ticker',
  dataResolver: 'latestArticles',
  props: {
    tickerHeader: {
      type: String,
      default: 'Latest News:'
    },
    tickerHeaderUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      latestArticles: this.$store.state.latestArticles
    };
  },
  mounted() {
    window.addEventListener('load', function () {
      const itemContainer = document.getElementById('latest-article-list');

      // Sum the total width of all ticker items
      const items = itemContainer.getElementsByTagName('a');
      var containerLength = 0;
      for (var i = 0; i < items.length; i++) {
        containerLength += items[i].offsetWidth + 16;
      }

      // Internet Explorer workaround: set the width of the item container to the length of the items
      itemContainer.style.width = containerLength + 'px';
      // Internet Explorer workaround end

      // Calculate animation duration
      var duration = (containerLength / 60) * 1000;
      duration = Math.max(duration, 40000);
      duration = Math.min(duration, 150000);

      itemContainer.style.animationDuration = `${duration}ms`;
      itemContainer.style.animationName = 'slide-left';
    });
  },
};
</script>
