import { loadJs } from 'global-utils/asset-loader';
import {
  getGtmScriptHead,
  getGtmScriptBody,
  gtagScriptHead,
  gtagScript
} from 'global-components';
import { shippingIndexResolver } from 'global-configs/page-config';

export function jQuery() {
  return import('jquery').then(mod => {
    window.jQuery = window.$ = mod.default;
    return Promise.resolve(mod.default);
  });
}

export function googlePublisherTag() {
  return loadJs('https://www.googletagservices.com/tag/js/gpt.js');
}

export function adScripts(pubCtx) {
  return [
    jQuery(),
    googlePublisherTag(),
    import('global-ads/src/AdPublisher.js'),
  ];
}

export function owlCarouselScripts() {
  return [
    jQuery(),
    import('owl.carousel')
  ];
}

export function gtmHeadScriptLoader() {
  return [
    jQuery(),
    getGtmScriptHead
  ];
}

export function gtmBodyScriptLoader() {
  return [getGtmScriptBody];
}

export function shippingIndexLoader(store) {
  const shippingBlock = shippingIndexResolver(store.state.publication.code);
  return [
    jQuery(),
    loadJs(shippingBlock.javaScriptSrc)
  ];
}

export function gTagScriptHeadLoader() {
  return [gtagScriptHead];
}

export function gTagScriptLoader() {
  return [gtagScript];
}
