import axios from 'axios';
import { EventBus } from './EventBus';
import { isEmpty, _isNil, alertLocalStorageUtil } from 'global-utils';

export class AlertSubscriptionClient {
  constructor(vm) {
    this.vm = vm;
    this.logger = (window && window.logger) || console || {};
  }

  get endpoint() {
    return `/alerts-service/subscription`;
  }

  get httpHeaders() {
    return {
      'Content-Type': 'application/json'
    };
  }

  get httpClient() {
    const client = axios.create({
      headers: this.httpHeaders
    });
    client.defaults.withCredentials = true;
    return client;
  }

  async fetch(sync = true) {
    var start = Date.now();
    try {
      var resp = await this.httpClient.get(this.endpoint);
      const subscriptionData = resp.data;

      this.logDebug(`GET ${this.endpoint} (Time Elapsed: ${Date.now() - start}ms)`);

      if (subscriptionData &&
        isEmpty(subscriptionData.topics) &&
        isEmpty(subscriptionData.authors) &&
        isEmpty(subscriptionData.searchQueries)
      ) {
        throw new Error('Subscription list is blank!');
      }

      if (sync) {
        EventBus.$emit('sync:alerts:fetched', resp.data);
      }

      if (subscriptionData &&
        isEmpty(subscriptionData.topics) &&
        isEmpty(subscriptionData.authors) &&
        isEmpty(subscriptionData.searchQueries)
      ) {
        throw new Error('Subscription list is blank!');
      }

      return resp.data;
    } catch (err) {
      if (err.response && err.response.data) {
        this.logError(`Subscription list fetch api backend down / not set. Fetch Failed with status_code = ${err.response.status}`);
      }

      throw err;
    }
  }

  async save(subscriptionType, data) {
    await this.syncLocalStorageExpiry();

    var start = Date.now();
    try {
      var resp = await this.httpClient.post(this.endpoint + `/${subscriptionType}`, data);

      this.logDebug(`POST ${this.endpoint}/${subscriptionType} (Time Elapsed: ${Date.now() - start}ms)`);
      EventBus.$emit('sync:alerts:saved', { type: subscriptionType, data: resp.data });

      return resp.data;
    } catch (err) {
      if (err.response && err.response.data) {
        this.logError(`Subscription data create api backend down / not set. Create Failed with status_code = ${err.response.status}`);
      } else {
        console.error(err);
      }
      throw err;
    }
  }

  async update(subscriptionType, subscriptionId, data) {
    await this.syncLocalStorageExpiry();

    var start = Date.now();
    try {
      var resp = await this.httpClient.put(this.endpoint + `/${subscriptionType}/${subscriptionId}`, data);

      this.logDebug(`PUT ${this.endpoint}/${subscriptionType}/${subscriptionId} (Time Elapsed: ${Date.now() - start}ms)`);
      EventBus.$emit('sync:alerts:updated', { type: subscriptionType, data: resp.data });

      return resp.data;
    } catch (err) {
      if (err.response && err.response.data) {
        this.logError(`Subscription data update api backend down / not set. Update Failed with status_code = ${err.response.status}`);
      } else {
        console.error(err);
      }

      throw err;
    }
  }

  async delete(subscriptionType, subscriptionId, data) {
    await this.syncLocalStorageExpiry();

    var start = Date.now();
    try {
      var resp = await this.httpClient.delete(
        this.endpoint + `/${subscriptionType}/${subscriptionId}`,
        {
          headers: this.httpHeaders,
          data: data
        }
      );

      this.logDebug(`DELETE ${this.endpoint}/${subscriptionType}/${subscriptionId} (Time Elapsed: ${Date.now() - start}ms)`);
      EventBus.$emit('sync:alerts:deleted', { type: subscriptionType, data: resp.data });

      return resp.data;
    } catch (err) {
      if (err.response && err.response.data) {
        this.logError(`Subscription data delete api backend down / not set. Update Failed with status_code = ${err.response.status}`);
      } else {
        console.error(err);
      }

      throw err;
    }
  }

  async syncLocalStorageExpiry() {
    const subscriptionData = await alertLocalStorageUtil.getItem();
    if (!subscriptionData || (
      _isNil(subscriptionData.topics) &&
      _isNil(subscriptionData.authors) &&
      _isNil(subscriptionData.searchQueries)
    )) {
      this.fetch(false);
    }
  }

  logError(msg) {
    this.logger.error(`[${this.constructor.name}] ${msg}`);
  }

  logDebug(msg) {
    if (debugMode) {
      this.logger.debug(`[${this.constructor.name}] ${msg}`);
    }
  }
}
