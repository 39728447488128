<template>
  <card
    v-if="teaserElement.title"
    :class="[`teaser teaser-type-${teaserType} ${teaserConfig.topBorder}`,
             teaserElement.mainCategory.id,
             `mb-3`,
             getHighlight(), 'flex-grow-1']">
    <card-body>
      <!-- Tag -->
      <TeaserIcon
        v-if="teaserElement.paid && showMainCategory"
        style-class="paid-article-icon"/>
      <TeaserCategory
        :is-cross-publication="isCrossPublicationTeaser"
        :show-main-category="showMainCategory"
        :main-category="mainCategory"
        :main-category-classes="teaserConfig.mainCategoryClasses"
        :teaser-element-highlight="teaserElement.teaserHighlight"
        :article-publication="articlePublication"/>
      <div :class="['teaser-body', 'clearfix', teaserConfig.teaserBodyClasses]">
        <Row>
          <Column :sm="grid[0]">
            <div class="teaser-body-image">
              <TeaserKicker
                :show-kicker="showKicker"
                :hide-image="hideImage"
                :kicker-style="teaserElement.kickerStyle"
                :kicker-text="teaserElement.kickerText"
                :kicker-classes="teaserConfig.kickerClasses"
                :lead-asset="teaserElement.leadAsset"
                :article-pub-name="articlePubName"
                :is-cross-publication="isCrossPublicationTeaser"/>
              <TeaserIcon
                v-if="teaserElement.paid && !showMainCategory"
                style-class="paid-article-icon-over-image mr-4 mt-1"/>
              <a
                :href="url"
                :rel="relationAttribute">
                <TeaserImage
                  :hide-image="hideImage"
                  :image-classes="teaserConfig.imageClasses"
                  :lead-asset="teaserElement.leadAsset"
                  :image-size="imageSize"/>
              </a>
            </div>
          </Column>
          <Column :sm="grid[1]">
            <div class="d-flex flex-column h-100">
              <slot name="header"></slot>
              <div class="mb-auto">
                <h2
                  v-if="teaserElement.title"
                  :class="['teaser-title', teaserConfig.titleClasses, crossPublicationTitleClass()]">
                  <card-link
                    :text="teaserElement.title"
                    :url="url"
                    :rel="relationAttribute"
                    style-class="text-reset"/>
                </h2>
                <card-text
                  v-if="showLeadText && teaserElement.leadText"
                  :class="teaserConfig.leadtextClasses"
                  v-text="$options.html2Text(teaserElement.leadText)"/>
                <slot name="body"></slot>
              </div>
              <slot name="footer"></slot>
            </div>
          </Column>
        </Row>
      </div>
    </card-body>
  </card>
</template>

<script>
import html2Text from 'html-to-text';
import { polyfillURL, checkIfFrontArticle, isEmpty, checkIfSponsoredArticle } from 'global-utils';
import { isBranded } from 'global-utils/vue';
import teaserConfig from './teaserConfig';
import { TeaserImage, TeaserKicker, TeaserCategory, TeaserIcon } from './teaserElements';
import { crossPublicationHighlightClass, teaserHighlightExists } from './teaserUtils';

export default {
  name: 'teaser',
  components: {
    TeaserIcon,
    TeaserImage,
    TeaserKicker,
    TeaserCategory,
  },
  props: {
    teaserType: {
      type: String,
      default: 'large'
    },
    teaserElement: {
      type: Object,
      required: true
    },
    showLeadText: {
      type: Boolean,
      default: false
    },
    showMainCategory: {
      type: Boolean,
      default: true,
    },
    imageSize: {
      type: String,
      default: 'medium'
    },
    eager: Boolean,
    hideImage: {
      type: Boolean,
      default: false
    },
    imageAlignment: {
      type: String
    },
    showKicker: {
      type: Boolean,
      default: true
    },
    grid: {
      type: Array,
      default: () => ([12, 12]),
    },
    highlightByCategory: {
      type: String,
      default: '',
      required: false
    }
  },
  data() {
    return {
      mainCategory: this.getMainCategory()
    };
  },
  computed: {
    isFrontArticle() {
      return checkIfFrontArticle(this.teaserElement.type);
    },
    relationAttribute() {
      return checkIfSponsoredArticle(this.teaserElement.type) ? 'sponsored' : null;
    },
    articlePubUrl() {
      return polyfillURL(this.teaserElement.canonicalUrl);
    },
    requestPub() {
      return polyfillURL(this.$store.state.requestUrl).hostname.replace(/(dev.|test.|stage.|beta.|www.)/, '');
    },
    articlePublication() {
      return (this.isFrontArticle ? this.$pubCtx.title : this.articlePubUrl.hostname)
        .replace(/(dev.|test.|stage.|beta.|www.)/, '');
    },
    articlePubName() {
      return this.articlePublication.replace('.com', '').replace('.no', '');
    },
    isCrossPublicationTeaser() {
      return (this.articlePublication !== this.requestPub);
    },
    teaserConfig() {
      return teaserConfig(this.teaserType, this.imageAlignment);
    },
    authorList() {
      return this.teaserElement.authors.map(author => author.name).join(', ');
    },
    url() {
      if (this.isFrontArticle) {
        return this.teaserElement.canonicalUrl;
      }
      const artUrl = this.articlePubUrl;
      if (this.isCrossPublicationTeaser) {
        // Returns full path (href) of a `URL` for cross publication articles
        return artUrl.href;
      }
      // Returns relative path for same publication articles
      return artUrl.pathname + artUrl.search;
    },
    isSamePageCategory() {
      return this.teaserElement.mainCategory.id === this.$route.meta.pageContext.type;
    }
  },
  methods: {
    isBranded,
    getSpecialHighlight() {
      for (var i = 0; i < this.teaserElement.categories.length; i++) {
        if (this.teaserElement.categories[i] === this.highlightByCategory) {
          return 'highlight special';
        }
      }
      return '';
    },
    getCrossPubHighlight() {
      return crossPublicationHighlightClass(this.$pubCtx, this.teaserElement.teaserHighlight, this.articlePublication);
    },
    getHighlight() {
      if (this.highlightByCategory !== '') {
        return this.getSpecialHighlight();
      } else if (teaserHighlightExists(this.teaserElement.teaserHighlight)) {
        const pubShortCode = this.$pubCtx.publicationShortCodeMap[this.articlePublication];
        if (this.teaserElement.teaserHighlight.includes('-')) {
          const highlightParts = this.teaserElement.teaserHighlight.split('-');
          return `highlight ${pubShortCode}-${highlightParts[1]}`;
        }
        return `highlight ${pubShortCode}-${this.teaserElement.teaserHighlight}`;
      }

      return '';
    },
    crossPublicationTitleClass() {
      if (this.isCrossPublicationTeaser) {
        return this.getCrossPubHighlight();
      }
      return '';
    },
    getMainCategory() {
      let category = this.teaserElement.mainCategory;
      if (this.isFrontArticle) {
        category = this.teaserElement.mainCategory || this.$pubCtx.fallbackTeaserCategory;
      } else if (this.$pubCtx.topicHead || this.$route.meta.pageContext.topicHead) {
        category = !isEmpty(this.teaserElement.topic.name) ? this.teaserElement.topic
          : this.$pubCtx.fallbackTeaserCategory;
      }
      return category;
    },
  },
  html2Text(html) {
    return html2Text.fromString(html);
  },
};
</script>
