<template>
  <div
    class="regular-menu d-none d-xl-flex justify-content-center align-items-center text-nowrap">
    <RegularMenuItem
      v-for="{ label, href, target, items, htmlClass } in topMenu.items"
      :key="label"
      :label="label"
      :href="href"
      :target="target"
      :class="htmlClass"
      :children="items"/>
  </div>
</template>
<script>
import RegularMenuItem from './RegularMenuItem';

export default {
  name: 'regular-menu',
  components: {
    RegularMenuItem,
  },
  data() {
    return {
      hasDropDownMenu: true,
    };
  },
  computed: {
    topMenu() {
      return this.$pubCtx.menuConfigs.topMenu;
    },
  },
};
</script>
