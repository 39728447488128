<template>
  <Modal
    v-if="showConfirm"
    @close="$emit('close')"
    class="ff-flama">
    <template v-slot:header>
      <div class="alert-header">
        <template v-if="showSuccess">
          <FontAwesomeIcon :icon="icons.faBell"/>
          <template v-if="alertNoEmail">
            {{ __('alert.created.no.email') }}
          </template>
          <template v-else>
            {{ __('alert.created') }}
          </template>
        </template>
        <template v-if="!showSuccess && !showAlreadySubscribed">
          <FontAwesomeIcon
            :icon="icons.faBellSolid"
            class="icon-red-alert"/>
          {{ __('alert.creation.error') }}
        </template>
        <template v-if="!showSuccess && showAlreadySubscribed">
          <FontAwesomeIcon
            :icon="icons.faBellSolid"
            class="icon-blue-alert"/>
          {{ __('alert.already.created') }}
        </template>
      </div>
    </template>
    <template v-slot:body>
      <div>
        <p
          v-if="showSuccess && fromPage"
          class="ff-flama fs-lg fw-light pb-3 pt-3">{{ __('confirm.alert.from.page') }}</p>
        <p
          v-if="showSuccess && !fromPage"
          v-html="__('confirm.alert.note')"
          class="ff-flama fs-lg fw-light pb-3 pt-3"></p>
        <p
          v-if="!showSuccess && !showAlreadySubscribed"
          v-html="__('alert.error.note')"
          class="ff-flama fs-lg fw-light pb-3 pt-3"></p>
        <p
          v-if="!showSuccess && showAlreadySubscribed"
          v-html="__('alert.subscribed.note', __(showAlreadySubscribed))"
          class="ff-flama fs-lg fw-light pb-3 pt-3"></p>
        <button
          v-if="!showSuccess && showAlreadySubscribed"
          @click="goToMyAlerts"
          class="continue btn btn-primary w-100 mb-4">
          {{ __('manage.alert') }}
        </button>
        <button
          @click="closeAlertConfirmModal"
          class="continue btn btn-primary w-100 mb-4">
          {{ __('btn.continue') }}
        </button>
      </div>
    </template>
  </Modal>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { faBell as faBellSolid } from '@fortawesome/pro-solid-svg-icons';
import { EventBus } from 'global-utils';

export default {
  name: 'alert-confirm-modal',
  components: { FontAwesomeIcon },
  props: {
    showConfirm: {
      type: Boolean,
      default: false,
    },
    showSuccess: {
      type: Boolean,
      default: true,
    },
    fromPage: {
      type: Boolean,
      default: false,
    },
    showAlreadySubscribed: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      icons: {
        faBell,
        faBellSolid
      },
      alertNoEmail: false
    };
  },
  async mounted() {
    EventBus.$on('alerts:no:email', () => {
      this.alertNoEmail = true;
    });
  },
  methods: {
    goToMyAlerts() {
      window.location.href = '/alerts';
    },
    closeAlertConfirmModal() {
      this.$emit('close');
      this.alertNoEmail = false;
    }
  }
};
</script>
