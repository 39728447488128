import { kebabCase } from 'global-utils';

function serialize(attr) {
  return Object.entries(attr).reduce((cum, [key, val]) => {
    return cum + `${kebabCase(key)}="${val}" `;
  }, '').trim();
}

class EmbedBuilder {
  items = {};

  add(item, attr = {}) {
    this.items[item] = attr;
    return this;
  }

  meta(attr = {}, val) {
    if (typeof attr === 'string' && val) {
      attr = { attr: val };
    }
    return this.add(`<meta ${serialize(attr)}>`, attr);
  }

  link(href, attr = {}) {
    attr.href = href;
    return this.add(`<link ${serialize(attr)}>`, attr);
  }

  style(href, attr = {}) {
    attr.rel = 'stylesheet';
    return this.link(href, attr);
  }

  script(src, attr = {}) {
    attr.src = src;
    attr.type = 'text/javascript';
    return this.add(`<script ${serialize(attr)}></script>`, attr);
  }

  flush() {
    const list = Object.keys(this.items);
    this.items = {};
    return list;
  }
}

const headEmbedBuilder = new EmbedBuilder();
const bodyEmbedBuilder = new EmbedBuilder();

const EmbedInServer = {
  created() {
    if (!this.$options.embed) return;
    if (typeof this.$options.embed !== 'function') {
      throw new Error(`'embed' must be a function in component: '${this.$options.name}'`);
    }
    this.$ssrContext.headEmbedBuilder = headEmbedBuilder;
    this.$ssrContext.bodyEmbedBuilder = bodyEmbedBuilder;
    this.$options.embed.call(this, headEmbedBuilder, bodyEmbedBuilder);
  }
};

const EmbedInClient = {};

export default process.env.VUE_ENV === 'client' ? EmbedInClient : EmbedInServer;
