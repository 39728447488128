<template>
  <div class="bedrift-order wrapper ff-flama">
    <Row>
      <Column md="12">
        <MarkedPlassenBanner
          @back="$emit('back')"
          heading="For bedriftskunder"/>
      </Column>
    </Row>
    <Row class="bedrift-order-description my-3 mx-3">
      <Column
        md="2"
        class="promo-pic"></Column>
      <column
        md="9"
        class="description">
        <div class="promo-desc">
          <h3 class="tt-uppercase">For bedriftskunder</h3>
        </div>
        <div class="promo-desc">
          <p>
            Fiskeribladet er Norges ledende medie innen fiskerinæringen.
            Få ditt budskap ut til 22.100 daglige lesere*
          </p>
        </div>
      </column>
    </Row>
    <Row class="bedrift-order-products my-3 mx-3">
      <Column md="12">
        <Row class="bedrift-product-column-wrapper">
          <Column
            v-for="product in productInfo"
            :key="product.id"
            md="3"
            class="bedrift-product-column">
            <div
              v-text="product.name"
              class="product-name fw-bold"></div>
            <div class="product-desc">
              <p
                v-if="product.note !== ''"
                class="fw-light my-0">{{ product.note }}</p>
              <p class="fw-light my-0">{{ product.indents }}</p>
              <p class="fw-light my-0">{{ product.sizeLimit }}</p>
              <p class="fw-bold">{{ product.price }}</p>
            </div>
          </Column>
        </Row>
      </Column>
    </Row>
    <Row class="bedrift-contacts my-3 mx-3">
      <Column md="12">
        <div class="help-heading">Vi hjelper gjerne med å finne gode markedsføringskonsepter !</div>
        <div class="help-text">Ta kontakt med vår salgsavdeling for rådgiving om våre annonseringsprodukter.</div>
        <div class="contact-info d-inline-block">
          <div class="d-inline contact-email">
            <a href="mailto:annonse@fbfi.no">
              <FontAwesomeIcon
                :icon="emailIco"
                size="lg"
                class="emailIco pr-3"/>annonse@fbfi.no</a>
          </div>
          <div class="d-inline contact-phone">
            <a href="tel:+4755213300">
              <FontAwesomeIcon
                :icon="phoneIco"
                size="lg"
                class="phoneIco pr-3"/>55 21 33 00</a>
          </div>
        </div>
      </Column>
    </Row>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import MarkedPlassenBanner from './MarkedPlassenBanner';
import bestillAnnonse from 'global-configs/bestillAnnonse';

export default {
  name: 'order-bedrift',
  components: {
    MarkedPlassenBanner,
    FontAwesomeIcon
  },
  data() {
    return {
      productInfo: bestillAnnonse[this.$pubCtx.name].bedrift,
      emailIco: faEnvelope,
      phoneIco: faPhone
    };
  }
};
</script>
