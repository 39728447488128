<template>
  <div class="fishprice-ticker ff-flama fs-md">
    <div class="wrapper">
      <div class="py-2 ticker-carousel owl-carousel">
        <div
          v-for="({ fishType, location, currency, price, diff }, index) in prices"
          :key="'price-' + index">
          <span class="d-block mb-1">
            {{ fishType }} ({{ location }})<br>
          </span>
          <span class="col-blue">
            {{ currency }}{{ price }}
          </span>
          <span :class="['fw-medium', diff < 0 ? 'negative' : 'positive']">
            {{ 0 > diff ? '▾ ' : '▴ +' }}{{ diff }}%
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { AssetMixin, EmbedMixin } from 'global-mixin';
import { owlCarouselScripts } from 'assets/js/bundled-assets';

export default {
  name: 'fishprice-ticker',
  mixins: [AssetMixin, EmbedMixin],
  props: {
    options: Object,
  },
  data() {
    return {
      prices: [],
    };
  },
  computed: {
    mergedOptions() {
      return Object.assign({}, this.$options.defaultOptions, this.options);
    }
  },
  defaultOptions: {
    center: false,
    dots: false,
    rewind: false,
    loop: false,
    startPosition: 0,
    responsive: {
      0: {
        items: 1.25
      },
      576: {
        items: 3
      }
    }
  },
  embed(head) {
    head.style(this.$cdn.asset('owl.carousel.css'));
  },
  async assets() {
    const [{ data: prices }, $] = await Promise.all([
      axios.get('/global/api/prices'),
      ...owlCarouselScripts()
    ]);
    this.prices = prices;
    await this.$nextTick();
    $('.ticker-carousel').owlCarousel(this.mergedOptions);
  }
};
</script>
