export default {
  nextPlaceholderIdx(state) {
    return state.curPlaceholderIdx++;
  },
  resetPlaceholderIdx(state) {
    state.curPlaceholderIdx = 0;
  },
  storeSelectedEpaperYear: (state, year) => {
    state.selectedEpaperYear = year;
  },
  storeSelectedEpapers: (state, selectedEpapers) => {
    state.selectedEpapers = selectedEpapers;
  },
};
