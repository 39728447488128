import { AlertSubscriptionClient } from 'global-utils';

export class AlertGeneralSettingsClient extends AlertSubscriptionClient {
  get endpoint() {
    return super.endpoint + '/user/notificationSettings';
  }

  async fetch() {
    try {
      const res = await this.httpClient.get(this.endpoint);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  async update(data) {
    try {
      const res = await this.httpClient.put(this.endpoint, data);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
}
