<template>
  <span v-if="title">
    <span v-if="type === 'inline'">
      <a :href="url">{{ title }}</a>
    </span>
    <span
      v-else-if="type === 'inline-single'"
      style="margin-bottom: 5px">
      <b>{{ __('read.also') }}:</b> <a :href="url">{{ title }}</a>
    </span>
    <card
      v-else
      class="inline news float-left">
      <card-body>
        <card-link
          :url="url"
          class="text-reset">
          <card-image
            v-if="imageUrl"
            :image-src="imageUrl"
            :source="imageUrl"
            sizes="(min-width: 576px) 300px, 300px"
            class="mb-2"/>
          <card-title class="ff-flama fs-md fw-medium mb-3 col-black">{{ title }}</card-title>
        </card-link>
        <card-link
          :url="url"
          :text="__('read.more')"
          class="ff-flama fs-sm lh-12 float-right"/>
      </card-body>
    </card>
  </span>
</template>
<script>
export default {
  name: 'inline-news',
  props: {
    refId: String,
    type: String,
  },
  data() {
    const { title, url, imageUrl } = this.$store.state.article.inlineElements[this.refId];
    return { title, url, imageUrl };
  },
};
</script>

<style lang="scss">

.article-body .dn-relation-block {
  margin: 0 0 5px 0;
}

</style>
