<template>
  <div
    v-if="!$pubCtx.noEpaper"
    class="action-menu-item ml-4">
    <a
      :href="link"
      :target="isStandaloneView ? '_parent' : false">
      <svg
        width="25px"
        height="28px"
        viewBox="0 0 25 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <title>Newspaper Icon</title>
        <path
          d="M19.6540145,1.64439266 L19.6540145,6.60394982 L22.0887971,6.60394982 C23.1933666,6.60394982
        24.0887971,7.49938032 24.0887971,8.60394982 L24.0887971,29.4501037 C24.0887971,30.5546732 23.1933666,31.4501037 22.0887971,31.4501037 L2.43662321,31.4501037 C1.33205371,31.4501037 0.436623213,30.5546732 0.436623213,29.4501037 L0.436623213,7.96292911 C0.434176627,7.91845405 0.434161533,7.87387148 0.436623213,7.82938167 L0.436623213,6.60394982 L16.7229365,0.552999553 C18.3014383,-0.0309284815 19.6540145,0.464963383 19.6540145,1.64439266 Z M21.1322754,9.21933444 L3.39314495,9.21933444 L3.39314495,28.8347191 L21.1322754,28.8347191 L21.1322754,9.21933444 Z M18.1757536,19.6808729 L18.1757536,22.2962575 L6.34966669,22.2962575 L6.34966669,19.6808729 L18.1757536,19.6808729 Z M18.1757536,15.757796 L18.1757536,18.3731806 L6.34966669,18.3731806 L6.34966669,15.757796 L18.1757536,15.757796 Z M18.1757536,11.8347191 L18.1757536,14.4501037 L6.34966669,14.4501037 L6.34966669,11.8347191 L18.1757536,11.8347191 Z M16.6974928,3.33471905 L7.01478261,6.60369231 L16.6967826,6.60369231 L16.6974928,3.33471905 Z"/>
      </svg>
    </a>
  </div>
</template>
<script>
export default {
  name: 'e-paper-menu-item',
  inject: ['isStandaloneView'],
  computed: {
    link() {
      if (['intrafish_no', 'tekfisk'].includes(this.$pubCtx.name)) {
        return '/siste'; // latest news
      }
      return this.$pubCtx.epaperUrl || '/editions';
    }
  }
};
</script>
