export const getGtmScriptHead = vm => {
  if (!vm.$store.state.gtmCode) {
    return ``;
  }
  return `<!-- Google Tag Manager -->
  <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
    {'gtm.start': new Date().getTime(),event:'gtm.js'}
  );var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${vm.$store.state.gtmCode}');</script>
  <!-- End Google Tag Manager -->`;
};

export const getGtmScriptBody = vm => {
  if (!vm.$store.state.gtmCode) {
    return ``;
  }
  return `<!-- Google Tag Manager (noscript) -->
  <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${vm.$store.state.gtmCode}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  <!-- End Google Tag Manager (noscript) -->`;
};
