<template>
  <Modal
    v-show="!privacyCookieNotified && enablePrivacyCookiePopup"
    no-close-button
    class="modal-mask-privacy">
    <template v-slot:header>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 243 89"
        width="140"
        height="52"
        fill="none"
        class="mb-4">
        <path
          d="M79.23 44.05h9.65a44.13 44.13 0 0 0-44-43.8V9.9a34.48 34.48 0 0 1 34.34 34.15"
          fill="#00726C"/>
        <path
          d="M44.89 78.85h-.13a34.48 34.48 0 0 1-34.48-34.47v-.33H.64v.33a44.13 44.13 0 0 0 44.13 44.13h.13v-9.66"
          fill="#AFD46C"/>
        <path
          d="M44.76 22.23h.13V9.9h-.13a34.48 34.48 0 0 0-34.47 34.15h12.34a22.14 22.14 0 0 1 22.13-21.82Z"
          fill="#7ED3F7"/>
        <path
          d="M44.76 66.52a22.14 22.14 0 0 1-22.14-22.14v-.33H10.29v.33a34.48 34.48 0 0 0 34.47 34.48h.13V66.52h-.13Z"
          fill="#72BF44"/>
        <path
          d="M79.23 44.38v-.33H66.89v.33a22.14 22.14 0 0 1-22 22.14v12.33a34.46 34.46 0 0 0 34.34-34.47Z"
          fill="#7ED3F7"/>
        <path
          d="M57.1 44.38a12.34 12.34 0 0 1-12.21 12.33v9.81a22.14 22.14 0 0 0 22-22.14v-.33h-9.81v.33"
          fill="#00726C"/>
        <path
          d="M44.89 22.24v9.81a12.33 12.33 0 0 1 12.19 12h9.81a22.14 22.14 0 0 0-22-21.81Z"
          fill="#0072BC"/>
        <path
          d="M32.44 44.05v.33a12.34 12.34 0 0 0 12.32 12.34h.13V44.05H32.44ZM44.89 56.71A12.34 12.34 0 0 0 57.1 44.38v-.33H44.89v12.66Z"
          fill="#00B2DD"/>
        <path
          d="M32.44 44.05h12.45v-12h-.13a12.33 12.33 0 0 0-12.32 12Z"
          fill="#F15A22"/>
        <path
          d="M102.95 67.13h1.25l3.97 5.94 3.97-5.94h1.24v10.9h-1.23V69.2l-3.97 5.84h-.06l-3.97-5.83v8.82h-1.2v-10.9ZM118.02 67.13h7.88v1.12h-6.65v3.72h5.95v1.12h-5.95v3.81h6.73v1.13h-7.96v-10.9ZM133.8 76.89a4.22 4.22 0 0 0 4.51-4.28 4.24 4.24 0 0 0-4.51-4.32h-2.56v8.63l2.56-.03Zm-3.8-9.76h3.79c3.42 0 5.79 2.35 5.79 5.41A5.47 5.47 0 0 1 133.8 78H130V67.13ZM143.85 67.13h1.24v10.9h-1.24v-10.9ZM156.9 74l-2.47-5.56-2.49 5.56h4.96Zm-3-7h1.1l5 11h-1.32l-1.28-2.88h-5.93L150.16 78h-1.26l5-11ZM169.43 72.61a5.49 5.49 0 0 1 5.47-5.64c1.5-.08 2.97.43 4.1 1.42l-.79.93a4.65 4.65 0 0 0-4.96-.86 4.26 4.26 0 0 0-2.5 4.08 4.22 4.22 0 0 0 4.25 4.57c1.15 0 2.27-.4 3.17-1.11v-2.69h-3.32V72.2h4.5v4.35a6.61 6.61 0 0 1-6.52 1.3 5.35 5.35 0 0 1-3.38-5.24M188.26 72.69c1.63 0 2.8-.85 2.8-2.25 0-1.34-1-2.15-2.79-2.15h-3.36v4.43l3.35-.03Zm-4.58-5.56h4.69a4.3 4.3 0 0 1 3.1 1.09 3 3 0 0 1 .84 2.15 3.2 3.2 0 0 1-3 3.17l3.38 4.46h-1.5L188 73.79h-3.11V78h-1.23V67.13M205.85 72.61a4.32 4.32 0 0 0-7.38-3.18 4.26 4.26 0 0 0-1.18 3.14 4.32 4.32 0 0 0 5.98 4.15 4.28 4.28 0 0 0 2.58-4.12v.01Zm-9.84 0a5.56 5.56 0 1 1 11.12 0 5.56 5.56 0 0 1-11.12 0ZM211.11 73.46v-6.33h1.23v6.26c0 2.35 1.25 3.67 3.34 3.67s3.28-1.21 3.28-3.6v-6.33h1.23v6.24c0 3.18-1.82 4.83-4.55 4.83-2.73 0-4.53-1.65-4.53-4.74ZM228.58 73.06c1.81 0 3-1 3-2.4 0-1.56-1.17-2.37-2.93-2.37h-2.75v4.8l2.68-.03Zm-3.92-5.93h4.08c2.46 0 4.08 1.31 4.08 3.45 0 2.35-2 3.56-4.29 3.56h-2.64V78h-1.23V67.13ZM200.82 37.29l-7.76-2.66c-2.5-1-3.29-1.6-3.29-3.09a2.5 2.5 0 0 1 2.58-2.54h13.36v-7.68h-14.32a10.37 10.37 0 0 0-10.33 10.47c0 5.28 2.47 8.24 6.5 9.54l8.51 3c3 .82 3.27 1.75 3.27 2.86a2.45 2.45 0 0 1-1.54 2.47c-.33.13-.67.19-1.02.17h-15.64v7.63h16.74A10.4 10.4 0 0 0 208 46.86a10 10 0 0 0-7.22-9.57M102.79 21.33v36.21h8.14V35.1l17.03 22.44h6.99V21.33h-8.14v21.74l-16.43-21.74h-7.59ZM143.36 21.33v36.21h8.26V43.15h14.07v14.39h8.3V21.33h-8.22v14.19h-14.15V21.33h-8.26ZM212.15 21.33v7.65h10.81v28.49h8.25V28.98h10.93v-7.65h-29.99Z"
          fill="#004071"/>
      </svg>
      <h3
        v-text="__('privacy.cookie.header', $store.state.publication.label)"
        class="mb-3"></h3>
    </template>
    <template v-slot:body>
      <div v-html="__('privacy.cookie.body', $store.state.publication.label)"></div>
    </template>
    <template v-slot:footer>
      <button
        @click="setPrivacyCookieAccepted"
        v-text="__('ok.understand')"
        class="btn btn-primary"></button>
    </template>
  </Modal>
</template>

<script>
const resetAcceptedCookiesBeforeTime = 1635357600000; // Oct 28 2021
const notifiedCookieName = 'privacyCookieNotified';
const notifiedCookieTimeName = 'privacyCookieNotificationAcceptedAt';

export default {
  name: 'privacy-modal',
  data() {
    return {
      privacyCookieNotified: true,
      enablePrivacyCookiePopup: this.$pubCtx.enablePrivacyCookiePopup
    };
  },
  mounted() {
    this.validateAcceptedPrivacyCookie();
  },
  methods: {
    setPrivacyCookieAccepted() {
      localStorage.setItem(notifiedCookieName, 'true');
      localStorage.setItem(notifiedCookieTimeName, `${new Date().getTime()}`);
      this.privacyCookieNotified = true;
    },
    validateAcceptedPrivacyCookie() {
      const hasAcceptedCookie = localStorage.getItem(notifiedCookieName) === 'true';
      const cookieAcceptanceTime = localStorage.getItem(notifiedCookieTimeName) || false;
      const hasAcceptedAfterResetTime =
        cookieAcceptanceTime
          ? Number.parseInt(cookieAcceptanceTime) > resetAcceptedCookiesBeforeTime
          : false;
      this.privacyCookieNotified = hasAcceptedAfterResetTime && hasAcceptedCookie;
    },
  },
};

</script>
<style lang="scss" scoped>
.modal-mask.modal-mask-privacy {
  background-color: rgba(255, 255, 255, 0.7);
  font-family: "Flama", $fallback-sans-serif-fonts;
  font-size: 16px;
  color: #343434;
}

.modal-mask-privacy {
  /deep/ .modal-wrapper {
    @include media-breakpoint-down(xs) {
      padding: 5px;
    }
  }
  /deep/ .modal-container {
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.1);
    min-width: auto;
    padding: 0;
  }
  /deep/ .modal-content {
    border-radius: 10px;
  }
  /deep/ .modal-header {
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 2rem 2rem 0;

    h3 {
      font-size: 22px;
      font-weight: 700;
    }
  }
  /deep/ .modal-body {
    padding: 0 2rem;
    h4, p {
      font-size: 16px;
      margin-bottom: 25px
    }

    h4 {
      font-weight: 700;
    }
  }

  /deep/ .modal-footer {
    justify-content: center;
    padding: 2rem;
  }

  .btn-primary {
    @include backgroundColor('primary');
    @include borderColor('primary');
    border-radius: 4px;
    width: 75%;
    font-size: 16px;
    padding: 12px 20px;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }

  .btn-primary {
    &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active {
      @include backgroundColor('primary');
      @include borderColor('primary');
      color: #FFFFFF;
    }
  }
}
</style>
