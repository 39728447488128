<template>
  <div>
    <span
      v-if="!imgByline && this.$store.state.article.authors.length"
      v-text="titleCase(__('by'))">
    </span>
    <span class="authors">
      <span
        v-for="(author, idx) in authors"
        :key="idx"
        class="fw-medium">
        <a
          v-if="author.username"
          :href="`/author/${author.username}`">
          {{ author.name }}
        </a>
        <span v-if="!author.username">{{ author.name }}</span>
        <AlertButton
          v-if="author.subscribable"
          :items="[{ name: author.username, label: author.name }]"
          :title="__('alert.button.title.author', author.name)"
          sub-type="author"
          mini/>
        <span v-if="idx < authors.length - 2">, </span>
        <span v-if="idx === authors.length - 2"> {{ __('and') }} </span>
      </span>
    </span>
  </div>
</template>
<script>
import { titleCase } from 'global-utils';
import { AlertButton } from '../Alert';

export default {
  name: 'article-byline',
  components: { AlertButton },
  props: {
    imgByline: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: this.$store.state.article.title,
      authors: this.$store.state.article.authors
    };
  },
  methods: {
    titleCase,
    authorsAlertItems() {
      return this.$store.state.article.authors.reduce((items, author) => {
        items.push({ name: author.username, label: author.name });
        return items;
      }, []);
    }
  },
};
</script>
<style lang="scss">
.authors svg {
  color: black;
  font-size: 1.5rem;
}
</style>
