<template>
  <form
    :action="action"
    method="post"
    class="login-form io-subscription-block">
    <fieldset>
      <input
        :value="csrfToken"
        type="hidden"
        name="_csrf">
      <input
        id="username"
        :placeholder="__('username')"
        type="text"
        value=""
        name="username"
        class="form-control mb-3">
      <input
        id="password"
        :placeholder="__('password')"
        type="password"
        name="password"
        class="form-control mb-4">
      <input
        id="login_button"
        :value="__('login')"
        type="submit"
        class="btn btn-primary">
      <a
        v-if="!hideForgottenPassword"
        v-text="__('forgot.password')"
        href="/subscription/password/"
        class="fs-md d-block mt-4 mb-2">
      </a>
      <a
        v-if="!hideNewUser"
        v-text="__('subscribe.now')"
        href="/subscription/"
        class="fs-md fw-medium io-subscription-button">
      </a>
    </fieldset>
  </form>
</template>
<script>

import axios from 'axios';
import cheerio from 'cheerio';

export default {
  name: 'user-login-menu-item',
  data() {
    return {
      csrfToken: '',
      hideForgottenPassword: this.$store.state.publication.hideForgottenPassword || false,
      hideNewUser: this.$store.state.publication.hideNewUser || false
    };
  },
  computed: {
    action() {
      return `${this.getLoginEndpoint()}?target=${this.getLoginTarger()}`;
    }
  },
  asyncComputed: {
    csrfToken() {
      return this.loadCsrfToken();
    }
  },
  methods: {
    async loadCsrfToken() {
      try {
        const response = await axios.get(this.getLoginEndpoint());
        const $ = cheerio.load(response.data);
        return $('input[name=_csrf]').val();
      } catch (e) {
        console.error(e);
      }
      return '';
    },
    getLoginTarger() {
      const target = `${window.top.location.pathname}${window.top.location.search}`;
      return encodeURIComponent(decodeURIComponent(target));
    },
    getLoginEndpoint() {
      return this.$store.state.nhstAuth.loginEndpoint;
    }
  }
};

</script>
