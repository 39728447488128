<template>
  <div class="owl-carousel owl-theme">
    <slot></slot>
  </div>
</template>
<script>
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faAngleLeft } from '@fortawesome/pro-light-svg-icons';
import { owlCarouselScripts } from 'assets/js/bundled-assets';
import { AssetMixin, EmbedMixin } from 'global-mixin';

export default {
  name: 'carousel',
  mixins: [AssetMixin, EmbedMixin],
  props: {
    options: {
      type: Object,
    },
  },
  defaultOptions: {
    loop: true,
    margin: 24,
    nav: true,
    dots: false,
    rewind: true,
    navText: [
      icon(faAngleLeft).html,
      icon(faAngleRight).html,
    ],
    responsive: {
      0: {
        items: 1.5,
        nav: true,
      },
      576: {
        items: 2,
        nav: true,
        loop: false,
      },
      768: {
        items: 3,
        nav: true,
        loop: false,
      },
      1272: {
        items: 4,
        nav: true,
        loop: false,
      },
    },
  },
  async assets() {
    const [$] = await Promise.all(owlCarouselScripts());
    const mergedOptions = { ...this.$options.defaultOptions, ...this.options };
    const vm = this.$el;
    const $carousel = $(vm).owlCarousel(mergedOptions);
    $carousel.on('changed.owl.carousel', () => {
      this.$emit('change', vm, $carousel, $);
    });
  },
  embed(head) {
    head.style(this.$cdn.asset('owl.carousel.css'));
  },
};
</script>
