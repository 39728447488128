export default [
  {
    numberOfArticles: 9
  },
  {
    numberOfArticles: 4
  },
  {
    numberOfArticles: 6
  },
  {
    numberOfArticles: 6
  },
  {
    numberOfArticles: 9
  },
  {
    numberOfArticles: 9
  },
  {
    numberOfArticles: 6
  },
  {
    numberOfArticles: 6
  },
  {
    numberOfArticles: 6
  },
  {
    numberOfArticles: 6
  },
  {
    numberOfArticles: 6
  }
];
