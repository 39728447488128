import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export function createRouter(routes) {
  return new Router({
    base: __dirname,
    mode: 'history',
    routes,
    scrollBehavior(to) {
      if (to.path === '/search') {
        return { selector: '.archive-page' };
      } else if (to.path === '/prices') {
        return { selector: 'body' };
      }
    }
  });
}
