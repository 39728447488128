<template>
  <div
    :style="`top: ${top}px`"
    class="sticky position-sticky">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'sticky',
  props: {
    top: Number
  },
};
</script>
