import commonFrontpageConfig from './commonFrontpage';
import commonEditionConfig from './commonEditionpage';
const defaultConfig = {
  frontpage: commonFrontpageConfig,
  test_frontpage: commonFrontpageConfig,
  arbeidsliv: commonEditionConfig,
};

export default (edition) => {
  return {
    newsBlocks: defaultConfig[edition],
    jobsBlock: { }
  };
};
