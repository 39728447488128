import { render, staticRenderFns } from "./NativePaywall.vue?vue&type=template&id=b773a56e&scoped=true&"
import script from "./NativePaywall.vue?vue&type=script&lang=js&"
export * from "./NativePaywall.vue?vue&type=script&lang=js&"
import style0 from "./NativePaywall.vue?vue&type=style&index=0&id=b773a56e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b773a56e",
  null
  
)

export default component.exports