<template>
  <figcaption
    v-if="show"
    :class="[
      $options.defaultClass,
      $options.name,
      {[`${styleClass}`]:styleClass},
      {'inline':inline}]">
    <span
      v-html="caption"
      class="text">
    </span>
    <span
      v-html="credit"
      class="credit">
    </span>
  </figcaption>
</template>

<script>
import { stripPhotoPrefix, extractTextFromHtml } from 'global-utils';

export default {
  name: 'article-image-credit',
  defaultClass: 'figure-caption',
  props: {
    styleClass: String,
    inline: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const imageCredit = this.$store.state.article.leadAsset == null
      ? null
      : (this.$store.state.article.leadAsset.imageCredit || null);
    if (imageCredit) {
      return {
        caption: extractTextFromHtml(imageCredit.caption) || '',
        credit: stripPhotoPrefix(imageCredit.credit, this.__),
        show: true
      };
    }
    return {
      show: false
    };
  }
};
</script>
