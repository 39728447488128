<template>
  <div
    v-if="tags.length"
    class="d-flex flex-row flex-wrap">
    <div
      v-for="tag in displayableTagList.slice(0, limit)"
      v-if="tag.url"
      :key="tag.index"
      class="ff-flama fs-sm fw-bold mr-3 mb-2">
      <a
        :href="tag.url"
        class="d-inline-block align-middle"
        style="line-height: 2.8rem">
        {{ tag.label }}
      </a>
    </div>
    <slot/>
  </div>
</template>
<script>
export default {
  name: 'article-tags',
  props: {
    styleClass: String,
    tags: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    limit: {
      type: Number,
      required: true
    },
  },
  computed: {
    displayableTagList() {
      return this.tags
        .filter(tag => tag.label !== '')
        .filter(tag => !(tag.type && this.$pubCtx.excludeTags.includes(tag.type.toLowerCase())));
    }
  }
};
</script>
