export const page = (name, subdir = '') => resolve => {
  return import(`pages/${subdir}${name}Page`).then(resolve);
};

export const staticPage = (route, pubName) => {
  return page(route.staticPage, route.meta.pageContext.pathId ? '' : `static/${pubName}/`);
};

export const standalonePage = (route) => {
  return page(route.standalonePage, route.meta.pageContext.pathId ? '' : `standalone/`);
};

export function buildRoutes(publication, routesByName) {
  return Object.entries(routesByName).map(([name, route]) => {
    route.name = name;
    route.component = route.staticPage ? staticPage(route, publication) : (route.standalonePage ? standalonePage(route) : page(route.page));
    return route;
  });
}
