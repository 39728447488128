export const getMailChimpHubSpotPopupScript = (publicationCode) => {
  const scriptTag = '<script id="mcjs">!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/';

  if (publicationCode === 'KYSTEN') {
    const mailChimpScript = appEnv === 'test'
      ? `<!-- Begin MailChimp Tag -->\n${scriptTag}b94d91ad023dbbe1a862d6a63/5a9a00fdfcda59168b282f98d.js");</` + `script>\n<!-- End MailChimp Tag -->`
      : `<!-- Begin MailChimp Tag -->\n${scriptTag}b94d91ad023dbbe1a862d6a63/3a6b3f96177c0502d94c0692d.js");</` + `script>\n<!-- End MailChimp Tag -->`;
    return mailChimpScript;
  }
};
