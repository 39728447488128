import { on } from 'global-hackable';
import html2Text from 'html-to-text';

export default () => {
  on('created:EditionPage', (vm) => {
    if (!vm.$ssrContext) return;
    const adobeDataLayer = vm.$ssrContext.adobeDataLayer = vm.$ssrContext.adobeDataLayer || {};
    adobeDataLayer.pageType = 'edition';
  });

  on('created:ArticlePage', (vm) => {
    if (!vm.$ssrContext) return;
    const adobeDataLayer = vm.$ssrContext.adobeDataLayer = vm.$ssrContext.adobeDataLayer || {};
    adobeDataLayer.pageType = 'article';
    const { id, title, body, mainCategory, topic, tags, publishedAt, authors, paid } =
      vm.$store.state.article;
    adobeDataLayer.article = adobeDataLayer.article || {};
    adobeDataLayer.article = {
      ...adobeDataLayer.article,
      id,
      title,
      category: mainCategory,
      topic,
      tags: tags.map(tag => tag.label),
      freeOrPaid: (paid && !vm.$pubCtx.isFreePublication) ? 'paid' : 'free',
      availability: 'open', // in ArticlePaywallPage.vue it will be `close`
      authors: authors.map(author => author.name),
      publishedAt,
      wordCount: html2Text.fromString(body).split(' ').length
    };
  });

  on('created:ArticlePaywallPage', vm => {
    if (!vm.$ssrContext) return;
    const adobeDataLayer = vm.$ssrContext.adobeDataLayer = vm.$ssrContext.adobeDataLayer || {};
    adobeDataLayer.article = adobeDataLayer.article || {};
    adobeDataLayer.article.availability = 'close';
  });

  on('beforeRenderTemplate', async (ssrContext) => {
    ssrContext.headEmbedBuilder.add(
      `<script type="text/javascript">
        function getQueryParam(name) {
          name = name.replace(/[\\[]/, '\\[').replace(/[\\]]/, '\\]');
          var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
          var results = regex.exec(location.search);
          return results === null ? '' : decodeURIComponent(results[1].replace(/\\+/g, ' '));
        };
        function getCookieValue(cname) {
          var name = cname + "=";
          var ca = document.cookie.split(';');
          for(var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
            }
          }
          return "";
        }
        function getOneIdInfo(oneIdInfoCookie) {
          const splits = oneIdInfoCookie.split('|');
          const oneIdInfo = {};
          oneIdInfo.userId = splits[4];
          oneIdInfo.products = splits[7] === '' ? [] : splits[7].split(',');
          return oneIdInfo;
        }
        function getSubscriptionId(oneIdInfo) {
          return oneIdInfo.userId;
        }
        function getSubscriptionType(oneIdInfo) {
          return oneIdInfo.products && oneIdInfo.products.length > 0 ? 'Subscriber' : 'Free';
        }
        // adobeDataLayer for adobe analytics
        function finalizeAdobeDataLayer() {
          var adobeDataLayer = ${JSON.stringify(ssrContext.adobeDataLayer)} || {};
          adobeDataLayer.adobe_mc = getQueryParam('adobe_mc');
          var oneIdInfoCookie = getCookieValue('oneid_info');
          if (oneIdInfoCookie && oneIdInfoCookie.length > 0) {
            var oneIdInfo = getOneIdInfo(atob(oneIdInfoCookie));
            adobeDataLayer.subscription = {
              userId: getSubscriptionId(oneIdInfo),
              type: getSubscriptionType(oneIdInfo)
            }
          } else {
            adobeDataLayer.subscription = {
              userId: '',
              type: 'Guest'
            }
          }
          return adobeDataLayer;
        }
        window.globalDataLayer = finalizeAdobeDataLayer();
        </script>`
    );
    ssrContext.headEmbedBuilder.script(dconf.AdobeTagManger.jsSrc);
    ssrContext.bodyEmbedBuilder.add(
      '<script type="text/javascript">_satellite && _satellite.pageBottom();</script>'
    );
  });
};
