import Vue from 'vue';
import VueCookies from 'vue-cookies';
import { createClientStore } from 'global-store';
import { createRouter } from 'router';
import { sync } from 'vuex-router-sync';
import DatePicker from 'vue2-datepicker';
import VueSelect from 'vue-multiselect';
import { CDNPlugin, i18nPlugin, PubCtxPlugin } from 'global-plugins';
import { createI18n } from 'global-i18n';
import { useAdobeDataLayer } from 'global-analytics';
import AsyncComputed from 'vue-async-computed';
import App from './App.vue';

import 'pkg/bootstrap/dist/js/bootstrap.js';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue2-datepicker/index.css';
import * as globalElements from 'global-elements';
import { buildRoutes } from 'router/routesBuilder';

// eslint-disable-next-line
__webpack_public_path__ = window.publicPath;

useAdobeDataLayer();

Vue.use(PubCtxPlugin);
Vue.use(CDNPlugin, {
  CDN: dconf.CDN,
  assetsManifest: global.assetsManifest,
});
Vue.use(i18nPlugin);
Vue.use(VueCookies);
Vue.use(AsyncComputed);

Vue.component('date-picker', DatePicker);
Vue.component('VSelect', VueSelect);

/* Loading common elements globally */
Object.keys(globalElements).forEach(name => {
  Vue.component(name, globalElements[name]);
});

Vue.config.errorHandler = (err, vm, info) => {
  console.log(vm);
  console.log(err);
  console.log(info);
};
Vue.config.warnHandler = (err, vm, info) => {
  console.log(vm);
  console.log(err);
  console.log(info);
};

export function createApp() {
  const store = createClientStore();
  if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__);
  }
  const routes = buildRoutes(store.state.publication.name, store.state.routes);
  const router = createRouter(routes);
  sync(store, router);
  const app = new Vue({
    i18n: createI18n(store.state.i18nMessages),
    router,
    store,
    pubCtx: store.state.publication,
    render: h => h(App)
  });
  return { app, router, store };
}
