<template>
  <column md="11">
    <div
      v-for="(chunk, index) in suggestions"
      :key="index"
      class="ff-flama d-inline-block mb-3 mt-2 mr-2">
      <template v-for="(topic, tind) in chunk">
        <div
          :key="tind"
          @click="subscribe(topic)"
          :data-name="topic.name"
          :data-type="topic.type"
          :data-label="topic.label"
          class="alert-topic btn-secondary no-border align-middle fs-sm fw-bold pr-2 pl-2 pt-2 pb-2">
          {{ topic.label }}
          <FontAwesomeIcon
            :icon="icons.faPlus"
            class="ml-1"/>
        </div>
      </template>
    </div>
    <AlertModal
      :show="showSubscribeModal"
      @close="showSubscribeModal = false">
      <AlertOptionChooser
        :items="subscribeItem"
        :from-page="true"
        :sub-type="subType"
        @confirm="confirmSubscription"
        @confirmError="handleConfirmError"/>
    </AlertModal>
    <AlertConfirmModal
      :show-confirm="showConfirmModal"
      :show-success="confirmSuccess"
      :from-page="true"
      @close="() => { showConfirmModal = false; onConfirmModalclose(); }">
    </AlertConfirmModal>
  </column>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import AlertModal from './AlertModal';
import AlertOptionChooser from './AlertOptionChooser';
import AlertConfirmModal from './AlertConfirmModal';
import { EventBus, alertLocalStorageUtil, isEmpty, chunk } from 'global-utils';

export default {
  name: 'alert-promo-widget',
  components: {
    AlertModal,
    AlertOptionChooser,
    AlertConfirmModal,
    FontAwesomeIcon,
  },
  data() {
    return {
      subscribeItem: [],
      subType: '',
      showSubscribeModal: false,
      showConfirmModal: false,
      confirmSuccess: false,
      icons: {
        faPlus
      },
    };
  },
  computed: {
    suggestions() {
      return chunk(this.$store.state.widgetTopics.slice(0, 20), 1);
    }
  },
  methods: {
    async subscribe({ name, type, label }) {
      const subscriptionData = await alertLocalStorageUtil.getItem();
      const alreadySubscribedTopic = subscriptionData &&
        subscriptionData.topics &&
        subscriptionData.topics.find(topic => topic.term === name);

      if (alreadySubscribedTopic) {
        this.alreadySubscribedTopicSubType = alreadySubscribedTopic.subType;
        this.confirmSuccess = false;
        this.showConfirmModal = true;
        return;
      }
      this.subscribeItem = [{ name, label, type }];
      this.subType = type;
      this.showSubscribeModal = true;
    },
    confirmSubscription(confirmData) {
      this.showSubscribeModal = false;
      this.showConfirmModal = true;
      this.confirmSuccess = true;
    },
    handleConfirmError(err) {
      console.error('something went wrong saving subscription: ', err);

      this.showSubscribeModal = false;
      this.showConfirmModal = true;
      this.confirmSuccess = false;
    },
    onConfirmModalclose() {
      if (!isEmpty(this.subscribeItem)) {
        EventBus.$emit('newsPanel:refresh');
      }
    }
  }
};
</script>
