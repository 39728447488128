<template>
  <div class="col-12">
    <iframe
      :src="info.link"
      :height="info.height"
      width="100%"
      allowfullscreen>
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'custom-ad-placeholder',
  props: {
    info: Object
  },
};
</script>
