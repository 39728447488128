<template>
  <ul
    v-if="mostReadArticles && mostReadArticles.length > 0"
    class="unstyled-list">
    <li
      v-for="(articleData) in mostReadArticles"
      :key="articleData.id"
      class="p-2">
      <a
        :href="articleData.canonicalUrl"
        v-html="articleData.title"
        class="text-reset">
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'most-read-list',
  dataResolver: 'mostReadArticles',
  data() {
    return {
      mostReadArticles: this.$store.state.mostReadArticles.slice(0, 15)
    };
  }
};
</script>
