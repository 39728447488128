<template>
  <div
    v-if="$pubCtx.responsibleEditor"
    class="editorial-responsibility d-flex align-content-stretch justify-content-between flex-wrap pb-1 mb-3">
    <div class="order-sm-0 my-3 align-self-center">
      <div class="fw-bold">{{ $pubCtx.responsibleEditor.editorInChiefLabel }}</div>
      <div>{{ $pubCtx.responsibleEditor.chiefEditor }}</div>
    </div>
    <div class="order-sm-2 my-3 align-self-center">
      <div>
        <div
          v-for="info in $pubCtx.responsibleEditor.pubInfo"
          :key="info"
          v-html="info"/>
      </div>
    </div>
    <div class="order-sm-1 mb-1 my-sm-3 logo-msg d-flex justify-content-center">
      <RedaktoransvarIcon width="72"/>
      <div
        v-html="__('editorial.responsibility.message', $pubCtx.responsibleEditor.pubName || $pubCtx.label)"
        class="editorial-msg align-self-center pl-2"/>
    </div>
  </div>
</template>
<script>
import RedaktoransvarIcon from './RedaktoransvarIcon';

export default {
  name: 'editorial-responsibility',
  components: {
    RedaktoransvarIcon,
  },
};
</script>
<style lang="scss">
.editorial-responsibility {
  font-size: 1.4rem;
  border-bottom: 1px solid;

  .editorial-msg {
    width: 75%;
  }
}
</style>
