(function (global) {
  global.__hooks__ = global.__hooks__ || {
    events: {},
    filters: {},
  };
})(typeof window === 'object' ? window : global);

class Hook {
  constructor(name) {
    this.name = name;
    this.handlers = [];
  }

  addHandler(handler) {
    if (typeof handler !== 'function') {
      throw new Error(`Handler of hook '${this.name}' must be a function.
       Found ${typeof handler}: ${handler}`);
    }
    this.handlers.push(handler);
  }
}

class Event extends Hook {
  async invoke(data) {
    return Promise.all(this.handlers.map((handler) => handler(data)));
  }
}

class Filter extends Hook {
  constructor(name) {
    super(name);
    this.cursorIdx = 0;
  }

  async invoke(data, context) {
    while (this.cursorIdx < this.handlers.length) {
      data = await this.next(data, context);
    }
    this.cursorIdx = 0;
    return data;
  }

  async next(data = {}, context) {
    const nData = await this.handlers[this.cursorIdx].call(this, data, context, this.next);
    this.cursorIdx++;
    return typeof nData === 'object' ? nData : data;
  }

  invokeSync(data, context) {
    while (this.cursorIdx < this.handlers.length) {
      data = this.nextSync(data, context);
    }
    this.cursorIdx = 0;
    return data;
  }

  nextSync(data = {}, context) {
    const nData = this.handlers[this.cursorIdx].call(this, data, context, this.next);
    this.cursorIdx++;
    return typeof nData === 'object' ? nData : data;
  }
}

function bindEvent(name, listener, eventsMap) {
  const event = eventsMap[name] || new Event(name);
  event.addHandler(listener);
  eventsMap[name] = event;
}

async function emitEvent(name, data, eventsMap) {
  if (!eventsMap[name]) return;
  return eventsMap[name].invoke(data);
}

function addFilter(name, flushFn, filtersMap) {
  const filter = filtersMap[name] || new Filter(name);
  filter.addHandler(flushFn);
  filtersMap[name] = filter;
}

async function applyFilter(name, data, flushCtx, filtersMap) {
  if (!filtersMap[name]) return data;
  return filtersMap[name].invoke(data, flushCtx);
}

function applyFilterSync(name, data, flushCtx, filtersMap) {
  if (!filtersMap[name]) return data;
  return filtersMap[name].invokeSync(data, flushCtx);
}

export function on(name, listener) {
  bindEvent(name, listener, global.__hooks__.events);
}

export function emit(name, data) {
  return emitEvent(name, data, global.__hooks__.events);
}

export function pipe(name, flushFn) {
  addFilter(name, flushFn, global.__hooks__.filters);
}

export async function flush(name, data, flushCtx) {
  return applyFilter(name, data, flushCtx, global.__hooks__.filters);
}

export function flushSync(name, data, flushCtx) {
  return applyFilterSync(name, data, flushCtx, global.__hooks__.filters);
}

export class Hackable {
  constructor() {
    this.events = {};
    this.filters = {};
  }

  on(name, listener) {
    this.bind(name, listener);
  }

  bind(name, listener) {
    bindEvent(name, listener, this.events);
  }

  emit(name, data) {
    return emitEvent(name, data, this.events);
  }

  pipe(name, flushFn) {
    addFilter(name, flushFn, this.filters);
  }

  async flush(name, data) {
    return applyFilter(name, data, this.filters);
  }

  flushSync(name, data) {
    return applyFilterSync(name, data, this.filters);
  }
}
