<template>
  <div class="jobs-ticker border-top border-bottom py-3 mb-3 ff-flama fs-md d-flex">
    <div
      v-text="__('latest.jobs')"
      class="jobs-ticker-header tt-uppercase pr-3"></div>
    <Carousel
      :options="$options.defaultOptions"
      class="jobs-ticker-container teaser-carousel">
      <a
        v-for="({ title, detailsUrl }, idx) in jobs"
        :key="idx"
        :href="detailsUrl"
        target="_blank"
        class="text-reset fw-medium jobs-ticker-item d-inline-block">
        {{ title }}
      </a>
    </Carousel>
  </div>
</template>

<script>
export default {
  name: 'job-ticker',
  dataResolver: 'jobsTicker',
  defaultOptions: {
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    loop: true,
    items: 1,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    autoplaySpeed: 750,
    navText: [
      '',
      ''
    ],
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      576: {
        items: 1,
        nav: false,
      },
      768: {
        items: 1,
        nav: false,
      },
      1272: {
        items: 1,
        nav: false,
      }
    }
  },
  data() {
    return {
      jobs: this.$store.state.tickerJobs
    };
  }
};
</script>
