import commonFrontpageConfig from './commonFrontpage';
const defaultConfig = {
  frontpage: commonFrontpageConfig,
  test_frontpage: commonFrontpageConfig
};

export default (edition) => {
  return {
    newsBlocks: defaultConfig[edition],
    jobsBlock: { },
  };
};
