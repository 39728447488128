import { isPreview } from 'global-utils';

export const getOneTrustTrackingScript = vm => {
  const oneTrustConfigs = dconf.get('OneTrust');

  if (!vm.$pubCtx.enableOneTrust ||
    !oneTrustConfigs.src ||
    !vm.$store.state.oneTrustDomainScript ||
    isPreview(vm.$route.meta.pageContext.type, vm.$route.query) ||
    vm.$route.path.match('/standalone/(.+)') ||
    vm.$route.query.skipModules === 'onetrust') {
    return ``;
  }

  vm.$ssrContext && vm.$ssrContext.bodyEmbedBuilder.add(
    '<button id="ot-sdk-btn" class="ot-sdk-show-settings ot-sdk-custom-button"></button>'
  );

  return `<!-- Begin OneTrust Tag -->
    <style>
      .ot-floating-button, .ot-sdk-custom-button {
          display: none !important;
      }
    </style>
    <script src="${oneTrustConfigs.src}" type="text/javascript" charset="UTF-8" data-domain-script="${vm.$store.state.oneTrustDomainScript}" ></script>
    <script type="text/javascript">
      let isOneTrustUIEventsSet = false;
      
      window.addEventListener('load', function () {
          let cookieSettingBtn = document.querySelector('.cookies-popup-trigger a');
          if(cookieSettingBtn) {
            cookieSettingBtn.addEventListener('click', function(e) {
              e.preventDefault();
              let element = document.querySelector(".ot-sdk-custom-button");
              if (element) {
                element.click();
              }
              oneTrust.setOneTrustSettingsUIEvents();
              return false;
            });
            
          if (location.hash === '#cookie-setting') {
            cookieSettingBtn.click();
          }
        }
      });
      
      function OptanonWrapper() {
        if (isOneTrustUIEventsSet) {
          return;
        }
        isOneTrustUIEventsSet = true;
        oneTrust.captureOneTrustUIEvents();
      }
    </script>
    <!-- End OneTrust Tag -->`;
};
