<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header d-flex justify-content-between mb-3">
              <slot name="header"/>
              <div
                v-if="!noCloseButton"
                @click="$emit('close')"
                class="close-icon px-3 py-1">
                <FontAwesomeIcon :icon="icons.faTimes"/>
              </div>
            </div>
            <div class="modal-body">
              <slot name="body"/>
            </div>
            <div class="modal-footer">
              <slot name="footer"/>
            </div>
          </div>
        </div>
      </div>
    </div></transition>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

export default {
  name: 'modal',
  components: { FontAwesomeIcon },
  props: {
    width: {
      type: String,
      default: '35%',
    },
    noCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        faTimes,
      },
    };
  }
};
</script>
<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 520px;
  min-width: 360px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
  padding: 0;
}

.modal-body {
  padding: 0;
}

.modal-header h3 {
  margin-top: 0;
}

.modal-footer {
  padding: 0;
}

.close-icon {
  cursor: pointer;
  font-size: 2rem;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid lightgrey;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter,
.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
