<template>
  <card
    v-if="embedContent || hubspotContent"
    :class="['inline embed', embedType, floatValue ? `float-${floatValue}` : '']"
    :style="cardStyle">
    <card-body v-if="embedContent">
      <span v-html="embedContent"></span>
    </card-body>
    <component
      :is="'script'"
      v-html="formScript"/>
  </card>
</template>

<script>
import { AssetMixin, EmbedMixin } from 'global-mixin';
import { jQuery } from 'assets/js/bundled-assets';

export default {
  name: 'inline-embed',
  mixins: [AssetMixin, EmbedMixin],
  props: {
    refId: String
  },
  data() {
    let { embedContent, float, width, height } = this.$store.state.article.inlineElements[this.refId];

    const deferScripts = [];
    const surveyMonkeyWidgetUrl = embedContent.match(/widget.surveymonkey.com\/collect\/website\/js\/(.*)js/g);
    const hubspotFormEmbed = embedContent.match(/hbspt.forms.create/g);
    let hubspotContent = '';
    const liveCenterEmbed = embedContent.match(/live-center-embed/g);

    if (surveyMonkeyWidgetUrl) {
      /*
      * This is a hack for how SurveyMonkey widget rendering has been implemented
      * The widget initialization script create a '<script id="smcx-sdk" src="..."></ script>
      * on runtime if it is not present as sibling of the initialization script in the DOM tree.
      * But Vue discard all external changes(DOM) when hydrating itself at the very beginning of
      *  client initialization.
      * */
      embedContent =
        `<script id="smcx-sdk" src="https://${surveyMonkeyWidgetUrl[0]}"></` + 'script>';
    } else if (this.$pubCtx.name === 'tradewinds') {
      const [script, content] = parseTradewindsMarketWidget(embedContent);
      if (content) {
        embedContent = content;
        deferScripts.push(script);
      }
    }

    if (hubspotFormEmbed) {
      hubspotContent = embedContent.replace(/(<([^>]+)>)/gi, '');
      embedContent = '';
    }
    const widthValue =
      (float && (float !== 'none'))
        ? ((width && width !== '100%')
          ? ('max-width: ' + width + '; overflow: auto;')
          : ('max-width: max-content;'))
        : ('max-width: 100%;');

    const heightValue =
      (float && (float !== 'none'))
        ? ((height && height !== '100%')
          ? ('max-height: ' + height + '; overflow: auto;')
          : ('max-height: max-content;'))
        : ('max-height: 100%;');

    embedContent = parseSmartEmbeds(embedContent);

    return {
      embedContent,
      floatValue: float
        ? ((float !== 'none') ? float : (hubspotContent !== '' ? '' : 'none'))
        : 'none',
      deferScripts,
      hubspotFormEmbed,
      hubspotContent,
      formScript: '',
      embedType: getEmbedType(embedContent),
      cardStyle: widthValue + heightValue + (hubspotContent !== '' ? ` padding: 2rem;` : ''),
      liveCenterEmbed
    };
  },
  embed(head, body) {
    if (this.hubspotFormEmbed) {
      head.add(`<!--[if lte IE 8]> <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></` +
        `script> <![endif]--> <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></` + `script>`);
    }

    if (this.liveCenterEmbed) {
      body.add(`<script>function livecenterembed(e){function t(t,r){e[s](o,function(e){var n,i;if(e&&((n=e[e.message?"message":"data"]+"")&&n.substr&&"nc:"===n.substr(0,3))&&(i=n.split(":"))[1]===r)switch(i[2]){case"h":return void(t.style.height=i[3]+"px");case"scrolltotop":return void t.scrollIntoView()}},!1)}for(var r,n,i,l,a,s=e.addEventListener?"addEventListener":"attachEvent",o="attachEvent"===s?"onmessage":"message",c=e.document.querySelectorAll(".live-center-embed"),u=0;u<c.length;u++)(r=c[u]).getAttribute("data-rendered")||(n=r.getAttribute("data-src"))&&(r.setAttribute("data-rendered","true"),i=e.ncVizCounter||1e3,e.ncVizCounter=i+1,a="nc-frame-c-"+(l=i+""),r.innerHTML='<div style="position: relative; height: 300px; border: none;" id="'+a+'"><iframe src="'+n+"?"+l+'" style="position: absolute; left: 0; top: 0; width: 100%; height: 100%;" frameborder="0" allowtransparency="true" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" allow="autoplay; fullscreen"></iframe></div>',t(r.firstChild,l))}setTimeout(function(){livecenterembed(window)},4e3);</` + `script>`);
    }
  },
  async assets() {
    const $ = await jQuery();
    this.deferScripts.forEach(script => {
      $('head').prepend(script);
    });

    this.formScript = this.hubspotContent;
  },
};

function getEmbedType(embedContent) {
  if (typeof embedContent === 'undefined') {
    return '';
  }
  const embedTypes = [
    { keys: 'soundcloud', type: 'soundcloud embed-responsive embed-responsive-soundcloud' },
    { keys: '/maps', type: 'embed-responsive embed-responsive-1by1' },
    { keys: 'pubchart', type: 'chart embed-responsive embed-responsive-chart' },
    { keys: 'youtube.com,vimeo.com', type: 'video embed-responsive embed-responsive-video embed-responsive-16by9' },
    { keys: 'twitter', type: 'twitter' },
    { keys: 'facebook', type: 'facebook' }
  ];

  const matchedEmbed = embedTypes.find(embed => {
    return embed.keys.split(',').some(key => embedContent.includes(key));
  });

  return matchedEmbed ? matchedEmbed.type : '';
}

function parseSmartEmbeds(embedContent) {
  // replace wrapper for smart embedded youtube iframe
  embedContent = embedContent
    .replace(
      new RegExp('<div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: ([+-]?([0-9]*[.])?[0-9]+)%;"><iframe', 'g'),
      `<div class="iframely-embed embed-responsive-smart"><iframe`
    );

  return embedContent;
}

function parseTradewindsMarketWidget(embedContent) {
  const isNorkonMarketPlaceWidget =
    (new RegExp(/twagg.azurewebsites.net\/widgets\/widgets\/(.*)\/widget.js/g))
      .test(embedContent);
  if (isNorkonMarketPlaceWidget) {
    embedContent = embedContent.replace(/'/g, '"');
    const embededJsSrc = embedContent
      .match(/<script\b[^>]*>(.*?)<\/script>/gm);

    return [embededJsSrc[0], embedContent.replace(embededJsSrc[0], '')];
  }
  return [];
}
</script>
