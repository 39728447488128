<template>
  <div>
    <card-link
      v-if="showMainCategory && showLinkedCategory"
      :style-class="classesForMainCategory"
      :text="mainCategory.label"
      :url="crossPublicationCategoryLink()"/>
    <card-text
      v-if="showMainCategory && !showLinkedCategory"
      :class="classesForMainCategory"
      v-text="mainCategory.label"/>
  </div>
</template>

<script>
import { crossPublicationHighlightClass } from './teaserUtils';

export default {
  name: 'teaser-category',
  props: {
    isCrossPublication: {
      type: Boolean,
      default: false
    },
    showMainCategory: {
      type: Boolean,
      default: true
    },
    mainCategory: {
      type: [Object, String],
      required: true
    },
    mainCategoryClasses: {
      type: String,
      default: ''
    },
    teaserElementHighlight: {
      type: String,
      default: ''
    },
    articlePublication: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      classesForMainCategory: `${this.mainCategoryClasses} ${this.crossPublicationCategoryClass()}`,
      showLinkedCategory: (this.mainCategory && this.mainCategory.url)
    };
  },
  methods: {
    crossPublicationCategoryClass() {
      if (this.isCrossPublication) {
        return crossPublicationHighlightClass(this.$store.state.publication, this.teaserElementHighlight, this.articlePublication);
      }
      return '';
    },
    crossPublicationCategoryLink() {
      let categoryLink = this.mainCategory.url;
      if (this.isCrossPublication) {
        categoryLink = `${this.$store.state.baseUrl.replace(this.$store.state.publication.domain, this.articlePublication)}${categoryLink}`;
      }
      return categoryLink;
    }
  }
};
</script>
