<template>
  <h1
    :class="[
      $options.name,
      'ff-sueca-bold',
      {[`${styleClass}`]:styleClass}]">
    {{ title }}
  </h1>
</template>
<script>
export default {
  name: 'article-title',
  props: {
    styleClass: String
  },
  data() {
    return {
      title: this.$store.state.article.title
    };
  }
};
</script>
