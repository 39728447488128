import { adScripts } from 'assets/js/bundled-assets';
import { AssetMixin } from 'global-mixin';

export default {
  name: 'ad',
  mixins: [AssetMixin],
  props: {
    slotId: {
      type: String,
      required: true
    },
    outOfPage: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      required: false,
      default: ''
    },
    kind: {
      type: String,
      default: 'default'
    },
    adLabel: {
      type: String,
      default: null
    },
    adLabelLink: {
      type: String,
      default: null
    },
    adLabelClassNames: {
      type: String,
      default: 'ad-label'
    },
    parentDivClassNames: {
      type: String,
      default: null
    }
  },
  computed: {
    ad() {
      return this.$store.state.adConfig[this.kind][this.slotId];
    },
    onScrollRefresher() {
      return () => {
        this.refresh();
      };
    }
  },
  methods: {
    createSlot() {
      if (typeof this.ad !== 'undefined') {
        return this.outOfPage
          ? googletag.defineOutOfPageSlot(this.ad.unitPath, this.slotId)
          : googletag.defineSlot(this.ad.unitPath, this.ad.sizes, this.slotId);
      }

      return googletag.defineSlot();
    },
    refresh() {
      if (!this.slot || (this.$store.state.error && this.slot === 'leaderboard')) {
        return;
      }
      const lazyThreshold = this.ad.lazyThreshold || 0;
      if (this.refreshDone || (lazyThreshold > 0 && !$(`#${this.slotId}`).inViewport(lazyThreshold))) {
        return;
      }

      if ($(`#${this.slotId}`).inViewport(lazyThreshold)) {
        googletag.pubads().refresh([this.slot]);
        this.refreshDone = true;
        $(window).off('scroll', this.onScrollRefresher);
      }
    },
  },
  async assets() {
    const [$,, { AdPublisher }] =
      await Promise.all(adScripts(this.$store.state.publication));

    // todo: replace jquery based selector query with vue based selector
    $.fn.inViewport = function (lazyThreshold = 0) {
      const elementTop = $(this).offset().top - lazyThreshold;
      const elementBottom = elementTop + $(this).outerHeight();
      const viewportTop = $(window).scrollTop();
      const viewportBottom = viewportTop + $(window).height();
      return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    new AdPublisher(this.$store.state, this.$route.meta.pageContext).publish();
    googletag.cmd.push(() => {
      this.slot = this.createSlot();
      if (!this.slot) {
        return;
      }
      if (this.ad.targets) {
        Object.entries(this.ad.targets).forEach(([key, val]) => {
          this.slot.setTargeting(key, val);
        });
      }

      googletag.pubads().setTargeting('cpp', 'false');
      googletag.pubads().setTargeting('screen.width', `${screen.width}`);

      const GptAamSegments = 'gpt_aamsegments';

      if (typeof AamGpt !== 'undefined') {
        if (typeof AamGpt.getCookie(GptAamSegments) !== 'undefined') {
          googletag.pubads().setTargeting(
            AamGpt.getKey(GptAamSegments),
            AamGpt.getValues(GptAamSegments)
          );
        }
      }
      this.slot.addService(googletag.pubads());
      googletag.display(this.slotId);

      this.refresh();
      const $window = $(window);
      $window.on('scroll', this.onScrollRefresher);
      const tempAdLabel = this.adLabel;
      const tempSlotId = this.slotId;
      googletag.pubads().addEventListener('slotRenderEnded', function (event) {
        if (!event.isEmpty && tempSlotId === event.slot.getSlotElementId() && tempAdLabel) {
          $(`#${tempSlotId}Label`).removeClass('is-hidden');
        }
      });
    });
  },
  render(h) {
    return h('div', {
      class: this.parentDivClassNames
    },
    [
      this.adLabel && h('div', {
        attrs: {
          id: `${this.slotId}Label`,
        },
        class: ['is-hidden', this.adLabelClassNames]
      },
      [
        this.adLabelLink ? h('a', {
          attrs: {
            href: `${this.adLabelLink}`,
            target: '_blank'
          },
        },
        [
          this.adLabel
        ]

        ) : this.adLabel,
      ]

      ),
      h('div', {
        attrs: {
          id: this.slotId,
          class: this.className
        }
      }
      )]
    );
  }
};
