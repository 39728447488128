import { isArticlePage, _isNil } from 'global-utils';
import { replaceTrailingPreviewElement } from './canonicalUrlUtils';
export const getCanonicalUrl = ({ $store: { state }, $route: { meta } }) => {
  return (
    isArticlePage(meta.pageContext) &&
    !_isNil(state.article) &&
    !(meta.pageContext.type === 'infoArticle')
  )
    ? ((state.article.sourceArticleUrl && `<link rel="canonical" href="${state.article.sourceArticleUrl}">`) ||
    `<link rel="canonical" href="${state.article.canonicalUrl}">`)
    : `<link rel="canonical" href="${replaceTrailingPreviewElement(state.requestBase)}">`;
};
