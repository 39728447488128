import commonEditionConfig from './commonEditionpage';
import commonFrontpageConfig from './commonFrontpage';
const defaultConfig = {
  frontpage: commonFrontpageConfig,
  test_frontpage: commonFrontpageConfig,
  twplus: commonEditionConfig,
  interviews: commonEditionConfig,
  esg: commonEditionConfig
};

export default (edition) => {
  return {
    newsBlocks: defaultConfig[edition],
    jobsBlock: { },
  };
};
