<template>
  <div>
    <AlertHomePagePromo v-if="showAlertPromoWidget"/>
    <component
      v-if="placeholderComponent"
      :is="placeholderComponent"
      :info="currentPlaceholder"/>
  </div>
</template>
<script>
import {
  JobCarouselPlaceholder,
  CarouselPlaceholder,
  FiskekrokenCarouselPlaceholder,
  CustomAdPlaceholder
} from 'global-components';
import { AlertHomePagePromo } from './Alert';

const placeholderByType = {
  carousel: CarouselPlaceholder,
  job_carousel: JobCarouselPlaceholder,
  fiskekroken_carousel: FiskekrokenCarouselPlaceholder,
  custom_ad: CustomAdPlaceholder
};

function resolvePlaceholderComponent(currentPlaceholder) {
  /**
   * Placeholder placement is dynamic.
   * This won't render fixed placeholder
   * */
  return currentPlaceholder && !currentPlaceholder.isPlacementFixed
    ? placeholderByType[currentPlaceholder.type] : undefined;
}

const isAlertPromoPlaceholder = (placeholder) => placeholder.type === 'alert_promo_widget';

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    JobCarouselPlaceholder,
    CarouselPlaceholder,
    FiskekrokenCarouselPlaceholder,
    CustomAdPlaceholder,
    /* eslint-enable vue/no-unused-components */
    AlertHomePagePromo
  },
  props: {
    idx: Number,
    reset: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    let showAlertPromoWidget = false;
    const idx = this.idx || this.nextIdx();
    let currentPlaceholder = this.getPlaceholder(idx);
    if (currentPlaceholder && isAlertPromoPlaceholder(currentPlaceholder)) {
      showAlertPromoWidget = true;
      currentPlaceholder = this.getPlaceholder(this.nextIdx());
    } else if (idx === 1 && !this.hasAlertPromoWidgetPlaceholder()) {
      showAlertPromoWidget = true;
    }
    return {
      showAlertPromoWidget,
      currentPlaceholder,
      placeholderComponent: resolvePlaceholderComponent(currentPlaceholder),
    };
  },
  methods: {
    getPlaceholder(idx) {
      return this.$store.state.edition.placeholders[idx];
    },
    nextIdx() {
      const idx = this.$store.state.curPlaceholderIdx;
      this.$store.commit(this.reset ? 'resetPlaceholderIdx' : 'nextPlaceholderIdx');
      return idx;
    },
    hasAlertPromoWidgetPlaceholder() {
      return !!this.$store.state.edition.placeholders.filter(isAlertPromoPlaceholder).length;
    },
  }
};
</script>
