<template>
  <div class="alert-info-banner wrapper pt-2 pb-3 mb-4">
    <row>
      <column
        md="1"
        class="pb-3 pt-3">
        <div class="alert-oval">
          <div class="fs-xxl">
            <FontAwesomeIcon
              :icon="alertIconSolid"
              class="icon-alert-solid"
              width="36"/>
          </div>
        </div>
      </column>
      <column
        class="ff-flama fs-lg fw-light pb-3 pt-3"
        md="11">
        <p>{{ __('my.alerts.welcome') }}</p>
        <p class="mb-1">{{ __('alert.keep.up.with.articles') }}</p>
        <p>{{ __('alert.click') }}
          <FontAwesomeIcon
            :icon="alertIcon"
            class="icon-alert"
            width="10"/>
          {{ __('alert.click.on.topics') }}</p>
      </column>
    </row>
    <div
      @click="closeAlertInfoBanner"
      class="alert-close float-right fs-xl py-2">
      <FontAwesomeIcon
        :icon="closeIcon"
        width="30"/>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTimes, faBell } from '@fortawesome/pro-light-svg-icons';
import { faBell as faBellSolid } from '@fortawesome/pro-solid-svg-icons';

export default {
  name: 'alert-info-banner',
  components: {
    FontAwesomeIcon
  },
  props: {
    alertInfoBannerClosedCookie: {
      type: String,
      default: ''
    },
    alertInfoBannerClosedTimeCookie: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      closeIcon: faTimes,
      alertIconSolid: faBellSolid,
      alertIcon: faBell,
    };
  },
  methods: {
    closeAlertInfoBanner() {
      localStorage.setItem(this.alertInfoBannerClosedCookie, 'true');
      localStorage.setItem(this.alertInfoBannerClosedTimeCookie, `${new Date().getTime()}`);
      this.$emit('alertInfoBannerClosed', {});
    }
  }
};
</script>
<style lang="scss">
.alert-info-banner {
  position: relative;
  margin-bottom: 4rem;
  background-color: $white;
  @include themify($themes) {
    box-shadow: 0px 3px 0px 0px themed('primary');
  }
}

.alert-oval {
  height: 64px;
  width: 64px;
  opacity: 0.3;
  background-color: $primary;
  border-radius: 50%;
  position: relative;
  margin: 0px auto;
}

.icon-alert {
  margin: 0 auto;
  @include color('primary')
}
.icon-alert-solid {
  color: $white;
  margin: 0 auto;
  height: 36px;
  width: 36px;
  left: 30%;
  position: absolute;
  top: 20%;
}
</style>
