<template>
  <div class="top-bar">
    <MarketsTicker
      v-if="$store.state.publication.enableMarketsTicker"
      @mount="notifyTopBarUpdate"/>
    <Navbar/>
  </div>
</template>
<script>
import { Navbar } from 'global-navbar';
import { MarketsTicker } from 'global-components';

export default {
  name: 'top-bar',
  components: { MarketsTicker, Navbar },
  mounted() {
    this.notifyTopBarUpdate();
  },
  methods: {
    notifyTopBarUpdate() {
      const el = this.$root.$el.querySelector('.top-bar');
      const topBarHeight = el.getBoundingClientRect().top + el.getBoundingClientRect().height;
      this.$root.$emit('topBarHeight', topBarHeight);
    }
  }
};
</script>
