<template>
  <Modal
    @close="$emit('close')"
    :class="`alert-action-status ${action} ${status} ff-sueca-sans`">
    <template v-slot:header>
      <div class="alert-header">
        <FontAwesomeIcon :icon="icons.faBell"/>
        {{ __(`alert.${action}.${status}`) }}
      </div>
    </template>
    <template v-slot:body>
      <div>
        <p
          v-html="__(`alert.${action}.${status}.note`)"
          class="ff-flama fs-lg fw-light pb-3 pt-3"></p>
        <button
          @click="$emit('continue')"
          class="continue btn btn-primary w-100 mb-4">
          {{ __('btn.continue') }}
        </button>
      </div>
    </template>
  </Modal>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faBell } from '@fortawesome/pro-regular-svg-icons';

export default {
  name: 'alert-action-status',
  components: { FontAwesomeIcon },
  props: {
    action: {
      type: String,
      required: true,
      validator: (action) => ['create', 'edit', 'delete'].includes(action),
    },
    success: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      status: this.success ? 'success' : 'failed',
      icons: {
        faBell,
      },
    };
  },
};
</script>
