import allBlackListedTopics from './blackListedTopics.json';

export const getBlackListedTopicsByPub = (pubName) => {
  if (!allBlackListedTopics[pubName]) return [];
  return allBlackListedTopics[pubName].map(topic => topic.toLowerCase());
};

export const isBlackListedTopic = (pubName, topic) => {
  return getBlackListedTopicsByPub(pubName).includes(topic.label.toLowerCase());
};

/**
 * forcefully convert the subType of each topic based on value of newsStreamTopics in config file for alert-topics
 */
export const syncAlertTopicSubType = (alertTopics, topic) => {
  try {
    const matchedTopic = alertTopics.filter(val => val.name === topic.term);
    if (matchedTopic.length > 0) {
      topic.subType = matchedTopic[0].type;
    }
    return topic;
  } catch (e) {
    return topic;
  }
};
