<template>
  <div class="privat-order wrapper ff-flama">
    <Row>
      <Column md="12">
        <MarkedPlassenBanner @back="$emit('back')"/>
      </Column>
    </Row>
    <template v-if="!showDetails">
      <Row class="privat-order-description my-3 mx-3">
        <Column
          md="2"
          class="promo-pic"></Column>
        <column
          md="9"
          class="description">
          <div class="promo-desc">
            <p>Fiskeribladet er Norges ledende medie innen fiskerinæringen. Få ditt budskap ut til 14.850 daglige lesere* (avis utgivelse tirsdag + fredag)</p>
          </div>
          <div class="product-desc">
            <p>
              Som privatannonsør får du annonsen din vist i papiravisen Fiskeribladet .
              Her får du 6 innrykk.
              Kjøp og Salg budskapet ditt kan du tilpasse LITEN, MELLOMSTOR OG STOR annonse,
              se hvor mange tegn du trenger.
            </p>
          </div>
        </column>
      </Row>
      <Row class="privat-order-products my-3 mx-3">
        <Column md="12">
          <template v-for="product in productInfo">
            <div
              @click="selectProduct"
              :id="product.id"
              :key="product.id"
              class="privat-product-row-wrapper">
              <Row class="privat-product-row">
                <Column md="2">
                  <div
                    v-text="product.name"
                    class="product-name fw-bold"></div>
                  <div class="product-desc">
                    <p
                      v-if="product.note !== ''"
                      class="fw-light my-0">{{ product.note }}</p>
                    <p class="fw-light my-0">{{ product.indents }}</p>
                    <p class="fw-light my-0">{{ product.sizeLimit }}</p>
                    <p class="fw-bold">{{ product.price }}</p>
                  </div>
                </Column>
                <Column
                  md="4"
                  class="offset-6">
                  <div
                    v-if="product.photo !== ''"
                    class="product-photo-wrapper">
                    <img
                      :src="product.photo"
                      :alt="product.name"
                      class="product-photo">
                  </div>
                </Column>
              </Row>
            </div>
          </template>
          <div class="source-note my-2">*Gjennomført av  Kantar 20/1</div>
        </Column>
      </Row>
    </template>
    <template v-else>
      <PrivatProductForms
        :product-id="selectedProductId"
        :product="selectedProduct"
        @cancel="cancelForm"/>
    </template>
  </div>
</template>

<script>
import bestillAnnonse from 'global-configs/bestillAnnonse';
import MarkedPlassenBanner from './MarkedPlassenBanner';
import PrivatProductForms from './PrivatProductForms';

export default {
  name: 'order-privat',
  components: {
    MarkedPlassenBanner,
    PrivatProductForms,
  },
  data() {
    return {
      productInfo: bestillAnnonse[this.$pubCtx.name].privat,
      showDetails: false,
      selectedProductId: '',
      selectedProduct: {}
    };
  },
  methods: {
    selectProduct(ev) {
      this.selectedProductId = ev.currentTarget.id;
      this.selectedProduct = this.productInfo.filter(prod => prod.id === ev.currentTarget.id)[0];
      this.showDetails = true;
    },
    cancelForm() {
      this.selectedProductId = '';
      this.selectedProduct = {};
      this.showDetails = false;
    },
    goBack() {
      this.$emit('back');
    }
  }
};
</script>
