<template>
  <div
    v-if="tickerInfo.length"
    class="ff-flama fs-xs pt-1 mb-3 d-print-none">
    <div
      v-if="!hideRelatedLinkSection"
      class="related-links mb-3 clearfix">
      <h3 class="fs-md float-left mb-0 mr-3 lh-22">RELATERTE LINKER:</h3>
      <ul class="unstyled-list float-left clearfix">
        <template
          v-for="ticker in tickerInfo">
          <li
            v-if="ticker.homepage"
            :key="ticker.index"
            class="float-left mr-3 mb-0 clear-both">
            <a
              :href="ticker.homepage"
              class="fs-sm fw-bold lh-22">
              {{ ticker.ticker }}
            </a>
          </li>
        </template>
      </ul>
    </div>
    <div
      v-if="!hideEnergiIntelSection"
      class="energi-intel"> <!-- needs border -->
      <card>
        <card-body>
          <h3 class="fs-lg mb-0">SELSKAPSDATABASEN</h3>
          <p class="fs-sm mb-2">Vil du vite mer om firmaer skrevet om i denne artikkelen? Se dem på Energy Intelligence Selskapsdatabasen</p>
          <ul class="unstyled-list clearfix">
            <template
              v-for="ticker in tickerInfo">
              <li
                v-if="ticker.id !== '0'"
                :key="ticker.index"
                class="float-left mr-3 mb-0">
                <a
                  :href="`/energyintelligence/#/company/${ticker.id}`"
                  class="fs-sm fw-bold">
                  {{ ticker.ticker }}
                </a>
              </li>
            </template>
          </ul>
        </card-body>
      </card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'article-energi-intelligence',
  props: {
    tags: {
      type: Array,
      required: true
    }
  },
  data() {
    const tickers = this.$store.state.article.companyTickers;
    let emptyRelatedLinks = 0;
    let emptyEnergyIntel = 0;

    for (var i = 0; i < tickers.length; i++) {
      emptyRelatedLinks += !(tickers[i].homepage) && 1;
      emptyEnergyIntel += (tickers[i].id === '0') && 1;
    }

    return {
      tickerInfo: tickers,
      hideRelatedLinkSection: this.$store.state.article.hideRelatedCompanyLinks || emptyRelatedLinks === tickers.length,
      hideEnergiIntelSection: emptyEnergyIntel === tickers.length
    };
  }
};
</script>
