<template>
  <div class="mb-4 mt-4">
    <row class="mb-4">
      <column md="12">
        <p class="help-text">{{ __('alert.news.stream.follow.text') }}</p>
        <p class="help-text">{{ __('alert.news.stream.customise.text') }}</p>
      </column>
    </row>
    <row>
      <column
        xs="12"
        class="main-category text-uppercase ff-flama fs-md fw-sm mb-4">
        <h5>{{ __('alert.suggested.topics') }}</h5>
      </column>
      <column
        v-for="topic in $store.state.suggestedTopics"
        :key="topic.name"
        xs="6"
        md="4"
        class="ff-flama fs-lg fw-medium">
        <h5 class="mb-4">
          <a
            @click="subscribe(topic)"
            href="javascript:void(0);"
            class="d-inline-block align-middle font-weight-bold">{{ topic.label }}</a>
        </h5>
      </column>
    </row>
    <AlertModal
      :show="showSubscribeModal"
      @close="showSubscribeModal = false">
      <AlertOptionChooser
        :items="subscribeItem"
        :from-page="true"
        :sub-type="subType"
        @confirm="confirmSubscription"
        @confirmError="handleConfirmError"/>
    </AlertModal>
    <AlertConfirmModal
      :show-confirm="showConfirmModal"
      :show-success="confirmSuccess"
      :show-already-subscribed="alreadySubscribedTopicSubType"
      :from-page="true"
      @close="() => { showConfirmModal = false; confirmSuccess = false; onConfirmModalclose(); }">
    </AlertConfirmModal>
  </div>
</template>
<script>
import AlertModal from './AlertModal';
import AlertConfirmModal from './AlertConfirmModal';
import AlertOptionChooser from './AlertOptionChooser';
import { EventBus, alertLocalStorageUtil, isEmpty } from 'global-utils';

export default {
  name: 'alert-promo-suggested-topics',
  components: {
    AlertModal,
    AlertOptionChooser,
    AlertConfirmModal,
  },
  data() {
    return {
      suggestionColumns: 3,
      publicationName: this.$pubCtx.label,
      subscribeItem: [],
      subType: '',
      showSubscribeModal: false,
      showConfirmModal: false,
      confirmSuccess: true,
      alreadySubscribedTopicSubType: '',
    };
  },
  methods: {
    async subscribe({ name, label, type }) {
      const subscriptionData = await alertLocalStorageUtil.getItem();
      const alreadySubscribedTopic = subscriptionData &&
        subscriptionData.topics &&
        subscriptionData.topics.find(topic => topic.term === name);

      if (alreadySubscribedTopic) {
        this.alreadySubscribedTopicSubType = alreadySubscribedTopic.subType;
        this.confirmSuccess = false;
        this.showConfirmModal = true;
        return;
      }
      this.subscribeItem = [{ name, label, type }];
      this.subType = type;
      this.showSubscribeModal = true;
    },
    confirmSubscription(confirmData) {
      this.showSubscribeModal = false;
      this.showConfirmModal = true;
      this.confirmSuccess = true;
    },
    handleConfirmError(err) {
      console.error('something went wrong saving subscription: ', err);

      this.showSubscribeModal = false;
      this.showConfirmModal = true;
      this.confirmSuccess = false;
    },
    onConfirmModalclose() {
      if (!isEmpty(this.subscribeItem)) {
        EventBus.$emit('newsPanel:refresh');
      }
    }
  }
};
</script>
