module.exports = {
  streamURL: 'wss://nhstglobalagg.azurewebsites.net/',
  products: [
    {
      symbol: 'C1',
      name: 'Brent spot',
      currency: '$',
      currencyPosition: 'F'
    },
    {
      symbol: 'C3',
      name: 'Brent futures (1 mo)',
      currency: '$',
      currencyPosition: 'F'
    },
    {
      symbol: 'C2',
      name: 'WTI spot',
      currency: '$',
      currencyPosition: 'F'
    },
    {
      symbol: 'C5',
      name: 'WTI futures (1 mo)',
      currency: '$',
      currencyPosition: 'F'
    },
    {
      symbol: 'C6',
      name: 'UK Nat Gas (1 mo)',
      currency: 'p',
      currencyPosition: 'B'
    },
    {
      symbol: 'C4',
      name: 'UK Nat Gas (2 mo)',
      currency: 'p',
      currencyPosition: 'B'
    }
  ]
};
