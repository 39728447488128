<template>
  <div class="ff-flama fs-xs border-top border-bottom mb-3">
    <row class="img-byline pt-3 pb-3 mr-0 ml-0">
      <column md="2">
        <div class="profile-img">
          <img
            :src="profileAvatar.avatarSrc"
            :alt="profileAvatar.authorName"
            width="72">
        </div>
      </column>
      <column md="8">
        <div class="d-block">
          <ArticleAuthors
            :img-byline="true"
            class="d-inline author-name ff-flama fw-light fs-md tt-uppercase"/>
          <ArticleLocation class="d-inline" />
        </div>
        <span
          v-text="profileText"
          class="d-block author-profile-text ff-flama fw-light fs-sm"></span>
        <ArticleDate
          :date="publishedAt"
          class="pr-3"/>
        <ArticleDate
          :date="updatedAt"
          show-update-prefix
          prefix-style-class="tt-uppercase"
          class="st-italic"/>
      </column>
    </row>
  </div>
</template>

<script>
import ArticleDate from './Date';
import ArticleAuthors from './Authors';
import ArticleLocation from './Location';

export default {
  name: 'article-image-byline',
  components: {
    ArticleDate,
    ArticleAuthors,
    ArticleLocation
  },
  data() {
    return {
      updatedAt: this.$store.state.article.updatedAt,
      publishedAt: this.$store.state.article.publishedAt,
    };
  },
  computed: {
    profileAvatar() {
      let avatarSrc = '';
      let authorName = '';
      this.$store.state.article.authors.map(author => {
        if (author.options && author.options.profileImage) {
          avatarSrc = author.options.profileImage;
          authorName = author.name;
        }
      });
      return { avatarSrc, authorName };
    },
    profileText() {
      let alterNativeProfileText = '';
      this.$store.state.article.authors.map(author => {
        if (author.options && author.options.alternativeBylineProfileText) {
          alterNativeProfileText = author.options.alternativeBylineProfileText;
        }
      });
      return alterNativeProfileText;
    }
  }
};
</script>
