<template>
  <figure :class="[$props.styleClass]">
    <img
      :src="placeholder || `${$cdn.resource('landscape.gif')}`"
      :alt="alt"
      :srcset="srcset"
      :sizes="sizes"
      width="100%">
    <figcaption
      v-if="caption"
      v-html="caption"
      class="figure-caption"/>
  </figure>
</template>
<script>
import { versions } from './Card';
export default {
  name: 'picturefill',
  props: {
    placeholder: String,
    imageSrc: String,
    sizes: String,
    alt: String,
    caption: String,
    styleClass: String
  },
  computed: {
    srcset() {
      return versions.map(ver => `${this.imageSrc}?image_version=${ver} ${ver}w`).join(', ');
    }
  },
};
</script>
