<template>
  <ul
    v-if="relatedArticles.length > 0"
    :class="[
      $options.name,
      {[`${styleClass}`]:styleClass}]">
    <li class="ff-flama fs-lg text-uppercase">
      {{ __('relatednews.header') }}
    </li>
    <li
      v-for="{title, url, mainCategory, publishedAt} in relatedArticles"
      :key="url"
      class="mb-3">
      <h2
        class="title ff-flama fs-md fw-medium">
        <a
          :href="resolveUrl(url, `Related News: ${title}`)"
          class="text-reset">
          {{ title }}
        </a>
      </h2>
      <div
        class="category-list ff-flama fs-sm fw-medium text-capitalize">
        <span
          v-if="mainCategory"
          class="category mr-3">
          <a :href="mainCategory.url">
            {{ mainCategory.label }}
          </a>
        </span>
      </div>
      <div>
        <ArticleDate
          :date="publishedAt"
          class="ff-flama fs-xs"/>
      </div>
    </li>
  </ul>
</template>

<script>
import { resolveUrl } from 'global-utils';
import ArticleDate from './Date';

export default {
  name: 'article-related-news',
  components: {
    ArticleDate
  },
  props: {
    styleClass: String,
    showBlurredImage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      relatedArticles: this.$store.state.article.relatedArticles
    };
  },
  methods: {
    resolveUrl
  }
};
</script>
