<template>
  <iframe
    id="paywall-iframe"
    :src="paywallIframePath"
    width="100%"
    height="478"
    seamless
    frameborder="0"
    scrolling="no">
  </iframe>
</template>

<script>
import { AssetMixin } from 'global-mixin';

export default {
  name: 'paywall-iframe',
  mixins: [AssetMixin],
  props: {
    targetQuery: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    let q = this.targetQuery ? '&' : '?';
    q = this.$route.params.view ? q + 'view=app' : '';
    return {
      paywallIframePath: this.$store.state.paywallUrl + this.targetQuery + q,
    };
  },
  async assets() {
    var $iframe = document.getElementById('paywall-iframe');
    window.addEventListener('message', function (event) {
      if (event.data && (typeof event.data['iframe-height'] !== 'undefined')) {
        $iframe.setAttribute('height', event.data['iframe-height']);
      }
    }, false);
  }
};
</script>
