
<template>
  <div class="alert-paywall mt-5">
    <template v-if="isIPUser">
      <div class="title my-2">{{ __('you.are.unable.ip.subscription') }}</div>
      <div class="hints mb-4">{{ __('contact.subscription.department') }}</div>
      <a
        :href="$pubCtx.language === 'no' ? '/kontaktoss' : '/contactus'"
        class="btn btn-primary w-100">{{ __('contact.us') }}</a>
    </template>
    <template v-else>
      <div class="title my-2">{{ __('alert.is.a.service.for.subscribers') }}</div>
      <div class="hints mb-4">{{ __('login.or.subscribe.for.alert') }}</div>
      <a
        :href="`/auth/user/login?target=${encodeURIComponent(targetUrl)}`"
        class="btn btn-secondary w-100 mb-2">{{ __('login') }}</a>
      <a
        class="btn btn-primary w-100"
        href="/subscription">{{ __('subscribe') }}</a>
    </template>
  </div>
</template>
<script>
import { isIPLogin } from 'global-utils';

export default {
  name: 'alert-paywall',
  props: {
    targetUrl: {
      type: String,
      required: false,
      default: '/'
    }
  },
  data() {
    return {
      isIPUser: false,
    };
  },
  mounted() {
    this.isIPUser = isIPLogin(this);
  },
};
</script>
<style lang="scss">
  .alert-paywall {
    .title {
      font-size: 1.8rem;
      line-height: 2.5rem;
      font-weight: 400;
    }
    .hints {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  }
</style>
