<template>
  <!-- BAD HACK -> v-show=false -->
  <!-- UniteGallery shows images before initializing gallery functions-->
  <div>
    <div
      id="picture-gallery"
      v-if="leadImages.length"
      v-show="false">
      <img
        v-for="{imageSource, imageCredit} in leadImages"
        :key="imageSource"
        :src="imageSource"
        :data-image="imageSource"
        :data-description="`${imageCredit.caption} ${imageCredit.credit}`">
    </div>
    <figcaption
      v-if="caption"
      class="mt-2 mb-3 figure-caption">
      {{ caption }}
    </figcaption>
  </div>
</template>
<script>
import { stripPhotoPrefix, extractTextFromHtml, loadJs } from 'global-utils';
import { AssetMixin, EmbedMixin } from 'global-mixin';
import { jQuery } from 'assets/js/bundled-assets';

export default {
  name: 'lead-picture-gallery',
  mixins: [AssetMixin, EmbedMixin],
  data: function () {
    return {
      caption: ''
    };
  },
  computed: {
    leadImages() {
      const leadImgObjects = this.$store.state.article.leadAsset.leadImages;
      return leadImgObjects.map(img => {
        img.imageCredit.caption = extractTextFromHtml(img.imageCredit.caption) || '';
        img.imageCredit.credit = stripPhotoPrefix(img.imageCredit.credit, this.__) || '';
        return img;
      });
    }
  },
  embed(head) {
    head.style(this.$cdn.resource('lib/unitegallery/css/unite-gallery.css'));
  },
  async assets() {
    var self = this;
    /* Files below must be loaded in given sequence */
    const $ = await jQuery();
    await loadJs(`${this.$cdn.resource('lib/unitegallery/js/unitegallery.js')}`);
    await loadJs(`${this.$cdn.resource('lib/unitegallery/themes/compact/ug-theme-compact.js')}`);
    $(document).ready(function () {
      var api = $('#picture-gallery').unitegallery({
        slider_scale_mode: 'fit',
        slider_scale_mode_media: 'fit',
        slider_scale_mode_fullscreen: 'fit',
        slider_enable_text_panel: true,
        slider_textpanel_always_on: true,
        slider_enable_play_button: false,
        slider_enable_zoom_panel: false,
        strippanel_background_color: '#323232',
        strippanel_padding_top: 0,
        strippanel_padding_bottom: 0,
        strippanel_padding_left: 0,
        strippanel_padding_right: 0,
        strippanel_enable_handle: false,
        strip_space_between_thumbs: 0,
        strip_thumbs_align: 'center',
        thumb_border_effect: false,
        slider_textpanel_enable_title: false,
        slider_textpanel_bg_opacity: 1,
        slider_fullscreen_button_align_hor: 'right'
      });

      $('#picture-gallery .ug-textpanel').hide();
      self.caption = api.getItem(0).description;

      api.on('item_change', function (num, data) {
        self.caption = data.description;
      });

      api.on('enter_fullscreen', function () {
        $('#picture-gallery .ug-textpanel').show();
      });

      api.on('exit_fullscreen', function () {
        $('#picture-gallery .ug-textpanel').hide();
      });
    });
  }
};
</script>
