<template>
  <ul class="unstyled-list">
    <li
      v-for="({ title, detailsUrl, recruiterName }, idx) in featuredJobs"
      :key="idx"
      class="featured-job p-3">
      <h2
        class="ff-flama fs-lg fw-medium mb-3">
        <a
          :href="detailsUrl"
          target="_blank"
          rel="noopener"
          class="text-reset">
          {{ title }}
        </a>
      </h2>
      <a
        :href="detailsUrl"
        rel="noopener"
        target="_blank"
        class="text-reset ff-sueca fs-lg">
        {{ recruiterName }}
      </a>
    </li>
  </ul>
</template>

<script>
import { slice } from 'global-utils';

export default {
  name: 'featured-jobs',
  meta: {
    title: 'FeaturedJobs'
  },
  data() {
    return {
      featuredJobs: slice(this.$store.state.jobsList, 0, 3) || []
    };
  }
};
</script>
