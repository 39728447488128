<template>
  <div :class="['justify-content-center', show ? 'd-flex' : 'd-none']">
    <div
      class="spinner-border"
      role="status">
      <span class="sr-only">{{ __('alert.loading') }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'alert-spinner',
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  }
};
</script>
