<template>
  <div :class="[classes, $props.styleClass]">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'column',
  props: {
    styleClass: String,
    xs: [Number, String],
    sm: [Number, String],
    md: [Number, String],
    lg: [Number, String],
    xl: [Number, String],
    offsetXs: [Number, String],
    offsetSm: [Number, String],
    offsetMd: [Number, String],
    offsetLg: [Number, String],
    offsetXl: [Number, String],
  },
  computed: {
    classes() {
      return {
        [`col-${this.xs}`]: this.xs,
        [`col-sm-${this.sm}`]: this.sm,
        [`col-md-${this.md}`]: this.md,
        [`col-lg-${this.lg}`]: this.lg,
        [`col-xl-${this.xl}`]: this.xl,
        [`offset-${this.offsetXs}`]: this.offsetXs,
        [`offset-sm-${this.offsetSm}`]: this.offsetSm,
        [`offset-md-${this.offsetMd}`]: this.offsetMd,
        [`offset-lg-${this.offsetLg}`]: this.offsetLg,
        [`offset-xl-${this.offsetXl}`]: this.offsetXl
      };
    }
  }
};
</script>
