export const stripPhotoPrefix = (credit, getTranslation) => {
  if (!credit) return null;

  const prefix = typeof getTranslation === 'function' ? getTranslation('photo') : 'Photo';
  const searchMask = ['photo', 'foto'];

  searchMask.forEach(mask => {
    const regEx = new RegExp('^' + mask + '\\s?:', 'gi');
    credit = credit.replace(regEx, '');
  });

  let creditPrefix = credit;
  const colonRegEx = new RegExp(':', 'g');
  creditPrefix = creditPrefix.replace(colonRegEx, '');

  return `${prefix}: ${creditPrefix}`;
};
