/*
Another Ugly Hack
------------------------------------------------------
Importing styles for fontawesome-svg-core is redundant
The fontawesome main file is already embed these styles
using javascript. Hence only Firefox shows unwanted sized
icons first while javascript is still getting ready to execute
TODO improvement would be good to have
 */

import 'core-js';
import 'intersection-observer';
import 'isomorphic-fetch';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { loadBrowserModules } from 'global-utils/browser-modules';
import { createApp } from './app.csr';
import App from './App.vue';

const {
  app,
  router,
  store
} = createApp();

// prime the store with server-initialized state.
// the state is determined during SSR and inlined in the page markup.

// wait until router has resolved all async before hooks
// and async components...
router.onReady(() => {
  [...Object.values(App.components), ...router.getMatchedComponents()]
    .forEach(c => loadBrowserModules(c, router, store));
  // Add router hook for handling asyncData.
  // Doing it after initial route is resolved so that we don't double-fetch
  // the data that we already have. Using router.beforeResolve() so that all
  // async components are resolved.
  router.beforeResolve((to, from, next) => {
    const matched = router.getMatchedComponents(to);
    const prevMatched = router.getMatchedComponents(from);
    let diffed = false;
    const activated = matched.filter((c, i) => {
      return diffed || (diffed = (prevMatched[i] !== c));
    });
    if (!activated.length) {
      return next();
    }
    Promise.all(activated.map(c => {
      if (c.asyncData) {
        return c.asyncData({
          store,
          route: to
        });
      }
    })).then(() => {
      next();
    }).catch(next);
  });

  // actually mount to DOM
  app.$mount('#app');
});
