<template>
  <div
    v-if="canDisplay"
    class="native-app">
    <div class="native-app__content">
      <img
        :src="`${$cdn.resource(`gfx/${$pubCtx.name}/app-logo.svg`)}`"
        alt="app link">
      <div class="native-app__title">
        <p>{{ title }}</p>
        <span>{{ description }}</span>
      </div>
    </div>

    <a
      :href="appDeepLink"
      class="button native-app__button native-app__button--color">{{ buttonText }}</a>
  </div>
</template>

<script>
export default {
  name: 'app-link',
  data() {
    return {
      DomainsNaturalSearchSocialCONST: ['www.google.', 'google.', 'bing.', 'search.yahoo.', 'facebook.',
        'facebook.com', 't.co', 'pinterest.', 'linkedin.com', 'linkedin.',
        'plus.google', 'instagram.com', 'twitter.com', 'www.twitter.'],
      mobileBrowserFrequencyCONST: '',
      appDeepLink: '',
      canDisplay: false,
      displayDeepLinkAfterViewCount: 3,
      appEnv: '',
      articleId: '',
      title: '',
      description: '',
      buttonText: '',
    };
  },
  mounted() {
    this.articleId = this.$store.state.article ? this.$store.state.article.id : '';
    this.appEnv = window.appEnv || 'prod';
    this.mobileBrowserFrequencyCONST = `globalMobileBrowserArticleViewFrequency-${this.$pubCtx.name}-new`;
    this.appDeepLink = this.makeAppDeepLink();
    if (this.shouldShowSmartAppBanner()) {
      if (this.isShowAppBanner()) {
        const meta = document.createElement('meta');
        meta.name = 'apple-itunes-app';
        meta.content = `app-id=${this.$pubCtx.appDeepLink.smartAppBanner.appId}, app-argument=${document.location.href}`;
        document.getElementsByTagName('head')[0].appendChild(meta);
      }
    } else if (this.shouldShowAppDeepLink()) {
      this.canDisplay = this.isShowAppBanner();
      this.title = this.$pubCtx.appDeepLink.title;
      this.description = this.$pubCtx.appDeepLink.description;
      this.buttonText = this.$pubCtx.appDeepLink.buttonText;
    }
  },
  methods: {
    shouldShowAppDeepLink() {
      return this.appDeepLink &&
        this.$pubCtx.appDeepLink.show &&
        this.isMobile() &&
        (this.articleId || this.isFirstVisit());
    },
    shouldShowSmartAppBanner() {
      return this.$pubCtx.appDeepLink &&
        this.$pubCtx.appDeepLink.show &&
        this.$pubCtx.appDeepLink.smartAppBanner &&
        this.$pubCtx.appDeepLink.smartAppBanner.show &&
        (this.articleId || this.isFirstVisit()) &&
        this.isIOSSafari();
    },
    isIOSSafari() {
      const userAgent = navigator.userAgent;
      const isSafari = userAgent.indexOf('Safari') !== -1;
      const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
      return isSafari && isIOS;
    },
    isFirstVisit() {
      return localStorage.getItem(this.mobileBrowserFrequencyCONST) === null;
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    updateAndGetArticleViewCount() {
      if (typeof localStorage !== 'undefined') {
        const count = localStorage.getItem(this.mobileBrowserFrequencyCONST);
        const newCount = (count != null) ? parseInt(count) + 1 : 1;
        localStorage.setItem(this.mobileBrowserFrequencyCONST, newCount.toString());
        return newCount;
      }
      return 0;
    },
    resetArticleViewCount() {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(this.mobileBrowserFrequencyCONST, '0');
      }
    },
    isShowAppBanner() {
      let isShowAppBanner = false;
      const referrerDomain = document.referrer && document.referrer.match(/:\/\/(.[^/]+)/)[1];
      if (referrerDomain !== '') {
        const result = this.DomainsNaturalSearchSocialCONST.filter(function (domain) {
          return referrerDomain.indexOf(domain) !== -1;
        });
        isShowAppBanner = (result.length > 0);
      }
      if (this.isFirstVisit() || this.updateAndGetArticleViewCount() >= this.displayDeepLinkAfterViewCount) {
        isShowAppBanner = true;
        this.resetArticleViewCount();
      }
      return isShowAppBanner;
    },
    makeAppDeepLink() {
      const args = {
        articleId: this.articleId,
      };
      return this.stringFormat(this.$pubCtx.appDeepLink.urlTemplate, args);
    },
    stringFormat(mainString, args) {
      return mainString.replace(/{(\w+)}/g, function (match, matchGroup) {
        return typeof args[matchGroup] !== 'undefined' ? args[matchGroup] : match;
      });
    },
  }
};
</script>

<style scoped lang="scss">
.native-app {
  position: fixed;
  height: $native-app-height;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: space-between;
  background-color: #FFFFFF;
  font-family: "Flama", $fallback-sans-serif-fonts;
  padding: 16px 8px;
  z-index: 10;

  &__content {
    display: flex;
    align-items: center;
    line-height: 1.2;
  }

  &__title {
    padding-left: 8px;

    span, p {
      font-size: 16px;
    }

    p {
      margin: 0;
      font-weight: bold;
    }

    span {
      color: #6e6e6e;
    }
  }

  &__button {
    border: none;
    font-weight: 400;
    padding: 8px 16px;
    color: #FFFFFF;
    border-radius: 4px;

    &:active, &:focus {
      background-color: #2f2f2f;
    }
  }
}
</style>
