<template>
  <div
    v-if="source"
    :class="[$props.styleClass]">
    <card-image
      :source="source"
      :sizes="_sizes"
      :placeholder="false"
      :imgWrapperClasses="imgWrapperClasses"
      eager></card-image>
  </div>
</template>
<script>
export default {
  name: 'lead-image',
  props: {
    showBlurredImage: {
      type: Boolean,
      default: false
    },
    sizes: {
      type: String,
    },
    styleClass: String,
    figureClass: String
  },
  computed: {
    source() {
      return this.$store.state.article.leadAsset.imageSource;
    },
    _sizes() {
      return this.sizes || this.$store.state.publication.leadImageSizes.article;
    },
    imgWrapperClasses() {
      const imageRepresentation = this.$store.state.article.leadAsset.imageRepresentation || '';
      let classes = '';
      if (imageRepresentation === 'three_two') {
        classes = 'aspect-three-two';
      }
      return classes;
    }
  }
};
</script>
