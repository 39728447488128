<template>
  <div class="pagination ff-flama fw-medium fs-md text-uppercase border-top pt-2 mb-5 clearfix">
    <a
      v-if="page > 1"
      :href="`?page=${page - 1}`"
      class="text-reset previous float-left clearfix w-50">
      {{ __('previous') }}
      <FontAwesomeIcon :icon="angleLeftIcon"/>
    </a>
    <a
      v-if="page * size < total"
      :href="`?page=${page + 1}`"
      class="text-reset next float-right clearfix w-50">
      {{ __('next') }}
      <FontAwesomeIcon :icon="angleRightIcon"/>
    </a>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/pro-light-svg-icons';

export default {
  name: 'pagination',
  components: {
    FontAwesomeIcon
  },
  props: {
    offset: {
      type: Number,
      default: 0,
      required: true
    },
    size: {
      type: Number,
      default: 0,
      required: true
    },
    total: {
      type: Number,
      default: 0,
      required: true
    }
  },
  data() {
    return {
      angleRightIcon: faAngleRight,
      angleLeftIcon: faAngleLeft
    };
  },
  computed: {
    page() {
      return this.offset / this.size + 1;
    },
  }
};
</script>
