<template>
  <Modal
    class="ff-flama"
    no-close-button
    style="background-color: white">
    <template v-slot:header>
      <div>
        <div class="fw-medium ff-flama-book">
          Enter username and password to access this page:
        </div>
        <div> https:// {{ $pubCtx.domain }}</div>
        <div class="fs-sm mt-1 ff-flama-book">
          Note: Entrance to this page is not intended for access by third parties. Please do not enter your DN ID/password above.
        </div>
      </div>
    </template>
    <template v-slot:body>
      <form @submit.prevent>
        <fieldset>
          <label for="username">{{ __('username') }}</label>
          <input
            id="username"
            v-model="userName"
            type="text"
            name="username"
            class="form-control mb-3">
          <label for="password">{{ __('password') }}</label>
          <input
            id="password"
            v-model="passWord"
            type="password"
            name="password"
            class="form-control mb-3">
          <div class="d-flex justify-content-end">
            <button
              @click="onSubmit"
              class="btn btn-primary mr-2"
              type="submit">OK</button>
            <button
              @click="onCancel"
              class="btn btn-secondary">Cancel</button>
          </div>
        </fieldset>
      </form>
    </template>
  </Modal>
</template>

<script>
import axios from 'axios';
export default {
  name: 'restricted-article-modal',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userName: '',
      passWord: ''
    };
  },
  methods: {
    onSubmit() {
      const postData = {
        id: this.id,
        userName: this.userName,
        passWord: this.passWord
      };
      axios.post('/article/restricted/checkAccess', postData)
        .then((res) => {
          if (res.status === 200) {
            this.$emit('unRestricted');
          } else {
            throw new Error();
          }
        }).catch(() => {
          window.location.href = '/NotFound';
        });
    },
    onCancel() {
      window.location.href = '/';
    },
    reset() {
      this.userName = '';
      this.passWord = '';
    }
  }
};
</script>

<style scoped>

</style>
