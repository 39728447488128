<template>
  <div
    v-if="$store.state.alertsEnabled && !$pubCtx.alert.disabled"
    class="alert-safeguard">
    <slot/>
  </div>
</template>
<script>
export default {
  name: 'alert-safeguard'
};
</script>
