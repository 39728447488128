<template>
  <div>
    <component
      :is="component"
      :data-io-article-url="articleUrl"/>
  </div>
</template>
<script>

import { stripTags, inlineAdProcessor } from 'global-utils';
import InlineElement from './InlineElement';
import cheerio from 'cheerio';

export default {
  name: 'article-body',
  data() {
    return {
      component: {
        template: this.parseBody(),
        components: { InlineElement }
      },
      articleUrl: stripTags(this.$store.state.requestUrl)
    };
  },
  mounted() {
    this.embedCopyrightLink();
  },
  methods: {
    parseBody() {
      let parsedBody = this.wrapWithDivIfNeeded(this.$store.state.article.body);
      // Process subhead, inline media files element, then Inline Elements and Return
      parsedBody = this.processSubhead(parsedBody);
      parsedBody = parsedBody.replace(new RegExp('(<figure\\s)(itemscope=([^>]*)>)([^>]*>)([^>]*>)\\s(<\\/figure>)', 'g'),
          `<inline-element ref-id="media">$4$5</inline-element>`);
      parsedBody = this.processInlineNewsGroup(parsedBody);
      parsedBody = this.processInlineNews(parsedBody);
      parsedBody = parsedBody.replace(new RegExp('(<p><link\\s)([^>]*)\\bref(\\s*=)([^>]*)\\s(\\/>)', 'g'),
        '<p><inline-element $2ref-id$3$4$5')
        .replace(new RegExp('(<blockquote[\\s\\S]*?>)(\\s*\\n*\\s*\\")([\\s\\S]*?)(\\"\\s*\\n*\\s*)(<\\/blockquote>)', 'g'),
          '$1<p itemprop="text">$3</p>$5');
      parsedBody = this.processInlinePromoBox(parsedBody);
      parsedBody = this.processInlineAds(parsedBody);
      parsedBody = this.embedAuthorDisclaimer(parsedBody);
      return parsedBody;
    },
    embedAuthorDisclaimer(body) {
      if (this.$store.state.article.meta.authorDisclaimer) {
        const authorDisclaimerLabel = `${this.$i18n(`authorDisclaimer.${this.$store.state.article.meta.authorDisclaimer}`)}`;
        if (authorDisclaimerLabel !== '') {
          const $ = cheerio.load(body, {
            xmlMode: true
          });
          $('div').first().prepend(`<p class="st-authortext">${authorDisclaimerLabel}</p>`);
          return $.html();
        }
      }
      return body;
    },
    embedCopyrightLink() {
      if (!this.$store.state.publication.enableArticleCopyright || (this.$route.meta.pageContext.type === 'infoArticle')) {
        return;
      }

      const copyrightAnchor = () => {
        const anchor = document.createElement('a');
        anchor.setAttribute('href', '/terms?tab=digital');
        anchor.classList.add('pl-1');
        anchor.innerText = `(${this.$i18n('article.copyright')})`;
        return anchor;
      };

      let lastParagraph = null;

      this.$el.childNodes.forEach(div => {
        const paragraphs = div.querySelectorAll('p');
        if (paragraphs.length > 0) {
          lastParagraph = paragraphs[paragraphs.length - 1];
        }
      });

      if (lastParagraph) {
        lastParagraph.appendChild(copyrightAnchor());
      }
    },
    processInlineNewsGroup(body) {
      const inlineNewsGroupPattern = new RegExp('<div class="dn-mulitple-relations-block dn-relation-block">(.|s)+?</div>', 'g');

      if (inlineNewsGroupPattern.test(body)) {
        const groupElements = body.match(inlineNewsGroupPattern, 'g');

        groupElements.map(elem => {
          const newElem = elem.replace(new RegExp('(<p><link\\s)([^>]*)\\bref(\\s*=)([^>]*)\\s(\\/>)', 'g'),
            '<p><inline-element component-type="inline" $2ref-id$3$4$5')
            .replace(new RegExp('(<link\\s)([^>]*)\\bref(\\s*=)([^>]*)\\s(\\/>)', 'g'),
              '<inline-element component-type="inline" $2ref-id$3$4$5');

          const newsContent = newElem.replace(/<(\/)?div([^>]*)>/g, `<$1inline-element ref-id="group" $2>`);
          body = body.replace(elem, newsContent);
        });
      }

      return body;
    },
    processInlineNews(body) {
      const inlineNewsPattern = new RegExp('<div class="dn-relation-block">(.|s)+?</div>', 'g');
      if (inlineNewsPattern.test(body)) {
        const inlineNewsElements = body.match(inlineNewsPattern, 'g');
        inlineNewsElements.map(elem => {
          const newsElement = elem.replace(new RegExp('(<link\\s)([^>]*)\\bref(\\s*=)([^>]*)\\s(\\/>)', 'g'),
            '<inline-element component-type="inline-single" $2ref-id$3$4$5');
          body = body.replace(elem, newsElement);
        });
      }
      body = body.replace(new RegExp('(<link\\s)([^>]*)\\bref(\\s*=)([^>]*)\\s(\\/>)', 'g'),
        '<inline-element $2ref-id$3$4$5');
      return body;
    },
    processInlinePromoBox(body) {
      const inlinePromoBoxPattern = new RegExp('<div id="promobox-[0-9]*"[\\s]+data-promobox-value="([^"]*)[^<]*', 'g');
      if (inlinePromoBoxPattern.test(body)) {
        body = body.replace(inlinePromoBoxPattern, '<div> <inline-element component-type="inline" ref-id="promo-box" promo-box-value="$1"></inline-element>');
      }
      return body;
    },
    processSubhead(body) {
      body = body.replace(new RegExp('<p([^>]*class="subhead"[^>]*)>([^<]*)</p>', 'g'),
        `<h2 $1>$2</h2>`)
        .replace(new RegExp('<div([^>]*class="subhead"[^>]*)>([^<]*)</div>', 'g'),
          `<h2 $1>$2</h2>`);
      return body;
    },
    processInlineAds(body) {
      try {
        Object.keys(this.$pubCtx.inlineArticleAds).forEach(slotId => {
          body = inlineAdProcessor(body, slotId, this);
        });
        return body;
      } catch (e) {
        if (process.env.VUE_ENV === 'server') {
          logger.error('Exception occurred from while processing Array of InlineAds' + e);
        }
        return body;
      }
    },
    wrapWithDivIfNeeded(body) {
      if (!body.startsWith('<div>') && !body.endsWith('</div>')) {
        body = '<div>' + body + '</div>';
      }
      return body;
    }
  },
};
</script>
