var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.relatedArticles.length > 0)?_c('ul',{class:[
    _vm.$options.name,
    ( _obj = {}, _obj[("" + _vm.styleClass)] = _vm.styleClass, _obj )]},[_c('li',{staticClass:"ff-flama fs-lg text-uppercase"},[_vm._v("\n    "+_vm._s(_vm.__('relatednews.header'))+"\n  ")]),_vm._v(" "),_vm._l((_vm.relatedArticles),function(ref){
    var title = ref.title;
    var url = ref.url;
    var mainCategory = ref.mainCategory;
    var publishedAt = ref.publishedAt;
return _c('li',{key:url,staticClass:"mb-3"},[_c('h2',{staticClass:"title ff-flama fs-md fw-medium"},[_c('a',{staticClass:"text-reset",attrs:{"href":_vm.resolveUrl(url, ("Related News: " + title))}},[_vm._v("\n        "+_vm._s(title)+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"category-list ff-flama fs-sm fw-medium text-capitalize"},[(mainCategory)?_c('span',{staticClass:"category mr-3"},[_c('a',{attrs:{"href":mainCategory.url}},[_vm._v("\n          "+_vm._s(mainCategory.label)+"\n        ")])]):_vm._e()]),_vm._v(" "),_c('div',[_c('ArticleDate',{staticClass:"ff-flama fs-xs",attrs:{"date":publishedAt}})],1)])})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }