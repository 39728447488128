<template>
  <div
    @click="goBack()"
    class="markedplassen-banner">
    <div class="markedplassen-logo"></div>
    <div
      v-if="bannerHeading !== 'bedrift'"
      class="markedplassen-heading">{{ bannerHeading }}</div>
  </div>
</template>

<script>
export default {
  name: 'order-markedplassen-banner',
  props: {
    heading: {
      type: String,
      default: 'Privatannonsering'
    }
  },
  data() {
    return {
      bannerHeading: this.heading
    };
  },
  methods: {
    goBack() {
      this.$router.push({ path: '/bestill-annonse' });
    }
  }
};
</script>
