<template>
  <div
    :class="['video embed-responsive embed-responsive-16by9', $props.figureClass]"
    v-html="content">
  </div>
</template>
<script>
export default {
  name: 'lead-video',
  props: {
    styleClass: String
  },
  computed: {
    content() {
      return this.$store.state.article.leadAsset.content.replace(
        new RegExp('<div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: ([+-]?([0-9]*[.])?[0-9]+)%;"><iframe', 'g'),
        `<div class="iframely-embed embed-responsive-smart"><iframe`
      );
    }
  }
};
</script>
