<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>

import { EmbedMixin } from 'global-mixin';

export default {
  name: 'standalone-view',
  mixins: [EmbedMixin],
  embed(head) {
    head.add(`
    <style type="text/css">
      body {
        background-color: rgba(140, 140, 140, 0.0) !important;
      }
    </style>`);
  }
};
</script>
