<template>
  <div :class="styleClass">
    <ArticleDate
      :date="date"
      :show-publish-prefix="showPublishPrefix"
      :show-update-prefix="showUpdatePrefix"
      :publish-date-format="publishDateFormat"
      prefix-style-class="fw-medium"
      class="published-at"/>
    <card-text
      v-if="authorList"
      v-html="authorList"
      class="authors"/>
  </div>
</template>

<script>
import { ArticleDate } from 'global-components/src/Article';

export default {
  name: 'teaser-detail',
  components: { ArticleDate },
  props: {
    showPublishPrefix: {
      type: Boolean,
      default: false
    },
    showUpdatePrefix: {
      type: Boolean,
      default: false
    },
    date: {
      type: String,
      required: true
    },
    publishDateFormat: {
      type: String,
      default: 'D MMM YYYY HH:mm'
    },
    authors: {
      type: Array,
      default: null
    },
    styleClass: String
  },
  computed: {
    authorList() {
      return this.authors
        ? this.authors.map(author => author.name).join(', ')
        : null;
    },
  }
};
</script>
