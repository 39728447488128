const cache = {};
const _el = Symbol('_el');
class Asset {
  constructor(tagName, type, options) {
    if (typeof tagName !== 'string') {
      throw new Error(`A string value for 'tagName' must be provided.`);
    }
    if (typeof type !== 'string') {
      throw new Error(`A string value for 'type' (mime-type) must be provided.`);
    }
    if (typeof options === 'string') {
      options = { url: options };
    }
    this[_el] = document.createElement(tagName);
    this.type = options.type;
    this.parent = options.parent || 'head';
    this.url = options.url;
    this.text = options.text;
    this.id = options.id || options.url;
    this.charset = options.charset || 'utf-8';
    this.async = !!options.async;
    this.force = options.force;
    this.cache = options.cache || true;
    this.assetLoader = true;
  }

  get el() {
    return this[_el];
  }

  get parentEl() {
    return document.getElementsByTagName(this.parent)[0] || document.documentElement;
  }

  buildEl() {
    if (!this.url && !this.text) {
      throw new Error('asset-loader: must provide a url or text to load');
    }
  }

  promise() {
    const cacheId = this.id || this.url;
    const cacheEntry = cache[cacheId];
    if (cacheEntry) {
      console.debug('asset-loader: cache hit', cacheId);
      return cacheEntry;
    } else if (this.force) {
      const el = this.loadedById() || this.loadedByUrl();

      if (el) {
        const promise = Promise.resolve(el);
        if (cacheId) { cache[cacheId] = promise }
        return promise;
      }
    }

    this.buildEl();
    const resolver = this.url ? this.load : this.exec;
    const promise = resolver.apply(this);
    if (this.cache && cacheId) {
      cache[cacheId] = promise;
    }
    return promise;
  }

  loadedById() {
    const asset = this.id && document.getElementById(this.id);
    if (asset && !asset.assetLoader) {
      console.warn('asset-loader: Already loaded with id:', this.id);
      return asset;
    }
  }

  loadedByUrl() {
    const asset = this.url && document.querySelector('script[src=' + this.url + ']');
    if (asset && !asset.assetLoader) {
      console.warn('asset-loader: Already loaded with url:', this.url);
      return asset;
    }
  }

  load() {
    return new Promise((resolve, reject) => {
      // Handle Script loading
      let done = false;
      // Attach handlers for all browsers.
      //
      // References:
      // http://stackoverflow.com/questions/4845762/onload-handler-for-script-tag-in-internet-explorer
      // http://stevesouders.com/efws/script-onload.php
      // https://www.html5rocks.com/en/tutorials/speed/script-loading/
      //
      this.el.onload = this.el.onreadystatechange = () => {
        if (!done && (!this.el.readyState ||
          this.el.readyState === 'loaded' ||
          this.el.readyState === 'complete')) {
          done = true;
          // Handle memory leak in IE
          this.el.onload = this.el.onreadystatechange = null;
          resolve(this.el);
        }
      };
      this.el.onerror = reject;
      this.parentEl.appendChild(this.el);
    });
  }

  exec() {
    this.parentEl.appendChild(this.el);
    return Promise.resolve(this.el);
  }
}

class Script extends Asset {
  constructor(type, options) {
    super('script', type, options);
  }

  buildEl() {
    super.buildEl();
    if (this.url) {
      this.el.src = this.url;
    }

    if (this.text) {
      this.el.text = this.text;
    }
  }
}

export function loadJs(items) {
  return items instanceof Array
    ? Promise.all(items.map(item => new Script('text/javascript', item).promise()))
    : new Script('text/javascript', items).promise();
}
