/**
 * Vue plugin for createI18n
 *
 * @param Vue
 */
export default (Vue) => {
  Vue.mixin({
    beforeCreate() {
      if (this.$options.i18n) {
        this.$i18n = this.__ = this.$options.i18n;
      } else if (this.$options.parent && this.$options.parent.$i18n) {
        this.$i18n = this.__ = this.$options.parent.$i18n;
      }
    }
  });
};
