<template>
  <div class="card-text">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'card-text'
};
</script>
