<template>
  <div class="tw-front-bar">
    <div id="tw-front-bar"></div>
  </div>
</template>

<script>
import { AssetMixin, EmbedMixin } from 'global-mixin';

export default {
  name: 'markets-ticker',
  mixins: [AssetMixin, EmbedMixin],
  embed(head) {
    head.script('https://twagg.azurewebsites.net/widgets/frontbar/frontbar-1.js');
  },
  async assets() {
    var checkTwPulse = setInterval(() => {
      if (window.TwPulse && window.TwPulse.frontBar) {
        window.TwPulse.frontBar('tw-front-bar');
        // TwPulse might need around 1sec to mount
        setTimeout(() => this.$emit('mount'), 1000);
        clearInterval(checkTwPulse);
      } else {
        console.log('TwPulse.frontBar not loaded yet ~');
      }
    }, 100);
  }
};
</script>
