<template>
  <div
    v-if="info.items && info.items.length"
    class="in-brief-block pt-3 pr-3 pl-3 mb-3">
    <row>
      <column xs="12">
        <h2 class="list-header ff-flama fs-lg tt-uppercase">
          In Brief
        </h2>
      </column>
    </row>
    <ul class="unstyled-list row">
      <li
        v-for="{ title, canonicalUrl } in info.items.slice(0, 6)"
        :key="canonicalUrl"
        class="col-12 col-sm-6 mb-0 d-flex">
        <h2 class="ff-flama fs-md fw-medium pb-3 pt-3 mb-0 border-bottom flex-grow-1">
          <a
            :href="canonicalUrl"
            class="text-reset">
            {{ title }}
          </a>
        </h2>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'inbrief-placeholder',
  data() {
    return {
      info: this.$store.state.edition.placeholders
        .find(p => p.type === 'in_brief') || {}
    };
  }
};
</script>
