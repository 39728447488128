/**
Add Recaptcha component in any page. It will call 'handleRecaptcha' method when captcha is solved and fire 'gRtoken' event with token as payload,
It will call 'resetRecaptcha' method when token is invalidated(token will be invalid after every 2 mins), this.googleRecaptcha.reset() resets the recaptcha widget (fyi: it
doesnt expire the token). Using the events 'gRtoken' and its payload we have verified token from server side implemented with a middleware
implemented in 'server/middlewares/recaptchaVerifier.js'. Finally we add the middleware to the routes that need recaptcha verification.
*/
// TODO reset with widgetId , this.googleRecaptcha.reset() will always reset the first recaptcha widget in the page(it will be required when there are multiple recaptcha widgets in one page).
<template>
  <div
    v-if="isClient"
    :data-sitekey="recaptchaSiteKey"
    :data-size="widgetSize"
    data-callback="handleRecaptcha"
    data-expired-callback="resetRecaptcha"
    class="g-recaptcha"></div>
</template>

<script>
import { AssetMixin } from 'global-mixin';

export default {
  name: 'recaptcha',
  mixins: [AssetMixin],
  data() {
    return {
      recaptchaSiteKey: '',
      isClient: false
    };
  },
  computed: {
    widgetSize() {
      return 'normal';
    }
  },
  mounted() {
    this.isClient = true;
    this.recaptchaSiteKey = window.dconf.recaptchaSiteKey;
  },
  methods: {
    async handleRecaptcha(token) {
      this.$emit('gRtoken', token);
    },
    async resetRecaptcha() {
      await window.grecaptcha.enterprise.reset();
      this.$emit('gRtokenExpired');
    },
  },
  assets() {
    window.handleRecaptcha = this.handleRecaptcha;
    window.resetRecaptcha = this.resetRecaptcha;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://www.google.com/recaptcha/enterprise.js?hl=${this.$pubCtx.language}`;
    script.setAttribute('async', true);
    script.setAttribute('defer', true);
    document.head.appendChild(script);
  }
};
</script>
