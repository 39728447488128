<template>
  <row>
    <column md="12">
      <DefaultTopics/>
      <DefaultAuthors/>
    </column>
  </row>
</template>
<script>
import DefaultTopics from './DefaultTopics';
import DefaultAuthors from './DefaultAuthors';

export default {
  name: 'default-subscriptions',
  components: {
    DefaultTopics,
    DefaultAuthors
  }
};
</script>
