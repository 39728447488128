import he from 'he';
import striptags from 'striptags';
import GDate from './GDate';
import { notificationMap } from 'global-configs/alerts';
import { moment } from './momentInitializer';
import html2Text from 'html-to-text';
import URI from 'urijs';
export function overridePrototypeOf(target, withType) {
  Object.setPrototypeOf(target, withType.prototype);
  target.name = target.constructor.name;
}

export function isFunction(obj) {
  return typeof obj === 'function';
}
export const checkIfSponsoredArticle = (type) => type === 'sponsoredarticle';

export const checkIfFrontArticle = (type) => type === 'frontarticle';
export const checkIfNewsletterIntroArticle = (type) => type === 'newsletterIntroArticle';

export function isGetter(name, proto) {
  return isFunction((Object.getOwnPropertyDescriptor(proto, name) || {}).get);
}

export function findGetters(obj) {
  const getters = new Set();
  let proto = obj;
  while (proto instanceof Object) {
    Object.getOwnPropertyNames(proto)
      .filter((name) => isGetter(name, proto))
      .forEach((name) => getters.add(name));
    proto = Object.getPrototypeOf(proto);
  }
  return Array.from(getters);
}

export function getterAwareJSON(source) {
  const json = { ...source };
  let proto = source;
  while (proto instanceof Object) {
    Object.entries(Object.getOwnPropertyDescriptors(proto))
      .filter(([name, descriptor]) =>
        typeof descriptor.get === 'function' &&
        !name.startsWith('_') && /* skip private field (starts with '_') */
        !json[name] /* skip overloaded getter */
      ).forEach(([name, descriptor]) => {
        try {
          json[name] = descriptor.get.call(source);
        } catch (error) {
          console.error(`Error calling getter ${name}`, error);
        }
      });
    proto = Object.getPrototypeOf(proto);
  }
  return json;
}

export function getDefault(defaultValue) {
  if (defaultValue instanceof Error) throw defaultValue;
  return (typeof defaultValue === 'undefined') || defaultValue === null ? '' : defaultValue;
}

export function getOrDefault(value, defaultValue) {
  return value || getDefault(defaultValue);
}

export function getValueOrDefault(obj, defaultValue) {
  return obj && obj.value ? obj.value : getDefault(defaultValue);
}

export function isValidOffset(offset) {
  return (!(isNaN(offset) || offset < 0));
}

export function safelyAssign(target, assignee) {
  return Object.assign({},
    (typeof target === 'undefined' || !target) ? {} : target,
    assignee);
}

export function extractTextFromHtml(htmlString) {
  if (!htmlString) return '';
  return htmlString.replace(/<[^>]*>/g, '') || '';
}

export function polyfillURL(url = '') {
  if (typeof window === 'object') {
    /**
     * Some browsers doesn't have `URL` type
     */
    const aLink = document.createElement('a');
    aLink.href = url;
    return aLink;
  }
  /**
   * For Node, use `URL` type instance
   */
  return url !== '' ? new URL(url) : url;
}

export function resolveUrl(url = '', callee = '') {
  try {
    if (typeof url !== 'string' || url.length === 0) return;
    const urlObj = polyfillURL(url);
    return urlObj.pathname + urlObj.search;
  } catch (e) {
    console.error(`Malfunction URL: ${url}, for ${callee}`);
  }
}

export function _isNil(obj) {
  return typeof obj === 'undefined' || obj === null;
}

export function clientConfig(req) {
  return {
    CDN: dconf.CDN,
    Solr: dconf.Solr,
    assetsManifest: global.assetsManifest,
    alertFetchConf: dconf.get('GlobalFetch.alerts'),
    lanternAPI: dconf.get('GlobalFetch.lantern'),
    disableNorkonTicker: process.env.DISABLE_NORKON_TICKER,
    recaptchaSiteKey: dconf.get('GOOGLE_RECAPTCHA.siteKey')
  };
}

export function decodeTitle(encodedTitle) {
  return he.decode(encodedTitle, { useNamedReferences: true }) || '';
}

export function getAllPublicationsCtxArray(allPubContext = {}) {
  return Object.entries(allPubContext).map(([, ctx]) => ctx);
}

export function titleCase(text) {
  return text.replace(/_/gi, ' ').replace(/-/gi, ' ').replace(/(æ|Æ|ø|Ø|å|Å|\w)\S*/g, function (s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  });
}

export function isShowingError(errorCtx) {
  return (errorCtx && !_isNil(errorCtx.type));
}

const appEnvSubDomainMap = {
  dev: 'dev',
  test: 'test',
  stage: 'stage',
  prod: 'www',
};
export function getDomainByPublication(publication = {}) {
  return `${appEnvSubDomainMap[appEnv]}.${publication.domain}`;
}

export function dateByPublication(
  { locale, language } = {},
  date = Date.now(),
  {
    format,
    includeZone = undefined,
    excludeTime = false
  } = {}
) {
  let formatAs = format;
  let zone = 'GMT';
  if (format) {
    includeZone = true;
  } else {
    locale = locale || language;
    if (!locale) {
      throw new Error(`No locale found in date formatter.`);
    }
    formatAs = 'D MMMM YYYY';
    if (locale === 'no') {
      formatAs = 'D. MMMM YYYY';
      zone = 'CET';
    }
    if (!excludeTime) {
      formatAs += ' H:mm';
    }
    if (includeZone === undefined) {
      includeZone = !excludeTime;
    }
  }
  return new GDate({ date, locale, zone, formatAs, includeZone });
}

export function checkUserLoginCookie(vm) {
  return !isIPLogin(vm) ? isUserLogin(vm) : false;
}

export function isIPLogin(vm) {
  return isServer
    ? vm.$store.state.cookies && vm.$store.state.cookies.IPLoginCookie !== undefined && vm.$store.state.cookies.IPLoginCookie !== null && vm.$store.state.cookies.IPLoginCookie !== 'guest'
    : vm.$cookies.isKey('IPLoginCookie') && vm.$cookies.get('IPLoginCookie') !== 'guest';
}

export function isUserLogin(vm) {
  return isServer
    ? vm.$store.state.cookies && vm.$store.state.cookies.oneid_info !== undefined && vm.$store.state.cookies.oneid_info !== null
    : vm.$cookies.isKey('oneid_info');
}

export function checkUserEmailVerificationCookie(vm) {
  return vm.$cookies.isKey('UserEmailVerificationCookie') &&
    vm.$cookies.get('UserEmailVerificationCookie') === 'enable';
}
export function removeUserEmailVerificationCookie(vm) {
  return vm.$cookies.isKey('UserEmailVerificationCookie') &&
    vm.$cookies.remove('UserEmailVerificationCookie');
}

export function shouldShowUserEmailVerification(vm) {
  return vm.$pubCtx.enableUserEmailVerification && checkUserLoginCookie(vm) && checkUserEmailVerificationCookie(vm);
}

export function getUrlWithProtocol(url) {
  return (!url) ? '' : url.startsWith('http') ? url : `https://${url}`;
}
export function getUrl(url) {
  if (url instanceof URL) {
    return url;
  }
  return new URL(url);
}
export function isSpecialPage(pageContext) {
  const specialPageContext = ['twthirtyyears'];
  return specialPageContext.includes(pageContext.type);
}

export function buildNotificationObject(selectedAlertOption) {
  const notificationObj = notificationMap[selectedAlertOption.selectedOption];
  if (selectedAlertOption.selectedOption === 'daily') {
    notificationObj.time = selectedAlertOption.selectedTime;
  } else if (selectedAlertOption.selectedOption === 'weekly') {
    notificationObj.day = selectedAlertOption.selectedDay;
    notificationObj.time = selectedAlertOption.selectedTime;
  }

  return notificationObj;
}

export const stripTags = (html) => striptags(unescape(html), [], '');

export const snakeCaseUtf8 = (string) => {
  return string.toLowerCase().split(' ').join('_');
};

export function convertGMTToCETDayAndHour(day = 'monday', hour = 23) {
  const mem = moment().utc().day(day).hour(hour);
  return {
    day: mem.clone().tz('CET').format('dddd'),
    hour: mem.clone().tz('CET').hour()
  };
}

export function convertCETToGMTDayAndHour(day = 'monday', hour = 23) {
  moment.tz.setDefault('CET');
  moment.locale('no');
  const mem = moment().day(day).hour(hour);
  return {
    day: mem.utc().format('dddd'),
    hour: mem.utc().hour()
  };
}

export function isPreview(pageContextType, query) {
  /**
   * checking if the current route is  an articlePreviewPage or an EditionPage or not
   */
  return pageContextType === 'articlePreviewPage' || isEditionPreview(query);
}

export function isEditionPreview(query) {
  /**
   * checking if an edition is preview or not. Edition is preview if and only if the query parameter editionid or editionId exists
   */
  return !!query.editionId || !!query.editionid;
}

export function replaceMultipleSpacesWithSingleSpace(string) {
  return (string.replace(/\s\s+/g, ' ')).trim();
}

export function htmlToString(html) {
  return html2Text.fromString(html);
}

export function getDomainFromUrl(url) {
  return new URI(url).domain();
}

export function parseOneIdInfoCookie(oneIdInfoCookie) {
  const splits = oneIdInfoCookie.split('|');
  const oneIdInfo = {};
  oneIdInfo.sessionId = splits[1];
  oneIdInfo.oneId = splits[2];
  oneIdInfo.username = splits[3];
  oneIdInfo.userId = splits[4];
  oneIdInfo.firstName = splits[5];
  oneIdInfo.lastName = splits[6];
  oneIdInfo.products = splits[7] === '' ? [] : splits[7].split(',');
  return oneIdInfo;
}

export function getSubscriptionId(oneIdInfo) {
  return oneIdInfo.userId;
}

export function isUserSubscribed(vm) {
  return checkUserLoginCookie(vm) && vm.$cookies.isKey('oneid_info') &&
    parseOneIdInfoCookie(atob(vm.$cookies.get('oneid_info'))).products.length > 0;
}

export function getSubscriptionType(oneIdInfo) {
  return oneIdInfo.products && oneIdInfo.products.length > 0 ? 'Subscriber' : 'Free';
}
