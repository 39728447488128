<template>
  <Modal
    @close="$emit('close')"
    class="ff-flama">
    <template v-slot:header>
      <div class="alert-header">
        {{ __('pause.alerts') }}
      </div>
    </template>
    <template v-slot:body>
      <div>
        <p class="ff-flama fw-light">{{ __('stope.email.push.notification') }}</p>
        <p class="ff-flama fw-light pb-3">{{ __('reactivate.notification.later') }}</p>
        <button
          @click="$emit('confirm')"
          class="continue btn btn-primary w-100 mb-3">
          {{ __('btn.yes') }}
        </button>
        <button
          @click="$emit('cancel')"
          class="continue btn btn-secondary w-100">
          {{ __('btn.no') }}
        </button>
      </div>
    </template>
  </Modal>
</template>
<script>
export default {
  name: 'alert-pause-modal',
};
</script>
<style>
.modal-container {
  max-width: 400px;
}
</style>
