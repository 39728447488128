const mutations = {
  content(state, { facets, articles }) {
    state.content = { facets, articles };
  },
  query(state, query) {
    state.query = {
      ...state.query,
      ...query,
    };
  },
  page(state, { offset, size, total }) {
    state.query.offset = +offset;
    if (size) {
      state.query.size = +size;
    }
    if (total) {
      state.query.total = +total;
    }
  },
};

const getters = {
  facets(state) {
    return state.facets;
  },
  articles(state) {
    return state.articles;
  }
};

export default {
  namespaced: true,
  state: {
    content: {
      facets: [],
      articles: [],
    },
    query: {
      q: undefined,
      sort: undefined,
      mode: undefined,
      offset: 0,
      size: 0,
      total: 0,
    },
  },
  getters,
  mutations
};
