<template>
  <div class="alert-sub-settings">
    <div class="d-flex justify-content-between">
      <div>
        <div class="main-category ff-flama fs-md fw-medium pb-3">
          <a
            :href="`${topicLink}`"
            target="_blank">{{ settingsElement.label }}</a>
        </div>
        <div
          v-if="!showOptions"
          class="d-flex ff-flama-book fs-xs fw-light text-capitalize">
          <div
            class="notification mr-2 fw-bold">
            {{ __(resolveNotificationLabel(settingsElement.notification.type)) }}
          </div>
          <div
            v-if="settingsElement.notification.interval"
            class="notification mr-2">
            {{ __('alert.option.'+ settingsElement.notification.interval.toLowerCase()) }}
          </div>
          <div
            v-if="settingsElement.notification.day"
            class="notification mr-2">
            {{ __(subscriptionDay.toLowerCase()) }}
          </div>
          <div
            v-if="settingsElement.notification.time"
            class="notification mr-2">
            {{ subscriptionTime }} {{ $pubCtx.timeZone }}
          </div>
        </div>
      </div>
      <div class="my-auto">
        <button
          v-if="!showOptions"
          @click="showOptions = true"
          class="edit btn btn-sm btn-outline-primary float-right">
          {{ __('edit') }}
        </button>
        <button
          v-if="showOptions"
          @click="showOptions = false"
          class="btn btn-sm float-right fs-lg">
          <FontAwesomeIcon
            :icon="closeIcon"
            width="30"/>
        </button>
      </div>
    </div>
    <!-- AlertOptionChooser.selectedTime needs to be a truthy value, so sending Number 0 will make AlertOptionChooser pick it`s default time -->
    <div v-if="showOptions">
      <AlertOptionChooser
        @edit="onEdit"
        @confirm="updateSubscription"
        @confirmError="handleConfirmError"
        @delete="deleteSubscription"
        @deleteError="handleDeleteError"
        :edit-mode="true"
        :items="[settingsElement]"
        :subs-options="{
          notificationType: settingsElement.notification.type,
          selectedOption: settingsElement.notification.interval
            ? settingsElement.notification.interval.toLowerCase()
            : 'never',
          selectedDay: settingsElement.notification.day ? subscriptionDay.toLowerCase() : '',
          selectedTime: settingsElement.notification.time ? `${subscriptionTime}` : ''
        }"
        :sub-type="settingsElement.subType || termType"
        class="medium"/>
    </div>
    <AlertActionStatus
      v-show="showActionStatus"
      :key="actionKind + actionSuccess"
      :action="actionKind"
      :success="actionSuccess"
      @close="onContinueOrClose"
      @continue="onContinueOrClose"/>
  </div>
</template>
<script>
import AlertActionStatus from './AlertActionStatus';
import AlertOptionChooser from './AlertOptionChooser';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { convertGMTToCETDayAndHour } from 'global-utils';

export default {
  name: 'subscription-settings',
  components: {
    AlertActionStatus,
    AlertOptionChooser,
    FontAwesomeIcon
  },
  props: {
    settingsElement: {
      type: Object,
      required: true
    },
    termType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showActionStatus: false,
      actionKind: 'edit',
      actionSuccess: false,
      showOptions: false,
      items: [],
      closeIcon: faTimes,
      topicLink: (this.termType === 'authors')
        ? `/author/${this.settingsElement.term}`
        : ((this.settingsElement.subType === 'tag') ? `/tag/${this.settingsElement.term}` : `/${this.settingsElement.term}`),
    };
  },
  computed: {
    subscriptionDay() {
      return this.subscriptionDayAndTime.day;
    },
    subscriptionTime() {
      return this.subscriptionDayAndTime.time;
    },
    subscriptionDayAndTime() {
      if (this.$pubCtx.timeZone === 'GMT') {
        return this.settingsElement.notification;
      }
      const { day, hour: time } = convertGMTToCETDayAndHour(this.settingsElement.notification.day, this.settingsElement.notification.time);
      return { day, time };
    }
  },
  methods: {
    updateSubscription() {
      this.showOptions = false;
    },
    handleConfirmError(err) {
      console.error('something went wrong updating: ', err);
      this.showOptions = false;
    },
    deleteSubscription() {
      this.actionKind = 'delete';
      this.actionSuccess = true;
      this.showActionStatus = true;
    },
    handleDeleteError(err) {
      console.error('something went wrong deleting: ', err);
      this.actionKind = 'delete';
      this.actionSuccess = false;
      this.showActionStatus = true;
    },
    onEdit({ error }) {
      this.actionKind = 'edit';
      this.actionSuccess = !error;
      this.showActionStatus = true;
    },
    onContinueOrClose() {
      this.showActionStatus = false;
      this.showOptions = false;
    },
    resolveNotificationLabel(notificationType) {
      switch (notificationType) {
      case 'PUSH':
        return 'alert.notification.type.push';
      case 'EMAIL':
        return 'alert.notification.type.email';
      case 'PUSH_EMAIL':
        return 'alert.notification.type.push_email';
      default:
        return 'alert.notification.type.no_alert';
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.alert-page .settings-elem .notification:not(:first-child) {
  border-left: .1rem solid #e0e2e1 !important;
  padding-left: 5px;
}
.btn.edit {
  border-radius: .3rem;
  border: .1rem solid;
  padding: 3px 10px;
}
.alert-sub-settings {
  a {
    @include color('primary');
  }
  .btn-outline-primary {
    @include color('primary');
    @include borderColor('primary');
  }
  .btn-outline-primary:hover {
    @include backgroundColor('primary');
    @include borderColor('primary');
    color: $white;
  }
}
</style>
