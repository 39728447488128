<template>
  <p
    :class="[
      $options.name,
      {[`${styleClass}`]:styleClass}]"
    v-html="leadText">
  </p>
</template>

<script>
import { extractTextFromHtml } from 'global-utils';
export default {
  name: 'article-lead-text',
  props: {
    styleClass: String
  },
  data() {
    return {
      leadText:
        (typeof this.$store.state.article.leadText !== 'undefined' && !(this.$route.meta.pageContext.type === 'infoArticle'))
          ? extractTextFromHtml(this.$store.state.article.leadText)
          : ''
    };
  }
};
</script>
