<template>
  <div
    v-if="!facet.disabled && facet.options && facet.options.length"
    :key="facet.name"
    :class="`mb-5 facet ${expandFacet && 'open'}`">
    <h3
      @click="expandFacet = !expandFacet"
      v-text="i18nOrDefault(facet.label)"
      class="ff-flama fs-lg fw-medium mb-3 facet-toggle text-capitalize"/>
    <ul class="unstyled-list">
      <slot
        :facetName="facet.name"
        name="prependOption"/>
      <template
        v-for="(option, i) in facet.options.filter(o => o.count > 0).slice(0, 10)">
        <li
          v-show="i < 5 || showAllOption"
          :key="option.name"
          class="form-check mb-2">
          <input
            v-if="facet.multiSelect"
            :id="option.value"
            v-model="query[facet.name]"
            :value="option.value"
            @change="update"
            class="form-check-input"
            type="checkbox">
          <input
            v-else
            :id="option.value"
            v-model="query[facet.name]"
            :name="facet.name"
            :value="option.value"
            @change="update"
            class="form-check-input"
            type="radio">
          <label
            :for="option.value"
            class="form-check-label fs-md pl-3">
            {{ labelFilter(option.label) }} ({{ option.count.toLocaleString('nb-NO') }})
          </label>
        </li>
      </template>
      <slot
        :facetName="facet.name"
        name="appendOption"/>
      <li
        v-if="facet.options.length > 5"
        @click="showAllOption = !showAllOption">
        <button
          v-text="!showAllOption
            ? __('see.all', i18nOrDefault(facet.label).toLowerCase())
            : __('see.less', i18nOrDefault(facet.label).toLowerCase())"
          class="ff-flama fs-sm fw-medium btn-link"/>
      </li>
    </ul>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('search');
export default {
  name: 'archive-facet',
  props: {
    facet: {
      type: Object,
      required: true,
    },
    labelFilter: {
      type: Function,
      default: (val) => (val),
    },
  },
  data() {
    return {
      expandFacet: true,
      showAllOption: false,
    };
  },
  computed: {
    ...mapState({
      query(state) {
        return state.query;
      },
    }),
  },
  methods: {
    update() {
      this.$emit('update');
    },
    i18nOrDefault(text) {
      return this.$i18n(text.toLowerCase()) || text;
    },
  },
};
</script>
