import * as allPubMetadata from './metadata';
import { defaultMetadata, defaultMetadataByRoute } from './metadata/_default';
import errorPageMetadata from './errorPageMetadata';
import { updateOgUrl } from './metadata/helper';

const metadataByPublications = {};
function prepareMetadata(ssrCtx, pubCtx) {
  const pubName = pubCtx.name;
  const metadataByRoutes = mergeEachPageMetadata(ssrCtx.state.routes, allPubMetadata[pubName]);
  return Object.entries(metadataByRoutes).reduce((_metadataByRoutes, [routeName, _metadata]) => {
    const metadata = { ..._metadata };
    Object.entries(metadata).forEach(([prop, valOrFn]) => {
      metadata[prop] = typeof valOrFn === 'function' ? valOrFn({ metadata, pubCtx }) : valOrFn;
    });
    _metadataByRoutes[routeName] = metadata;
    return _metadataByRoutes;
  }, {});
}

function mergeEachPageMetadata(routesByName, { publicationDefaultMetadata, metadataByRoute }) {
  return Object.keys(routesByName).reduce((cum, routeName) => {
    cum[routeName] = {
      ...defaultMetadata,
      ...publicationDefaultMetadata || {},
      ...defaultMetadataByRoute[routeName],
      ...metadataByRoute[routeName],
    };
    return cum;
  }, {});
}

export function buildMetadata(route, store, ssrCtx) {
  const pubCtx = store.state.publication;
  const metadataByRoutes = getMetadataByPublication(ssrCtx);
  const metadata = { ...metadataByRoutes[route.name] };
  Object.entries(metadata).forEach(([prop, valOrFn]) => {
    metadata[prop] = typeof valOrFn === 'function' ? valOrFn({ metadata, pubCtx, route, store }) : valOrFn;
  }, {});

  if (metadata.ogType === 'article' && ssrCtx.req.variantQueryParamValue) {
    updateOgUrl(metadata, ssrCtx);
  }
  store.state.page.meta = metadata;
}

function getMetadataByPublication(ssrCtx) {
  const pubCtx = ssrCtx.publication;
  if (!metadataByPublications[pubCtx.name]) {
    metadataByPublications[pubCtx.name] = prepareMetadata(ssrCtx, pubCtx);
  }
  return metadataByPublications[pubCtx.name];
}

export function buildErrorPageMetadata(errCtx, store) {
  store.state.page.meta = errorPageMetadata[errCtx.type];
}

export function mapMetadata(
  {
    title,
    shareTitle,
    description,
    ogUrl,
    imageSource,
    ogImage,
    ogType,
    ogSiteName,
    fbAppId,
    twitterCard,
    twitterImage,
    twitterUrl,
    twitterSite
  }) {
  return [
    { name: 'title', content: title },
    { name: 'description', content: description },
    { property: 'og:title', prefix: 'og: http://ogp.me/ns#', content: shareTitle || title },
    { property: 'og:description', prefix: 'og: http://ogp.me/ns#', content: description },
    { property: 'og:url', prefix: 'og: http://ogp.me/ns#', content: ogUrl },
    { property: 'og:image', prefix: 'og: http://ogp.me/ns#', content: imageSource || ogImage },
    { property: 'og:type', prefix: 'og: http://ogp.me/ns#', content: ogType || '' },
    { property: 'og:site_name', prefix: 'og: http://ogp.me/ns#', content: ogSiteName },
    { property: 'fb:app_id', content: fbAppId },
    { name: 'twitter:card', content: twitterCard },
    { name: 'twitter:title', content: shareTitle || title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: imageSource || twitterImage },
    { name: 'twitter:url', content: twitterUrl },
    { name: 'twitter:site', content: twitterSite },
    { name: 'twitter:creator', content: twitterSite },
    { name: 'referer', content: 'origin' },
  ];
}
