<template>
  <NewsList
    :article-list="latestArticles" />
</template>

<script>
import NewsList from './NewsList';

export default {
  name: 'latest-news-list',
  dataResolver: 'latestArticles',
  components: {
    NewsList
  },
  data() {
    return {
      latestArticles: this.$store.state.latestArticles,
    };
  }
};
</script>
