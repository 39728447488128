module.exports = {
  immediate: {
    interval: 'immediate'
  },
  daily: {
    interval: 'daily',
    time: ''
  },
  weekly: {
    interval: 'weekly',
    day: '',
    time: ''
  },
  never: {
  }
};
