<template>
  <div
    v-if="serverName !== 'Prod'"
    v-show="!versionInfoNotified"
    class="version-sticky">
    <div
      @click="closeVersionInfo"
      class="close-button float-right fs-md"
      style="cursor: pointer;">
      <FontAwesomeIcon
        :icon="closeIcon"
        width="20"/>
    </div>
    <div class="float-left">
      <span><strong>{{ serverName }} Server</strong></span>
      |
      <span>
        <a
          :href="buildUrl"
          target="_blank"
          style="text-decoration: underline;">
          <strong>Branch: </strong> {{ branch }}
        </a>
      </span>
      |
      <span>Deployed By: <strong>{{ userName }}</strong></span>
      |
      <span><strong>{{ buildTime }}</strong></span>
    </div>
  </div>
</template>

<script>
import { titleCase } from 'global-utils';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';

export default {
  name: 'version-sticky',
  dataResolver: 'versionSticky',
  components: {
    FontAwesomeIcon
  },
  data() {
    const appVersionInfo = this.$store.state.appVersionInfo;
    let branch = '';
    let userName = '';
    let buildUrl = '';
    let buildNumber = '';
    let buildTime = '';

    if (appVersionInfo && appVersionInfo.actions) {
      appVersionInfo.actions.map(action => {
        if (action && action._class &&
          (action._class === 'hudson.plugins.git.util.BuildData') &&
          action.lastBuiltRevision &&
          action.lastBuiltRevision.branch &&
          action.lastBuiltRevision.branch.length > 0) {
          const name = action.lastBuiltRevision.branch[0].name || '';
          const tokens = name.split('/');
          branch = tokens[tokens.length - 1] || '';
        }

        if (action && action._class &&
          (action._class === 'hudson.model.CauseAction') &&
          action.causes &&
          action.causes.length > 0) {
          userName = action.causes[0].userName || '';
        }
      });
      buildUrl = appVersionInfo.url;
      buildNumber = appVersionInfo.number;
      buildTime = new Date(appVersionInfo.timestamp).toUTCString();
    }

    return {
      show: this.serverName !== 'Prod' && !devMode,
      branch: branch,
      userName: userName,
      buildUrl: buildUrl,
      buildNumber: buildNumber,
      buildTime: buildTime,
      serverName: titleCase(this.$store.state.serverName),
      closeIcon: faTimesCircle,
      versionInfoNotified: false
    };
  },
  mounted() {
    var epochRightNow = Math.floor(new Date().getTime() / 1000);
    if (localStorage.getItem('versionInfoNotified')) {
      if (epochRightNow > localStorage.getItem('versionInfoNotified')) {
        this.versionInfoNotified = false;
        localStorage.removeItem('versionInfoNotified');
      } else {
        this.versionInfoNotified = true;
      }
    }
    console.log(`${this.serverName} Server
      Branch: ${this.branch} | ${this.buildUrl}
      Deployed By: ${this.userName}
      Deployed At: ${this.buildTime}
    `);
  },
  methods: {
    closeVersionInfo() {
      var epochRightNow = Math.floor(new Date().getTime() / 1000);
      var epochAfterThreeHours = epochRightNow + 10800;
      localStorage.setItem('versionInfoNotified', epochAfterThreeHours);
      this.versionInfoNotified = true;
    },
  },
};
</script>

<style>
  .version-sticky {
    background-color: #e1eef9;
    padding: 5px;
    color: #005288;
    width: 100%;
    bottom: 10px;
    left: 0px;
    position: fixed;
    z-index: 2000;
    font-size: 11px;
    border: 1px dashed #005288;
  }
</style>
