function resolveWithArguments(msg, args) {
  const chunks = msg.split(new RegExp(/\${([^}]+)}/g));
  return chunks.map(c => args[c] || c).join('');
}

function resolveMessage(msg, args) {
  args = args || {};
  return resolveWithArguments(msg, args);
}

class I18n {
  constructor(messages) {
    this.messages = messages;
  }

  messageFromKey(key, ...args) {
    const msg = this.messages[key];
    return !msg ? '' : resolveMessage(msg, args);
  }
}

/**
 * createI18n factory function
 */
export default function (messages) {
  const i18n = new I18n(messages);
  return i18n.messageFromKey.bind(i18n);
}
