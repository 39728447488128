<template>
  <Component
    :is="menuItem"
    v-if="!$pubCtx.isFreePublication && isUserLoggedIn"
    class="user"/>
</template>
<script>
import UserLoginMenuItem from './UserLoginMenuItem';
import UserLogoutMenuItem from './UserLogoutMenuItem';
import { checkUserLoginCookie } from 'global-utils';

export default {
  name: 'user-menu-item',
  components: {
    /* eslint-disable vue/no-unused-components */
    UserLoginMenuItem,
    UserLogoutMenuItem,
    /* eslint-enable vue/no-unused-components */
  },
  data() {
    return {
      isUserLoggedIn: checkUserLoginCookie(this),
      UserLoginMenuItem,
      UserLogoutMenuItem,
    };
  },
  computed: {
    menuItem() {
      return this.isUserLoggedIn ? UserLogoutMenuItem : UserLoginMenuItem;
    }
  },
  mounted() {
    this.isUserLoggedIn = checkUserLoginCookie(this);
  },
};
</script>

<style lang="scss">
  .menu-item.user {
    max-width: 347px !important;
  }
</style>
