<template>
  <FontAwesomeIcon
    v-if="showTeaserIcon"
    :icon="faPlusCircle"
    :class="styleClass"/>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
export default {
  name: 'teaser-icon',
  components: { FontAwesomeIcon },
  props: {
    styleClass: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      faPlusCircle,
    };
  },
  computed: {
    showTeaserIcon() {
      return this.$pubCtx.showTeaserPaidArticleIcon;
    }
  }
};
</script>
