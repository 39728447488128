export const imageSourceResolver = (article, imageVersion = 720) => {
  const image = {};
  const leadAssetType = article.leadAsset && article.leadAsset.type;
  if (leadAssetType === 'lead-image') {
    image.imageSource = article.leadAsset.imageSource;
    image.caption = article.leadAsset.imageCredit.caption || '';
  } else if (leadAssetType === 'lead-picture-gallery') {
    image.imageSource = article.leadAsset.leadImages[0].imageSource;
    image.caption = article.leadAsset.leadImages[0].imageCredit.caption || '';
  } else {
    const iEle = article.inlineElements && Object.entries(article.inlineElements)
      .map(([, value]) => value)
      .find(ele => ele.imageSource !== undefined);
    image.imageSource = iEle && iEle.imageSource;
    image.caption = (iEle && iEle.caption) || '';
  }
  image.imageSource = (image.imageSource && `${image.imageSource}?image_version=${imageVersion}`) || '';
  return image;
};
