export const publicationDefaultMetadata = {
  siteTitle: 'Kystens Næringsliv',
  siteLabel: 'Kystens Næringsliv | Vi skaper kysten sammen!',
  description: 'Vi gir deg nyheter fra kysten rundt'
};

export const metadataByRoute = {
  LatestNews: {
    description: '',
  }
};
