export const gtagScriptHead = vm => {
  if (!vm.$store.state.gtagCode) {
    return ``;
  }
  return `<!-- Global site tag (gtag.js) - Google Analytics -->
  <script async defer src="https://www.googletagmanager.com/gtag/js?id=${vm.$store.state.gtagCode}"></script>`;
};

export const gtagScript = vm => {
  if (!vm.$store.state.gtagCode) {
    return ``;
  }
  return `<!-- Global site tag (gtag.js) - Google Analytics -->
  <script>
   window.dataLayer = window.dataLayer || [];
   function gtag(){dataLayer.push(arguments);}
   gtag('js', new Date());
  
   gtag('config', '${vm.$store.state.gtagCode}');
  </script>`;
};
