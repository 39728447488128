<template>
  <div class="norkon-ticker ff-flama fs-md">
    <div class="wrapper">
      <div class="py-3 ticker-carousel owl-carousel">
        <div
          v-for="product in tickerConfig.products"
          :key="product.symbol">
          <div v-if="tickerBuffer[product.symbol]">
            {{ product.name }}<br>
            <span
              v-if="tickerBuffer[product.symbol].value"
              class="col-blue">
              {{ formatPrice(tickerBuffer[product.symbol].value, product.currency, product.currencyPosition) }}
            </span>
            <span
              v-if="tickerBuffer[product.symbol].percent"
              :class="['fw-medium', tickerBuffer[product.symbol].percent < 0 ? 'negative' : 'positive']">
              {{ formatChange(tickerBuffer[product.symbol].percent) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AssetMixin, EmbedMixin } from 'global-mixin';
import { assignIn } from 'global-utils';
import { owlCarouselScripts } from 'assets/js/bundled-assets';

export default {
  name: 'norkon-ticker',
  mixins: [AssetMixin, EmbedMixin],
  props: {
    options: Object
  },
  data() {
    return {
      tickerConfig: require('global-configs/norkonTickerConfig.js'),
      tickerBuffer: {},
      streamer: null
    };
  },
  computed: {
    mergedOptions() {
      return Object.assign({}, this.$options.defaultOptions, this.options);
    }
  },
  defaultOptions: {
    center: false,
    dots: false,
    rewind: false,
    loop: true,
    responsive: {
      0: {
        items: 1.25
      },
      375: {
        items: 2.25
      },
      576: {
        items: 3.25
      },
      768: {
        items: 4.25
      },
      992: {
        items: 6
      }
    }
  },
  methods: {
    formatPrice(price, currency, currencyPosition) {
      if (currencyPosition === 'B') {
        return price.toFixed(2) + currency;
      } else {
        return currency + price.toFixed(2);
      }
    },
    formatChange(change) {
      var sign = '';
      if (change < 0) {
        sign = '▾ ';
      } else if (change > 0) {
        sign = '▴ +';
      } else {
        sign = '';
      }
      return sign + change.toFixed(2) + '%';
    }
  },
  embed(head) {
    head.script(this.$cdn.resource('lib/NcPulse-streaming-2.0.1-min.js'), { async: true, defer: true });
    head.style(this.$cdn.asset('owl.carousel.css'));
  },
  async assets() {
    const [$] = await Promise.all(owlCarouselScripts());
    this.streamer = new window.NcPulse.OverviewPricesStreamer(this.tickerConfig.streamURL);
    this.streamer.start((delta) => {
      var tempBuffer = assignIn({}, this.tickerBuffer);
      for (var key in delta) {
        tempBuffer[key] = delta[key];
      }
      this.tickerBuffer = tempBuffer;
      this.$nextTick(() => {
        $('.ticker-carousel').owlCarousel(this.mergedOptions);
      });
    });
  }
};
</script>
