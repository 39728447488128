export const publicationDefaultMetadata = {
  siteTitle: 'Nyheter om fiskeri og havbruk',
  description: 'Fiskeribladet tilbyr nyheter knyttet til fiskeri og havbruk. Vi følger også norsk sjømat ut i den store verden',
  twitterSite: '@fiskeribladet'
};

export const metadataByRoute = {
  LatestNews: {
    description: 'Les de viktigste nyhetene om fiskeri og havbruk.',
  },
  Order: {
    title: 'Bestill annonse | Fiskeribladet',
    description: 'Bestill annonse på Fiskeribladet',
  },
  OrderPrivat: {
    title: 'Bestill annonse | Privat | Fiskeribladet',
    description: 'Bestill annonse på Fiskeribladet',
  },
  OrderBedrift: {
    title: 'Bestill annonse | Bedrift | Fiskeribladet',
    description: 'Bestill annonse på Fiskeribladet',
  },
  Fiskeridebatt: {
    pageTitle({ metadata }) {
      return `Fiskeridebatt - Debatt i fiskeri og havbruk | ${metadata.siteLabel}`;
    }
  }
};
