import { isEmpty } from 'global-utils';
export default {
  props: {
    error: {
      type: Object,
    },
    fallback: {
      type: Object,
      default: () => {
        return {
          props: {
            err: Object
          },
          render(h) {
            return h('div', {
              style: {
                display: 'block'
              },
            }, this.err.message);
          }
        };
      }
    }
  },
  errorCaptured(err, vm, info) {
    logger.error(`Error captured in ErrorAware component ${err}`);
  },
  render(h) {
    if (isEmpty(this.$slots)) {
      logger.debug('ErrorAware component must have child components.');
      return null;
    }
    return this.error ? h(this.fallback, { props: { err: this.error } }) : this.$slots.default[0];
  }
};
