<template>
  <section
    v-if="Object.keys(info || {}).length"
    class="section_highlight mb-3 pt-4 pb-4">
    <div class="wrapper">
      <row>
        <column sm="12">
          <div class="mb-4 d-flex">
            <h2
              v-if="info.title"
              class="category-name ff-flama fs-xxl fw-light tt-uppercase mb-0 mr-4">
              {{ info.title }}
            </h2>
            <a
              v-if="info.link"
              :href="getSeeAllLink(info.link)"
              v-text="__('see.all.articles')"
              class="category-link ff-flama fs-xs fw-medium">
            </a>
          </div>
        </column>
      </row>
      <row>
        <column sm="12">
          <Carousel
            :options="$options.carouselOptions()"
            class="teaser-carousel">
            <teaser
              v-for="(item ,idx) in info.items.slice(0, 20)"
              :key="idx"
              :teaser-element="item"
              :show-main-category="false"
              teaser-type="carousel"/>
          </Carousel>
        </column>
      </row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'carousel-placeholder',
  props: {
    info: Object
  },
  methods: {
    getSeeAllLink(link) {
      if (link.startsWith('http')) {
        const publicationDomains = ['tradewindsnews.com', 'upstreamonline.com', 'intrafish.com',
          'rechargenews.com', 'intrafish.no', 'europower.no', 'tekfisk.no', 'fiskeribladet.no'];

        if (new RegExp(publicationDomains.join('|')).test(link)) {
          const subDomain = (global.appEnv === 'prod') ? 'www' : global.appEnv;
          return link.replace(/(dev.|test.|stage.|beta.|www.)/, `${subDomain}.`);
        }

        return link;
      }

      return `/${link}`;
    }
  },
  carouselOptions() {
    return {
      items: 4,
      loop: false,
      autoplay: false,
      rewind: true,
      responsive: {
        0: {
          items: 1.5,
          nav: true
        },
        576: {
          items: 1.5,
          nav: false
        },
        768: {
          items: 3,
          nav: true,
          loop: false
        },
        992: {
          items: 4,
          nav: true,
          loop: false
        },
        1272: {
          items: 4,
          nav: true,
          loop: false
        }
      }
    };
  }
};
</script>
