<template>
  <div class="dropdown-field">
    <label :for="label">{{ label }}</label>
    <select
      :id="label"
      @change="$emit('input', $event.target.value)"
      class="w-100">
      <option
        v-if="placeholder"
        value=""
        selected
        disabled>{{ placeholder }}</option>
      <option
        v-for="option in options"
        :key="option"
        :value="option">{{ option }}</option>
    </select>
    <div
      v-for="msg in messages"
      :key="msg"
      class="error message">{{ msg }}</div>
  </div>
</template>
<script>
export default {
  name: 'dropdown-field',
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true,
    },
    messages: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>
