module.exports = {
  fiskeribladet: {
    privat: [
      {
        id: 'liten-annonse',
        name: 'Liten annonse',
        note: '',
        indents: '6 avisinnrykk',
        overskritt: '32',
        tekst: '270',
        price: 'Kr 699,- inkl. mva',
        photo: ''
      },
      {
        id: 'mellomstor-annonse',
        name: 'Mellomstor annonse',
        note: '',
        indents: '6 innrykk',
        overskritt: '32',
        tekst: '650',
        price: 'Kr  899,- inkl. mva',
        photo: ''
      },
      {
        id: 'stor-annonse',
        name: 'Stor annonse',
        note: '',
        indents: '6 innrykk',
        overskritt: '32',
        tekst: '1300',
        price: 'Kr. 1290,- inkl. mva',
        photo: ''
      },
    ],
    bedrift: [
      {
        name: 'Ekstra synlighet',
        note: '',
        indents: '24 avisinnrykk',
        sizeLimit: '1 helt år med display annonse i karusell',
        price: 'Kr 19.900,- eks. mva',
        photo: ''
      },
      {
        name: 'Stor annonse',
        note: 'Papiravis og nett',
        indents: '6 avisinnrykk, Modul 12',
        sizeLimit: '30 dager nettannonse i karusell',
        price: 'Kr 9.800,- eks. mva',
        photo: ''
      },
      {
        name: 'Mellomstor annonse',
        note: 'Papiravis og nett',
        indents: '6 avisinnrykk, Modul 11',
        sizeLimit: '30 dager nettannonse i karusell',
        price: 'Kr 5.900,- eks. mva',
        photo: ''
      },
      {
        name: 'Liten annonse',
        note: 'Kun papiravis',
        indents: '6 avisinnrykk, Modul 11B',
        sizeLimit: 'Overskrift: 32 tegn \n Tekst: 270 tegn',
        price: 'Kr 2.590,- eks. mva',
        photo: ''
      }
    ]
  }
};
