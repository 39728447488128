<template>
  <div class="ff-flama fs-xs border-top border-bottom pt-4 pb-4 mb-3 d-print-none share">
    <span>{{ __('share') }}: </span>
    <span>
      <a
        @click.prevent="mail"
        class="fw-medium text-reset mail-to-link">
        {{ __('email') }}
      </a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'article-mail-to',
  props: {
    subject: {
      type: String,
      required: true
    },
    emailArticleUrl: {
      type: String,
      required: true
    },
    emailArticleBody: {
      type: String,
    },
    canonicalUrl: {
      type: String,
    }
  },
  data: function () {
    return {
    };
  },
  methods: {
    mailBody: function () {
      return `${this.canonicalUrl}`;
    },
    mail: function () {
      const mailUrl = 'mailto:?subject=' + this.subject + '&body=' + encodeURIComponent(this.mailBody());

      this.goTo(mailUrl, '_self');
    },
    goTo: function (url, target) {
      if (url) {
        if (target) {
          window.open(url, target);
        } else {
          window.open(url);
        }
      }
    }
  }
};
</script>

<style scoped>
.mail-to-link {
  cursor: alias;
}
</style>
