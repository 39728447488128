<template>
  <card-text
    v-if="showKicker && kickerText && !hideImage && imageSrc"
    :class="['kicker ff-flama fs-sm fw-bold m-2 px-1 py-1 tt-uppercase',
             publicationAwareKickerClasses(kickerClasses), getKickerStyle()]">
    {{ kickerText }}
  </card-text>
</template>

<script>
import { imageSource } from './teaserUtils';

export default {
  name: 'teaser-kicker',
  props: {
    showKicker: {
      type: Boolean,
      default: true
    },
    hideImage: {
      type: Boolean,
      default: false
    },
    kickerStyle: {
      type: String,
      default: ''
    },
    kickerText: {
      type: String,
      default: ''
    },
    leadAsset: {
      type: Object,
      required: true
    },
    articlePubName: {
      type: String,
      default: ''
    },
    isCrossPublication: {
      type: Boolean,
      default: false
    },
    kickerClasses: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imageSrc: imageSource(this.leadAsset)
    };
  },
  methods: {
    getKickerStyle() {
      return this.computeStyleAndClasses(this.kickerStyle);
    },
    publicationAwareKickerClasses(kickerClasses) {
      kickerClasses = this.computeStyleAndClasses(kickerClasses);
      return kickerClasses ? `highlight ${kickerClasses}` : '';
    },
    computeStyleAndClasses(property = '') {
      const publicationLabel = this.$store.state.publication.label.toLowerCase();
      const publicationShortLabel = (publicationLabel.includes('-')) ? publicationLabel.split('-')[0] : publicationLabel;
      if (this.isCrossPublication && property.includes(publicationLabel)) {
        return property.replace(publicationLabel, this.articlePubName);
      } else if (!this.isCrossPublication && !property.includes(publicationShortLabel)) {
        return property.includes('-kicker') ? property : '';
      }
      return property;
    }
  }
};
</script>
