import {
  siteLabel,
  resolvePageTitle,
  title,
  ogSiteName,
  ogImage,
  twitterImage,
  url,
  resolveArticlePageTitle,
  mapRouteMetadata,
  resolveArticleImageSource, resolveArticleDescription, resolveArticlePageTitleWithoutSiteLabel,
} from './helper';

export const defaultMetadata = {
  siteLabel,
  pageTitle: resolvePageTitle(),
  title,
  ogSiteName,
  ogUrl: url,
  ogImage,
  twitterUrl: url,
  twitterImage,
  twitterCard: 'summary_large_image',
  fbAppId: '1547668972218679',
};

const metaTitleWithoutLabel = {
  title() {
    return ({ metadata }) => `${metadata.pageTitle} | ${metadata.siteLabel}`;
  },
};

const publicationBusinessDomain = {
  upstream: 'energy industry',
  tradewinds: 'shipping business',
  intrafish_com: 'seafood',
  recharge: 'renewable energy',
  intrafish_no: 'sjømatbransjens',
  fiskeribladet: 'sjømatbransjens',
  europower: 'fornybarbransjens',
  tekfisk: 'sjømatbransjens ',
  nhstmvp: '',
  hydrogen: '',
  kysten: ''
};

const alertsPagesMeta = () => {
  return {
    title() {
      return ({ store }) => store.$i18n('alert.meta.title', store.state.publication.label);
    },
    description() {
      return ({ store }) => store.$i18n('alert.meta.description', publicationBusinessDomain[store.state.publication.name]);
    }
  };
};

export const defaultMetadataByRoute = {
  Home: {
    title() {
      return ({ metadata }) => `${metadata.siteLabel} | ${metadata.siteTitle}`;
    },
    ogType: 'frontpage'
  },
  Login: {
    title() {
      return ({ metadata }) => `Login Page | ${metadata.siteLabel}`;
    },
    description() {
      return ({ metadata }) => `Login Page | ${metadata.siteLabel}`;
    },
  },
  Author: {
    title() {
      return ({ metadata, store }) => `${store.state.author.name} | ${metadata.siteLabel}`;
    },
  },
  CategoryList: {
    pageTitle: resolvePageTitle({
      news: 'Latest news',
    }),
  },
  LatestNews: {
    title() {
      return ({ metadata }) => `${metadata.pageTitle} | ${metadata.siteLabel}`;
    },
  },
  SponsoredEdition: {
    title() {
      return ({ metadata }) => `${metadata.pageTitle} | ${metadata.siteLabel}`;
    },
  },
  SponsoredArticle: {
    title() {
      return ({ metadata, store }) => `${store.state.article.title} | ${metadata.siteLabel} ${store.$i18n('sponsor.content.social.media.title')}`;
    },
    documentTitle() {
      return ({ store }) => `${store.$i18n('sponsor.content.social.media.title')}: ${store.state.article.title}`;
    },
    description: resolveArticleDescription,
    imageSource: resolveArticleImageSource,
    ogType: 'article',
  },
  ThirtyYears: {
    title() {
      return ({ metadata }) => `TradeWinds 30th Anniversary`;
    },
    documentTitle() {
      return ({ metadata }) => `TradeWinds 30th Anniversary`;
    },
    description() {
      return ({ metadata }) => `As shipping’s leading news provider celebrates a landmark birthday, take a look back over more than 1,500 editions to see who was making the headlines and why at key times in the maritime industry.`;
    },
    ogImage({ pubCtx }) {
      return `https://www.${pubCtx.domain}/resources/gfx/${pubCtx.name}/tw-30@2x.png`;
    },
    twitterImage({ pubCtx }) {
      return `https://www.${pubCtx.domain}/resources/gfx/${pubCtx.name}/tw-30@2x.png`;
    }
  },
  Alerts: alertsPagesMeta(),
  AlertsAuthDenied: alertsPagesMeta(),
  AlertsAuthFailed: alertsPagesMeta(),
  AlertsTooManySessions: alertsPagesMeta(),
  ...mapRouteMetadata([
    'Coronavirus',
    'CoronavirusAuthDenied',
    'CoronavirusFailed',
    'CoronavirusTooManySession',
    'Chinese',
    'PricesEdition',
    'PricesAuthDenied',
    'PricesAuthFailed',
    'PricesTooManySessions',
    'Kalender',
    'Terms',
  ], metaTitleWithoutLabel),
  ...mapRouteMetadata([
    'ArticleAuthDenied',
    'ArticleFailed',
    'ArticlePreview',
    'Article',
    'TooManySession',
    'About',
    'Contact',
    'Help',
  ], {
    title: resolveArticlePageTitle,
    shareTitle: resolveArticlePageTitleWithoutSiteLabel,
    description: resolveArticleDescription,
    imageSource: resolveArticleImageSource,
    ogType: 'article',
  }),

};
