<template>
  <div class="news-stream-item mb-5">
    <h5
      v-if="timeGroupLabel"
      class="ff-flama fs-md fw-light tt-uppercase text-center">
      {{ timeGroupLabel }}
    </h5>
    <div
      v-for="newsElem in newsItems"
      :key="newsElem.key"
      class="news-elem d-flex justify-content-between py-3">
      <div class="published-date order-0 order-md-0 ff-flama-book fs-sm m-md-auto">
        {{ newsElem.firstPublishedTime }}
      </div>
      <div class="title flex-grow-1 order-2 order-md-1 fs-lg ff-sueca-bold mx-md-5">
        <a
          :href="newsElem.canonicalUrl"
          target="_blank">{{ newsElem.title }}</a>
      </div>
      <div
        v-if="newsElem.alertTopics.length"
        class="main-category order-1 order-md-2 fw-medium mb-2 mb-md-0">
        <a
          v-for="(topic, index) in newsElem.alertTopics.slice(0, 4)"
          :key="index"
          :href="`${$store.state.baseUrl}/${topic.url.replace(/^\/+/, '')}`"
          class="alert-topics"
          target="_blank">{{ topic.label }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'news-stream-item',
  props: {
    timestamp: {
      type: Number,
      default: 0
    },
    newsChunk: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      newsItems: this.newsChunk.reverse()
    };
  },
  computed: {
    timeGroupLabel() {
      const today = new Date();
      const bdate = today.getDate();
      const bmonth = today.getMonth();
      const byear = today.getFullYear();
      const dateOnly = new Date(byear, bmonth, bdate);
      const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
      return (dateOnly.getTime() === parseInt(this.timestamp))
        ? ''
        : (((dateOnly.getTime() - this.timestamp) / 3600000 === 24) ? this.$i18n('last.day.called')
          : new Date(parseInt(this.timestamp)).toLocaleDateString(
            (this.$pubCtx.language === 'no' ? 'nb-NO' : this.$pubCtx.language),
            options
          ));
    }
  }
};
</script>

<style lang="scss">
  .alert-topics {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 14px;
  }
  .alert-topics:not(:last-child)::after {
    content: ","
  }
  .alert-newsElements-title{
    font-size: 18px;
    color: #323232;
  }
  .news-elem .published-date {
    white-space: nowrap;
  }
  .news-elem .title a {
    color: #323232;
  }
</style>
