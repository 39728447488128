<template>
  <component
    :is="inlineComponent"
    :type="componentType"
    v-bind="$props" >
    <slot></slot>
  </component>
</template>

<script>
import {
  InlineNews,
  InlinePicture,
  InlineEmbed,
  InlineFactbox,
  InlineMedia,
  InlineNewsGroup,
  InlinePromoBox
} from './inline-elements';

export default {
  name: 'inline-element',
  components: {
    /* eslint-disable vue/no-unused-components */
    InlineNews,
    InlinePicture,
    InlineEmbed,
    InlineFactbox,
    InlineMedia,
    InlineNewsGroup,
    InlinePromoBox
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    refId: String,
    componentType: {
      type: String,
      default: ''
    },
    promoBoxValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    inlineComponent() {
      if (this.refId === 'media') {
        return `inline-media`;
      }

      if (this.refId === 'group') {
        return `inline-news-group`;
      }

      if (this.refId === 'promo-box') {
        return `inline-promo-box`;
      }

      const data = this.$store.state.article.inlineElements[this.refId];
      return (data && data.type) ? `inline-${data.type}` : null;
    }
  }
};
</script>
