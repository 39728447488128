<template>
  <Component
    :is="paywallComponent"
    :target-query="paywallTargetQuery"/>
</template>

<script>
import PaywallIframe from './PaywallIframe';
import NativePaywall from './NativePaywall';

export default {
  name: 'paywall',
  components: {
    /* eslint-disable vue/no-unused-components */
    PaywallIframe,
    NativePaywall
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    targetQuery: {
      type: String,
      required: false,
      default: ''
    }
  },
  inject: ['isMobileView'],
  data() {
    return {
      paywallComponent: this.isMobileView ? NativePaywall : PaywallIframe,
      paywallTargetQuery: this.targetQuery
    };
  }
};
</script>
