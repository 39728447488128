<template>
  <div>
    <h5 class="ff-flama fs-lg fw-light tt-uppercase mb-0">
      {{ __('search.more') }}
    </h5>
    <div>
      <VSelect
        id="ajax"
        v-model="selectedItems"
        :options="items"
        :searchable="true"
        :loading="loading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="true"
        :options-limit="limit"
        :max-height="600"
        :show-labels="false"
        :placeholder="__('search.other.topics')"
        @search-change="onSearch"
        @select="checkIfAlreadySubscribed"
        @close="items = fallbackItems"
        label="label"
        track-by="name"
        open-direction="bottom">
        <template slot="noOptions">
          <span></span>
        </template>
        <template
          slot="option"
          slot-scope="{ option }">
          <div class="search-results d-flex justify-content-between">
            <div class="text">
              <FontAwesomeIcon
                :icon="option.type === 'topic' ? icons.faTag : icons.faUserEdit"
                class="mr-1"/>
              <span class="title">
                {{ option.label }}
              </span>
              <span class="subtitle">
                {{ __(`in.${option.type}`) }}
              </span>
            </div>
            <div class="button align-self-center">
              <span class="btn">{{ __('alert.me') }}</span>
            </div>
          </div>
        </template>
        <template
          slot="noResult">{{ __('alert.search.no.result') }}
        </template>
      </VSelect>
    </div>
    <AlertModal
      :show="showModal"
      @close="showModal = false; selectedItems = [];">
      <AlertOptionChooser
        :items="[selectedItems]"
        :sub-type="(selectedItems.type === 'topic' && selectedItems.subType) || 'authors'"
        :from-page="true"
        :fromSearch="true"
        @confirm="confirmSubscription"
        @confirmError="handleConfirmError"/>
    </AlertModal>
    <AlertConfirmModal
      :show-confirm="showConfirmModal"
      :show-success="confirmSuccess"
      :show-already-subscribed="alreadySubscribedTopicSubType"
      :from-page="true"
      @close="() => { showConfirmModal = false; confirmSuccess = false; onConfirmModalclose(); }">
    </AlertConfirmModal>
  </div>
</template>
<script>
import { debounce, EventBus, alertLocalStorageUtil, isEmpty } from 'global-utils';
import qs from 'qs';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTag, faUserEdit } from '@fortawesome/pro-regular-svg-icons';
import AlertModal from './AlertModal';
import AlertOptionChooser from './AlertOptionChooser';
import AlertConfirmModal from './AlertConfirmModal';

const searchUrl = `/alert/search`;
export default {
  name: 'alert-search',
  components: {
    AlertModal,
    AlertOptionChooser,
    FontAwesomeIcon,
    AlertConfirmModal,
  },
  data() {
    return {
      items: [],
      selectedItems: [],
      fallbackItems: [],
      limit: 12,
      loading: false,
      showModal: false,
      icons: {
        faTag,
        faUserEdit,
      },
      showConfirmModal: false,
      confirmSuccess: true,
      subscriptionData: [],
      alreadySubscribedTopicSubType: '',
    };
  },
  async mounted() {
    this.items = await this.fetchItems(this.buildUrl());
    this.fallbackItems = this.items;
    this.subscriptionData = await alertLocalStorageUtil.getItem();
    EventBus.$on('alert:refresh', async () => {
      this.subscriptionData = await alertLocalStorageUtil.getItem();
      this.selectedItems = [];
      this.loading = false;
    });
  },
  methods: {
    onSearch: debounce(async function (q) {
      if (!q) {
        return;
      }
      this.loading = true;
      try {
        const searchResults = await this.fetchItems(this.buildUrl(q));
        this.items = await searchResults.map(item => {
          item.name = (item.type.includes('topic') && item.name.toLowerCase().split(' ').join('_')) || item.name;
          return item;
        });
      } catch (e) {
        console.error(e);
        this.items = this.fallbackItems;
      }
      this.loading = false;
    }, 400),
    buildUrl(q) {
      const query = {
        ...this.$pubCtx.solr.defaultQuery,
        fieldFacets: ['tema_facet', 'othersections'],
        facetContains: q,
      };
      return searchUrl + '?' + qs.stringify(query, { indices: true });
    },
    async fetchItems(url) {
      const res = await fetch(url);
      const { topics, authors } = await res.json();
      let topicItems = [];
      let authorItems = [];
      if (topics.length) {
        topicItems = topics.map(({ name, label, subType }) => ({ type: 'topic', name, label, subType }));
      }
      if (authors.length) {
        authorItems = authors.sort((a, b) => a.score - b.score)
          .map(({ item }) => ({
            type: 'author',
            name: item.username,
            label: `${item.firstName} ${item.lastName}`
          }));
      }
      return [...topicItems, ...authorItems];
    },
    setItemType(type, items) {
      return items.map(item => {
        item.type = type;
        return item;
      });
    },
    confirmSubscription() {
      this.showModal = false;
      this.showConfirmModal = true;
      this.confirmSuccess = true;
    },
    handleConfirmError(err) {
      console.error('something went wrong saving subscription: ', err);

      this.showModal = false;
      this.showConfirmModal = true;
      this.confirmSuccess = false;
    },
    checkIfAlreadySubscribed(selected) {
      const subType = (selected.type === 'topic') ? 'topics' : 'authors';
      const subscribedItems = (!isEmpty(this.subscriptionData) && !isEmpty(this.subscriptionData[subType]))
        ? this.subscriptionData[subType].map(subItem => subItem.term.toLowerCase())
        : [];

      const alreadySubscribed = (!isEmpty(subscribedItems)) ? subscribedItems.includes(selected.name.toLowerCase()) : false;
      if (alreadySubscribed) {
        this.alreadySubscribedTopicSubType = subType;
        this.confirmSuccess = false;
        this.showConfirmModal = true;
        return;
      }
      this.showModal = true;
      this.items = this.fallbackItems;
    },
    onConfirmModalclose() {
      if (this.alreadySubscribedTopicSubType === '') {
        EventBus.$emit('newsPanel:refresh');
      }
    }
  },
};
</script>
<style lang="scss">

  .multiselect__select {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" width="23px" height="23px"><path d="M15.79 9.57a6 6 0 1 0-12.02.02 6 6 0 0 0 12.02-.02Zm6.85 11.14c0 .94-.77 1.72-1.71 1.72-.46 0-.9-.19-1.2-.51l-4.6-4.58a9.43 9.43 0 1 1 2.42-2.42l4.6 4.59c.3.3.5.75.5 1.2Z" fill="%23CCC"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
  }

  .multiselect--active, .multiselect__select {
    transform: none !important;
  }

  .multiselect--active {
    .multiselect__select {
       background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path d="M443.6 387.1 312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z" fill="%23CCC"/></svg>');
    }
  }

  .multiselect__select:before {
    display:none;
  }

  .multiselect__input {
    text-decoration: none !important;
  }

  .multiselect__element {
    margin-bottom: 0 !important;
  }

  .multiselect__content {
    width: 100%;
    @include color('primary');
  }
  .multiselect__tags {
    @include borderColor('primary');
  }

  .search-results {
    .text {
      width: 200px;
      white-space: normal;
      line-height: 20px;
    }

    .subtitle {
      font-size: 1.2rem;
      font-weight: bolder;
    }

    .btn {
      border: 1px solid;
      float: right;
      font-size: 1.2rem;
      @include color('primary');
      @include borderColor('primary');
    }
  }

</style>
