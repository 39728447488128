<template>
  <div class="order-form-wrapper">
    <Row class="privat-product-row">
      <Column md="12">
        <div
          v-text="product.name"
          class="product-name fw-md ml-0"></div>
        <div class="product-desc ml-0 mb-3">
          <p
            v-if="product.note !== ''"
            class="fw-light my-0">{{ product.note }}</p>
          <p class="fw-light my-0">{{ product.indents }}</p>
          <p class="my-0"><span class="st-italic fw-md">Overskrift:</span> <span class="fw-light">{{ product.overskritt }} tegn</span></p>
          <p class="my-0"><span class="st-italic fw-md">Tekst:</span> <span class="fw-light">{{ product.tekst }} tegn</span></p>
          <p class="fw-bold">{{ product.price }}</p>
        </div>
        <div
          v-if="!review"
          class="source-note">
          <p>Annonser må bestilles to virkedager før innrykk:</p>
          <ul class="mx-0 px-4">
            <li>fredag kl. 10 for tirsdag</li>
            <li>onsdag kl. 10 for fredag</li>
          </ul>
        </div>
      </Column>
    </Row>
    <Row class="privat-product-row">
      <Column md="7">
        <div
          v-if="!review"
          class="order-form">
          <DropdownField
            v-model="form.reason"
            :options="['Kjøp og salg', 'Båter', 'Motor', 'Utstyr', 'Kvoter', 'Arbeid', 'Tjenester', 'Diverse']"
            :messages="validation.reason"
            @input="validation.reason = []"
            label="Hva skal du som privatperson annonsere?"
            placeholder="Velg her"/>

          <TextField
            v-model="form.title"
            :messages="validation.title"
            @input="validation.title = []"
            :placeholder="'Overskrift. Maks ' + product.overskritt + ' tegn'"
            :maxlength="product.overskritt"
            label="Overskrift"/>

          <TextField
            v-model="form.body"
            :messages="validation.body"
            @input="validation.body = []"
            :placeholder="'Skriv teksten her. Maks ' + product.tekst + ' tegn'"
            :maxlength="product.tekst"
            label="Innhold"
            multiline/>

          <date-picker
            ref="fromDateRange"
            v-model="form.dateRange"
            @pick="checkDate"
            @clear="clearSelected"
            :disabled-date="dateDisableCondition"
            placeholder="Velg datoer"
            class="date-range-picker w-100 mt-3"
            clearable
            value-type="DD.MM.YYYY"
            format="DD/MM/YYYY"/>
          <div
            v-if="emptyDate"
            class="error message">Velg Datoer!</div>
          <label
            v-if="validDateSelected"
            class="message mt-1 mb-3">
            {{ __('orderform.valid.daterange.selected', selectedDateRange[0]) }}
          </label>
          <p class="form-separator"></p>

          <TextField
            v-model="form.phone"
            @input="validation.phone = []"
            :messages="validation.phone"
            label="Telefon"
            placeholder="Telefonnummer"/>

          <TextField
            v-model="form.email"
            @input="validation.email = []"
            :messages="validation.email"
            label="Epost"
            placeholder="Epostadresse"/>

          <TextField
            v-model="form.name"
            @input="validation.name = []"
            :messages="validation.name"
            label="Navn"
            placeholder="Navn"/>

          <TextField
            v-model="form.address"
            @input="validation.address = []"
            :messages="validation.address"
            label="Adresse"
            placeholder="Adresse"/>

          <TextField
            v-model="form.zipCode"
            @input="validation.zipCode = []"
            :messages="validation.zipCode"
            label="Postnummer"
            placeholder="Postnummer"/>

          <TextField
            v-model="form.postal"
            @input="validation.postal = []"
            :messages="validation.postal"
            label="Poststed"
            placeholder="Poststed"/>

          <DropdownField
            v-model="form.country"
            @input="validation.country = []"
            :messages="validation.country"
            :options="countries"
            label="Land"
            placeholder="Velg land"/>

          <div class="d-flex button-area mt-5">
            <button
              @click="sendReq"
              type="button"
              class="btn btn-primary mb-5 px-3 mr-2">Send inn annonsen</button>

            <button
              @click="cancel"
              type="button"
              class="btn btn-secondary mb-5 px-3 ml-2">Angre</button>
          </div>
        </div>
        <div
          v-if="review"
          class="email-send-status mb-5">
          <OrderEmailBody
            @back="goBack"
            @editInfo="returnToForm"
            :form="form"
            :label-by-field="labelByField"
            :product="product"/>
        </div>
      </Column>
      <Column md="5">
      </Column>
    </Row>
  </div>
</template>

<script>
import 'vue2-datepicker/locale/nb';
import countries from 'global-configs/countries.json';
import dayjs from 'dayjs';
import OrderEmailBody from './OrderEmailBody';

const labelByField = {
  reason: 'Hva du skal annonsere?',
  title: 'Overskrift',
  body: 'Innhold',
  phone: 'Telefon',
  email: 'Epost',
  name: 'Navn',
  address: 'Adresse',
  zipCode: 'Postnummer',
  postal: 'Poststed',
  country: 'Land',
  dateRange: 'Datoer',
};

export default {
  name: 'order-privat-product-forms',
  components: {
    OrderEmailBody
  },
  props: {
    productId: {
      type: String,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    const [start, end] = [null, null];
    dayjs.locale(this.$store.state.publication.language);

    return {
      form: this.resetForm(),
      validation: this.resetValidation(),
      range: [start, end],
      countries,
      labelByField: Object.assign({}, labelByField, { productInfo: this.product, dateRange: this.selectedDateRange }),
      selectedDateRange: [],
      disabledBefore: dayjs(),
      disabledAfter: undefined,
      review: false,
      emptyDate: false,
      validDateSelected: false,
    };
  },
  mounted() {
    window.addEventListener('popstate', (e) => {
      e.preventDefault();
      this.$router.push({ path: '/bestill-annonse/privat' });
      return false;
    });
  },
  methods: {
    resetForm() {
      return Object.keys(labelByField)
        .reduce((cum, field) => {
          cum[field] = '';
          return cum;
        }, {});
    },
    resetValidation() {
      this.emptyDate = false;
      return Object.keys(labelByField)
        .reduce((cum, field) => {
          cum[field] = [];
          return cum;
        }, {});
    },
    sendReq() {
      if (!this.validate()) {
        return;
      }
      this.review = true;
    },
    validate() {
      Object.keys(this.validation).map(field => {
        this.validation[field].length = 0;
      });
      this.hasValue();
      this.maxLength();
      for (const messages of Object.values(this.validation)) {
        if (messages.length) {
          return false;
        }
      }
      return true;
    },
    hasValue() {
      Object.entries(labelByField).forEach(([field, label]) => {
        if (!this.form[field]) {
          this.validation[field].push(`${label} er påkrevd.`);
        }
      });
    },
    maxLength() {
      if (this.form.title.length > this.product.overskritt) {
        this.validation.title.push(`Maks ${this.product.overskritt} tegn!`);
      }
      if (this.form.body.length > this.product.tekst) {
        this.validation.body.push(`Maks ${this.product.tekst} tegn!`);
      }
      if (this.selectedDateRange.length === 0) {
        this.validation.dateRange.push('Velg Datoer!');
        this.emptyDate = true;
      }
      if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.form.email))) {
        this.validation.email.push('Ugyldig e-post!');
      }
    },
    cancel() {
      console.log('cancel called!');
      this.resetForm();
      this.$emit('cancel');
    },
    dateDisableCondition(date) {
      const weekendAndOffDays = [0, 1, 3, 4, 6];
      const givenDate = new Date(date).getDay();
      const condition1 = weekendAndOffDays.includes(givenDate);

      let condition2 = false;
      if (this.disabledAfter) {
        condition2 = (date <= this.disabledBefore) || (date > this.disabledAfter);
      } else {
        condition2 = (date <= this.disabledBefore);
      }

      const formattedDay = dayjs(date, 'YYYY-MM-DD', this.$store.state.publication.language);
      const condition3 = (this.selectedDateRange.length === 8) && (this.selectedDateRange.indexOf(formattedDay) === -1);

      const christmasCondition = (new Date(date).getDate() === 25 && new Date(date).getMonth() === 11);

      return condition1 || condition2 || condition3 || christmasCondition;
    },
    checkDate(pickedDate) {
      let formatted = dayjs(pickedDate, 'YYYY-MM-DD');
      do {
        if (!this.dateDisableCondition(formatted.format('YYYY-MM-DD'))) {
          this.selectedDateRange.push(formatted.format('YYYY-MM-DD'));
        }
        formatted = formatted.add(1, 'day');
      } while (this.selectedDateRange.length < 8);

      if (this.selectedDateRange.length === 8) {
        this.validation.dateRange = [];
        this.emptyDate = false;
        this.validDateSelected = true;
      }
    },
    clearSelected() {
      this.selectedDateRange = [];
      this.disabledBefore = dayjs();
      this.disabledAfter = undefined;
      this.validDateSelected = false;
    },
    returnToForm(formData) {
      this.review = false;
      this.form = formData;
    },
    goBack() {
      this.$router.push({ path: '/bestill-annonse' });
    }
  }
};
</script>
