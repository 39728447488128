export function imageSource(leadAsset) {
  if (leadAsset == null) {
    return null;
  }

  switch (leadAsset.type) {
  case 'lead-image':
    return leadAsset.imageSource;

  case 'lead-picture-gallery':
    return leadAsset.leadImages[0].imageSource;

  default:
    return null;
  }
}

export function crossPublicationHighlightClass(pubCtx, teaserHighlight, articlePublication) {
  const publicationShortCode = pubCtx.publicationShortCodeMap[articlePublication];
  let teaserHighlightClass = `highlight ${publicationShortCode}-regular`;
  if (teaserHighlightExists(teaserHighlight)) {
    teaserHighlightClass = `highlight ${publicationShortCode}-${teaserHighlight}`;
  }

  return teaserHighlightClass;
}

export function teaserHighlightExists(teaserHighlight) {
  return ((typeof teaserHighlight !== 'undefined') && (teaserHighlight !== '') && (teaserHighlight !== '<NONE>'));
}
