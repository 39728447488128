<template>
  <div
    :class="styleClass">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'container',
  props: {
    fluid: {
      type: Boolean,
      default: false
    },
    flex: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    styleClass() {
      return this.flex ? 'flex-container' : (this.fluid ? 'container-fluid' : 'container');
    }
  }
};
</script>
