<template>
  <a
    :href="url"
    :class="[$options.name, $props.styleClass]">
    <i
      v-if="showBefore"
      class="material-icons md-18">
      {{ icon }}
    </i>
    {{ text }}
    <slot></slot>
    <i
      v-if="showAfter"
      class="material-icons md-14">
      {{ icon }}
    </i>
  </a>
</template>
<script>
export default {
  name: 'card-link',
  props: {
    text: {
      type: String,
      require: true
    },
    url: {
      type: String,
      default: '#'
    },
    icon: {
      type: String
    },
    before: {
      type: Boolean,
      default: false
    },
    after: {
      type: Boolean,
      default: false
    },
    styleClass: String
  },
  computed: {
    showBefore() {
      return this.icon && this.before;
    },
    showAfter() {
      return this.icon && (this.after || !this.before);
    }
  }
};
</script>
