import commonEditionConfig from './commonEditionpage';
import commonFrontpageConfig from './commonFrontpage';
const defaultConfig = {
  frontpage: commonFrontpageConfig,
  test_frontpage: commonFrontpageConfig,
  aquaculture: commonEditionConfig,
  fisheries: commonEditionConfig,
  markets: commonEditionConfig,
  processing: commonEditionConfig,
  salmon: commonEditionConfig
};

export default (edition) => {
  return {
    newsBlocks: defaultConfig[edition],
    jobsBlock: { }
  };
};
