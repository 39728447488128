import axios from 'axios';
import qs from 'qs';
import { alertLocalStorageUtil, isEmpty, _isNil, moment, polyfillURL, syncAlertTopicSubType } from 'global-utils';

const NEWSSTREAM_INITIAL_COUNT = 40;

export class AlertNewsStreamMiddleware {
  constructor(vm, env) {
    this.vm = vm;
    this.env = env;
    this.logger = (window && window.logger) || console || {};
  }

  get baseUrl() {
    return dconf.lanternAPI.baseUrl + '/content';
  }

  set newsLimit(limit) {
    this.limit = limit;
  }

  get newsLimit() {
    return this.limit || NEWSSTREAM_INITIAL_COUNT;
  }

  get params() {
    const paramsObj = {
      publication: this.vm.$pubCtx.lanternPubName,
      hits: this.newsLimit,
      types: 'news',
      state: 'published',
      embed_types: 'video',
      sort_by: 'first_published_at',
      sort_order: 'desc',
    };
    if (this.buildTermsQuery() !== '') {
      paramsObj.query = `${this.buildTermsQuery()}`;
    }

    return qs.stringify(paramsObj, { indices: false });
  }

  async fetch() {
    try {
      if (this.buildTermsQuery() === '') {
        return []; // as no topics/authors are subscribed
      }

      var resp = await axios.post('/global/api/newsStream', {
        endpoint: encodeURIComponent(`${this.baseUrl}?${this.params}`)
      });

      if (!isEmpty(resp.data)) {
        this.logDebug('alert news stream fetching worked successfully!');
        return resp.data;
      }

      return [];
    } catch (err) {
      this.logDebug('alert news stream fetching thrown errors!');
      if (err.response && err.response.data) {
        this.logError(`NewsStream fetch api backend down / not set. Fetch Failed with status_code = ${err.response.status}`);
      } else {
        console.error(err);
      }
      throw err;
    }
  }

  async fetchAndDecorate() {
    try {
      const responseData = await this.fetch();
      if (!isEmpty(responseData)) {
        const newsStreamData = await this.decorateData(responseData);
        return newsStreamData;
      }

      return [];
    } catch (err) {
      if (err.response && err.response.data) {
        this.logError(`NewsStream fetch api backend down / not set. Fetch Failed with status_code = ${err.response.status}`);
      } else {
        console.error(err);
      }
      throw err;
    }
  }

  async fetchMiniStream() {
    try {
      const responseData = await this.fetch();
      if (!isEmpty(responseData)) {
        const newsStreamData = await this.decorateMiniStream(responseData);
        return newsStreamData;
      }

      return [];
    } catch (err) {
      if (err.response && err.response.data) {
        this.logError(`NewsStream fetch api backend down / not set. Fetch Failed with status_code = ${err.response.status}`);
      } else {
        console.error(err);
      }
      throw err;
    }
  }

  async decorateData(responseData) {
    const newsStream = {};
    if (!isEmpty(responseData) && !isEmpty(responseData.components)) {
      await responseData.components.map(async (article) => {
        const articleFirstPubLishedDate = moment(article.first_published_at).format('DD.MM.YYYY');
        const articleFirstPubLishedEpoch = moment(articleFirstPubLishedDate, 'DD.MM.YYYY').valueOf();

        const categories = article.categories ? article.categories.map(cat => cat.id.toLowerCase()) : [];
        const topics = article.topic ? [article.topic.name.toLowerCase()] : [];
        const tags = article.tags ? article.tags.map(tag => tag.name.toLowerCase()) : [];
        const authors = article.byline.authors ? article.byline.authors.map(author => author.user_name) : [];

        const articleSubItems = [...categories, ...topics, ...tags, ...authors];
        const subscriptionItems = Array.from(new Set([...articleSubItems]));

        const responseData = {
          firstPublishedAt: articleFirstPubLishedDate,
          firstPublishedTime: moment(article.first_published_at).format('hh:mm A'),
          title: article.title,
          canonicalUrl: polyfillURL(article.canonical_url),
          alertTopics: await this.buildAlertTopics(subscriptionItems),
          firstPublishedAtWithDateAndTime: article.first_published_at,
        };
        if (!newsStream[articleFirstPubLishedEpoch]) {
          newsStream[articleFirstPubLishedEpoch] = [];
        }
        newsStream[articleFirstPubLishedEpoch].unshift(responseData);
      });
    }

    return newsStream;
  }

  async decorateMiniStream(responseData) {
    const newsStream = [];
    if (!isEmpty(responseData) && !isEmpty(responseData.components)) {
      await responseData.components.map(async (article) => {
        const articleFirstPubLishedDate = moment(article.first_published_at).format('DD.MM.YYYY');
        const categories = article.categories ? article.categories.map(cat => cat.id.toLowerCase()) : [];
        const topics = article.topic ? [article.topic.name.toLowerCase()] : [];
        const tags = article.tags ? article.tags.map(tag => tag.name.toLowerCase()) : [];
        const authors = article.byline.authors ? article.byline.authors.map(author => author.user_name) : [];

        const articleSubItems = [...categories, ...topics, ...tags, ...authors];
        const subscriptionItems = Array.from(new Set([...articleSubItems]));

        newsStream.push({
          firstPublishedAt: articleFirstPubLishedDate,
          firstPublishedTime: moment(article.first_published_at).format('hh:mm A'),
          title: article.title,
          category: article.categories.filter(cat => cat.default)[0],
          canonicalUrl: polyfillURL(article.canonical_url),
          alertTopics: await this.buildAlertTopics(subscriptionItems)
        });
      });
    }

    return newsStream;
  }

  buildTermsQuery() {
    const subscriptionData = alertLocalStorageUtil.getItem();

    if (!subscriptionData || (
      isEmpty(subscriptionData.topics) &&
      isEmpty(subscriptionData.authors) &&
      isEmpty(subscriptionData.searchQueries)
    )) {
      return '';
    }

    const latestNews = subscriptionData.topics.filter(t => t.term === 'latest_news');
    if (!isEmpty(latestNews) && latestNews[0] && latestNews[0].label === 'Latest News') {
      return ''; // as subscription to latest news overrides subscription to all tag/topic/categories/authors
    }

    const topicQuery = subscriptionData.topics
      ? subscriptionData.topics.map(topic => {
        return `tags.name:"${topic.term}" OR categories.id:"${topic.term}" OR topic.name:"${topic.term}"`;
      }).join(' OR ')
      : '';

    const authorQuery = subscriptionData.authors
      ? subscriptionData.authors.map(author => `byline.authors.user_name:"${author.term}"`).join(' OR ')
      : '';

    return (topicQuery && authorQuery)
      ? `((${topicQuery}) OR (${authorQuery}))`
      : (topicQuery
        ? `(${topicQuery})`
        : (authorQuery ? `(${authorQuery})` : ''));
  }

  async buildAlertTopics(articletopics) {
    const subscriptionData = alertLocalStorageUtil.getItem();

    if (!subscriptionData || (
      _isNil(subscriptionData.topics) &&
      _isNil(subscriptionData.authors) &&
      _isNil(subscriptionData.searchQueries)
    )) {
      return [];
    }

    const alertTopics = subscriptionData.topics
      ? await subscriptionData.topics.map(subItem => {
        if (articletopics.includes(subItem.term)) {
          subItem = syncAlertTopicSubType(this.vm.$store.state.alertTopics, subItem);
          return {
            url: (subItem.subType === 'tag') ? `/tag/${subItem.term}` : subItem.term, label: subItem.label
          };
        }
      })
      : [];

    const authorTopics = subscriptionData.authors
      ? await subscriptionData.authors.map(author => {
        if (articletopics.includes(author.term)) {
          return { url: `/author/${author.term}`, label: author.label };
        }
      })
      : [];
    // console.log('alert topics: ', [...authorTopics, ...alertTopics].filter(Boolean));
    return [...authorTopics, ...alertTopics].filter(Boolean);
  }

  logError(msg) {
    this.logger.error(`[${this.constructor.name}] ${msg}`);
  }

  logDebug(msg) {
    if (debugMode) {
      this.logger.debug(`[${this.constructor.name}] ${msg}`);
    }
  }
}
