<template>
  <div class="order-email-body mx-0">
    <Row
      id="email-body"
      v-if="!sent"
      class="px-5">
      <Column md="12">
        <Row
          v-for="(label, field) in labelByField"
          :key="field">
          <Column
            v-if="field+'' !== 'productInfo'"
            md="12">
            <div class="py-1 my-1"><b>{{ (field+'' === 'dateRange') ? 'Datoer' : label }}: </b></div>
            <div class="field-desc py-1 my-2">{{ (field+'' === 'dateRange') ? __('orderform.valid.daterange.selected', form[field]) : form[field] }}</div>
          </Column>
        </Row>
      </Column>
    </Row>
    <Row
      v-if="!sent"
      class="px-5 email-actions">
      <Column md="12">
        <h6 class="my-5">Hvis opplysningene over stemmer, gå videre til bestilling. Hvis ikke, klikk “Endre”.</h6>
        <Recaptcha
          ref="recaptcha"
          @gRtoken="handleRecaptchaTokenGenerateEvent"
          @gRtokenExpired="handleResetRecaptchaEvent"/>
        <div class="d-flex button-area">
          <button
            id="sendButton"
            @click="send"
            :disabled="sent || disableSubmitButton"
            type="button"
            class="btn btn-primary mb-5 px-5 mr-2">
            <span v-if="!sending && !sent">Bestill</span>
            <span v-if="sending && !sent">Sende </span>
            <span v-if="!sending && sent">Sendte</span>
            <FontAwesomeIcon
              v-if="sending"
              :icon="loader"
              pulse/>
            <FontAwesomeIcon
              v-if="sent"
              :icon="sentIcon"/>
          </button>

          <button
            @click="editInfo"
            type="button"
            class="btn btn-secondary mb-5 px-5 ml-2">Endre</button>
        </div>
      </Column>
    </Row>
    <div
      v-if="emailSendStatus"
      class="email-send-status mb-5">
      <div v-if="emailSendStatus === 'sent'">
        <div class="text-center my-3 py-3">
          <p>Takk for din bestilling!</p>
          <p>Din annonse vil nå bli produsert og bli rykket inn i avis til avtalte datoer.</p>
          <p>Lykke til med annonsering. Hilsen annonseteamet i Fiskeribladet</p>
        </div>
        <div class="text-center my-3 px-3">
          <button
            @click="back()"
            type="button"
            class="btn btn-primary mb-5 px-5">Gå tilbake til Fiskeribladet</button>
        </div>
      </div>
      <div
        v-if="emailSendStatus === 'failed'"
        class="order-form-email-error">
        Noe gikk galt. Vær så snill og kontakt
        <a href="mailto:annonse@fiskeribladet.no">annonse@fiskeribladet.no</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/pro-light-svg-icons';

export default {
  name: 'order-email-body',
  components: {
    FontAwesomeIcon
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    labelByField: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sending: false,
      loader: faSpinner,
      sent: false,
      sentIcon: faCheck,
      emailSendStatus: undefined,
      recaptchaToken: '',
      disableSubmitButton: true
    };
  },
  methods: {
    async send(ev) {
      this.sending = true;
      try {
        let to = ['tuhin@cefalo.com', 'himel@cefalo.com', this.form.email];
        if (appEnv === 'prod') {
          to = ['annonse@fiskeribladet.no', this.form.email];
        }
        await axios.post('/send', {
          to,
          subject: `Bestill annonse | Privat: ${this.product.name}`,
          html: document.getElementById('email-body').outerHTML,
          gRecaptchaToken: this.recaptchaToken
        });
        this.sending = true;
        this.sent = true;
        this.emailSendStatus = 'sent';
        document.getElementById('sendButton').classList.remove('btn-primary');
        document.getElementById('sendButton').classList.add('btn-success');
      } catch (e) {
        console.error('email sending error: ', e);
        this.sending = false;
        this.sent = false;
        this.emailSendStatus = 'failed';
      }
      await this.$refs.recaptcha.resetRecaptcha();
    },
    editInfo() {
      this.$emit('editInfo', this.form);
    },
    back() {
      this.$router.push({ path: '/bestill-annonse' });
    },
    handleResetRecaptchaEvent() {
      this.disableSubmitButton = true;
    },
    handleRecaptchaTokenGenerateEvent(token) {
      this.recaptchaToken = token;
      this.disableSubmitButton = false;
    },
  },
};
</script>
