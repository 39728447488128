<template>
  <div
    id="send-email-to-friend-modal"
    class="modal"
    tabindex="-1"
    role="dialog">
    <div
      class="modal-dialog modal-dialog-centered"
      role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="ff-flama fs-xl fw-light mt-3">
            {{ __('emailmodal.legend') }}
          </h5>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent
            class="share-article-form">
            <fieldset>
              <input
                id="email"
                v-model="email"
                :placeholder="__('emailmodal.placeholder.email')"
                type="email"
                name="email"
                value=""
                class="form-control mb-4">
              <textarea
                id="comment"
                v-model="comment"
                :placeholder="__('emailmodal.placeholder.message')"
                name="comment"
                class="form-control mb-5"></textarea>
              <div class="float-right">
                <button
                  @click="sendAjaxRequest"
                  type="submit"
                  class="btn btn-primary mr-4"
                  data-dismiss="modal">{{ __('emailmodal.send') }}</button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal">{{ __('emailmodal.cancel') }}</button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
    <div
      id="email-content"
      style="display: none;">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'send-email-to-friend',
  props: {
    subject: {
      type: String,
      required: true
    },
  },
  data: function () {
    return {
      email: '',
      comment: '',
      successMessage: this.$i18n('emailmodal.success'),
      failureMessage: this.$i18n('emailmodal.failure')
    };
  },
  computed: {
    content() {
      return `<div>${this.comment}${this.$el.querySelector('#email-content').innerHTML}</div>`;
    }
  },
  methods: {
    sendAjaxRequest() {
      const postData = {
        to: this.email,
        subject: this.subject,
        html: this.content,
      };
      axios.post('/send', postData)
        .then(() => {
          alert(this.successMessage);
          this.resetInputFields();
        })
        .catch(() => {
          alert(this.failureMessage);
          this.resetInputFields();
        });
    },
    resetInputFields() {
      this.email = '';
      this.comment = '';
    }
  }
};
</script>
