<template>
  <div class="popular-topics ff-flama">
    <div class="popular-topics__wrapper">
      <div class="topics-header mr-3">
        {{ __('alert.popular.topics') }}:
      </div>
      <div
        v-for="topic in $store.state.popularTopics"
        :key="topic.name"
        @click="subscribe(topic)"
        class="topic fs-md fw-medium mr-3">
        {{ topic.label }}
      </div>
    </div>
    <AlertModal
      :show="showSubscribeModal"
      @close="showSubscribeModal = false">
      <AlertOptionChooser
        :items="subscribeItem"
        :from-page="true"
        :sub-type="subType"
        @confirm="confirmSubscription"
        @confirmError="handleConfirmError"/>
    </AlertModal>
    <AlertConfirmModal
      :show-confirm="showConfirmModal"
      :show-success="confirmSuccess"
      :show-already-subscribed="alreadySubscribedTopicSubType"
      :from-page="true"
      @close="() => { showConfirmModal = false; confirmSuccess = false; onConfirmModalclose(); }">
    </AlertConfirmModal>
  </div>
</template>

<script>
import AlertModal from './AlertModal';
import AlertConfirmModal from './AlertConfirmModal';
import AlertOptionChooser from './AlertOptionChooser';
import { EventBus, alertLocalStorageUtil, isEmpty } from 'global-utils';

export default {
  name: 'alert-promo-popular-topics',
  components: {
    AlertOptionChooser,
    AlertModal,
    AlertConfirmModal,
  },
  data() {
    return {
      subscribeItem: [],
      subType: '',
      showSubscribeModal: false,
      showConfirmModal: false,
      confirmSuccess: false,
      alreadySubscribedTopicSubType: '',
    };
  },
  methods: {
    async subscribe({ name, type, label }) {
      const subscriptionData = await alertLocalStorageUtil.getItem();
      const alreadySubscribedTopic = subscriptionData &&
        subscriptionData.topics &&
        subscriptionData.topics.find(topic => topic.term === name);

      if (alreadySubscribedTopic) {
        this.alreadySubscribedTopicSubType = alreadySubscribedTopic.subType;
        this.confirmSuccess = false;
        this.showConfirmModal = true;
        return;
      }
      this.subscribeItem = [{ name, label, type }];
      this.subType = type;
      this.showSubscribeModal = true;
    },
    confirmSubscription(confirmData) {
      this.showSubscribeModal = false;
      this.showConfirmModal = true;
      this.confirmSuccess = true;
    },
    handleConfirmError(err) {
      console.error('something went wrong saving subscription: ', err);

      this.showSubscribeModal = false;
      this.showConfirmModal = true;
      this.confirmSuccess = false;
    },
    onConfirmModalclose() {
      if (!isEmpty(this.subscribeItem)) {
        EventBus.$emit('newsPanel:refresh');
      }
    }
  }
};
</script>
<style lang="scss">
.popular-topics {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.8rem;
  @include media-breakpoint-down(sm) {
    @include h-scroll;
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-down(sm) {
      overflow-x: auto;
      flex-wrap: nowrap;
    }
  }
  .topics-header {
    font-family: "Flama", $fallback-sans-serif-fonts;
    font-weight: 200;
    white-space: nowrap;
    @include color('black')
  }

  .topic {
    margin: 4px 5px 0 0;
    display: flex;
    white-space: nowrap;
    cursor: pointer;
    @include color('primary');
    @include media-breakpoint-down(sm) {
      @include h-scroll-item;
    }
  }
}
</style>
