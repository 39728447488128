<template>
  <figure :class="[$props.styleClass]">
    <div
      :class="`img-wrapper ${orientationClass} ${imgWrapperClasses}`"
      :style="`${imgWrapperStyle}${paddingBottomStyle}`">
      <LazyImage
        @orientation="setOrientation"
        @naturalHeight="setNaturalHeight"
        :alt="alt"
        :source="fallback || source"
        :source-set="_sourceSet"
        :sizes="sizes"
        :is-portrait="isPortrait"></LazyImage>
    </div>
    <figcaption
      v-if="caption"
      v-html="getCaption()"
      class="figure-caption"></figcaption>
  </figure>
</template>
<script>
import LazyImage from '../LazyImage';
import { versions } from './imageVersions';
import { extractTextFromHtml } from 'global-utils';

export default {
  name: 'card-image',
  components: {
    LazyImage,
  },
  props: {
    placeholder: {
      type: Boolean,
      default: false,
    },
    placeholderSource: String,
    source: String,
    fallback: String,
    sizes: String,
    alt: String,
    caption: String,
    classes: String,
    eager: Boolean,
    caller: String,
    isPortrait: {
      type: Boolean,
      default: false,
    },
    cropHeight: Number,
    cropWidth: Number,
    isFreeCrop: Boolean,
    imgWrapperClasses: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      orientationClass: '',
      imgWrapperStyle: '',
      paddingBottomStyle: ''
    };
  },
  computed: {
    _sourceSet() {
      return versions.map(ver => `${this.source}?image_version=${ver} ${ver}w`).join(', ');
    }
  },
  mounted() {
    if (this.cropWidth && this.isFreeCrop) {
      const ratio = (this.cropHeight / this.cropWidth) * 100;
      this.paddingBottomStyle = `padding-bottom:${parseInt(ratio, 10)}%;`;
    }
  },
  methods: {
    getCaption() {
      return extractTextFromHtml(this.caption) || '';
    },
    setOrientation(orientation) {
      if (this.caller === 'inline-picture') {
        this.orientationClass = `card-${orientation}`;
      }
    },
    setNaturalHeight(height) {
      if (this.caller === 'inline-picture' || this.caller === 'tw-30yrs') {
        this.imgWrapperStyle = `height: ${height}px;`;
      }
    }
  }
};
</script>
