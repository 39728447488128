<template>
  <ul
    v-if="articleList && articleList.length > 0"
    class="unstyled-list">
    <li
      v-for="(articleData) in articleList"
      :key="articleData.order"
      class="p-2">
      <a
        :href="articleData.articleUrl"
        v-html="articleData.articleTitle"
        class="text-reset">
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'news-list',
  props: {
    articleList: {
      type: Array,
      required: true
    }
  }
};
</script>
