import { moment } from './momentInitializer';

export class GDate {
  constructor({
    date = Date.now(),
    formatAs = 'D MMMM YYYY H:mm',
    zone = 'GMT',
    locale = 'en',
    includeZone = false,
  } = {}) {
    if (date instanceof Date) {
      this.date = date;
    } else if (typeof date === 'string') {
      this.date = new Date(date);
    } else {
      this.date = Date.now();
    }
    this.formatAs = formatAs;
    this.zone = zone;
    this.locale = locale;
    this.includeZone = includeZone;
  }

  format({ date, formatAs, zone, locale, includeZone } = {}) {
    date = date || this.date;
    formatAs = formatAs || this.formatAs;
    zone = zone || this.zone;
    locale = locale || this.locale;
    includeZone = typeof includeZone !== 'undefined' ? includeZone : this.includeZone;

    moment.tz.setDefault(zone);
    moment.locale(locale);
    if (moment(date).isValid()) {
      return moment(date).format(formatAs) + (includeZone ? ` ${zone}` : '');
    }
    console.error(`Invalid date/format(${formatAs}): ${date}`);
    return '';
  }
}

export default GDate;
