<template>
  <div
    class="action-menu d-flex justify-content-center text-nowrap fs-md">
    <AlertSafeguard
      class="my-alerts-nav">
      <a
        :target="isStandaloneView ? '_parent' : false"
        href="/alerts">
        <div
          v-if="mounted && isUserLoggedIn"
          class="d-none d-sm-inline-block alert-action-text mr-2">{{ __('my.alerts') }}</div>
        <button
          type="button"
          class="action-button">
          <FontAwesomeIcon :icon="faBell"/>
        </button>
      </a>
    </AlertSafeguard>
    <ActionMenuItem
      :icon="faUser"
      v-if="mounted && isUserLoggedIn && !$pubCtx.isFreePublication"
      of="user"/>
    <ActionMenuItem
      :icon="faSearch"
      of="search"
      class="d-none d-lg-block"/>
    <EPaperMenuItem
      class="d-lg-none epaper"/>
    <DarkModeMenuItem
      class="d-none darkmode"/>
    <ActionMenuItem
      :icon="faBars"
      :full-bg="true"
      of="fullMenu"
      class="hamburger"/>
  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSearch, faBars, faBell, faUser, faNewspaper } from '@fortawesome/pro-solid-svg-icons';
import { checkUserLoginCookie } from 'global-utils';
import { AlertSafeguard } from 'global-components';
import ActionMenuItem from './ActionMenuItem';
import EPaperMenuItem from './EPaperMenuItem';
import DarkModeMenuItem from './DarkModeMenuItem';
export default {
  name: 'action-menu',
  components: {
    ActionMenuItem,
    AlertSafeguard,
    EPaperMenuItem,
    DarkModeMenuItem,
    FontAwesomeIcon,
  },
  inject: ['isStandaloneView'],
  data() {
    return {
      hasDropDownMenu: true,
      isIPUser: false,
      isUserLoggedIn: checkUserLoginCookie(this),
      mounted: false,
      faSearch,
      faBell,
      faBars,
      faUser,
      faNewspaper,
    };
  },
  mounted() {
    this.isUserLoggedIn = checkUserLoginCookie(this);
    this.mounted = true;
  }
};
</script>
<style lang="scss">
.alert-action-text {
  vertical-align: top;
  margin-top:0.3rem;
}
.alert-action-text:hover {
  text-decoration: underline;
}
</style>
