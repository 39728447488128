<template>
  <ArchiveFacet
    v-bind="$props"
    @update="clearDateRange">
    <template v-slot:prependOption="{ facetName }">
      <li class="form-check mb-2">
        <input
          id="all-dates"
          :name="facetName"
          :checked="isAll"
          @change="selectAll"
          class="form-check-input"
          type="radio">
        <label
          for="all-dates"
          class="form-check-label fs-md pl-3">
          {{ __('all') }}
        </label>
      </li>
    </template>
    <template v-slot:appendOption>
      <date-picker
        v-model="range"
        :placeholder="__('select.date.range')"
        :lang="lang"
        @input="updateDateRange"
        @clear="updateDateRange"
        class="date-range-picker"
        range
        clearable
        value-type="DD.MM.YYYY"
        format="DD/MM/YYYY"/>
    </template>
  </ArchiveFacet>
</template>
<script>
import ArchiveFacet from './ArchiveFacet';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/nb';

export default {
  name: 'archive-date-facet',
  components: {
    ArchiveFacet,
  },
  props: {
    ...ArchiveFacet.props,
    labelFilter: {
      type: Function,
      default: function (val) {
        return this.$i18n(val.toLowerCase());
      }
    }
  },
  data() {
    let [start, end] = [null, null];
    if (this.$route.query.publishdate) {
      [start, end] = this.$route.query.publishdate.split('-');
    }
    return {
      range: [start, end],
      lang: this.$pubCtx.language === 'no' ? 'nb' : 'en'
    };
  },
  computed: {
    isAll() {
      return !this.$store.state.search.query.publishdate ||
        !this.$store.state.search.query.publishdate.length;
    },
  },
  methods: {
    selectAll() {
      this.updateDateRange();
      this.clearDateRange();
    },
    updateDateRange([start, end] = [null, null]) {
      const publishdate = start && end ? `${start}-${end}` : undefined;
      this.$store.commit('search/query', { publishdate });
      this.$emit('update');
    },
    clearDateRange() {
      this.range = [null, null];
      this.$emit('update');
    },
  },
};
</script>
