<template>
  <div
    v-if="mounted"
    class="logout-form">
    <div
      v-if="loading"
      class="loader">
      <FontAwesomeIcon
        :icon="spinner"
        spin
        pulse
        size="9x"/>
    </div>
    <div class="mb-4">
      <a
        v-if="!hideMyPageLink"
        :href="myProfile"
        :target="isStandaloneView ? '_parent' : false"
        v-text="__('my.page')"></a>
    </div>
    <div
      v-if="showMyCompanyLink"
      class="mb-4">
      <a
        :href="myCompany"
        :target="isStandaloneView ? '_parent' : false"
        v-text="__('my.company')"></a>
    </div>
    <div class="mb-4">
      <a
        @click="logoutThisSite"
        v-text="__('logout')"
        href="javascript:void(0)"></a>
    </div>
    <div
      v-if="entireNhstLogout"
      class="nhst-id-logout-form">
      <div
        @click="expandNhstIdLogout = !expandNhstIdLogout"
        class="mb-1">
        <span
          class="mr-2">{{ __('nhst.id.logout') }}</span>
        <span
          id="toggle-arrow-icon"
          :class="['arrow', { expand: expandNhstIdLogout }]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="9"
            viewBox="0 0 12 7"
            fill="none">
            <path
              d="M9.9624 0.492319L11.2041 1.8411L6.06515 6.57224L0.804199 1.8488L2.029 0.484619L6.04968 4.09451L9.9624 0.492319Z"
              fill="#336633"/>
          </svg>
        </span>
      </div>
      <div>
        <transition
          name="expand"
          mode="out-in">
          <div
            v-show="expandNhstIdLogout"
            class="mt-3">
            <p class="text-sm-left">{{ __('nhst.id.logout.description') }}</p>
            <button
              @click="logoutThisDevice"
              type="button"
              class="btn-primary-red mb-4">{{
                __('nhst.id.logout')
              }}
            </button>
            <div class="d-flex flex-wrap justify-content-between flex-grow-1">
              <div
                :key="`${pub}-monochrome-logo`"
                v-for="pub in pubs"
                class="nhst-id-img-wrapper">
                <img
                  :src="`${$cdn.resource(`gfx/${pub.toLowerCase()}/monochrome-logo.png`)}`"
                  :alt="`${pub}`">
              </div>
              <div class="nhst-id-img-wrapper">
                <img
                  :src="`${$cdn.resource(`gfx/dn-media-logo-short.png`)}`"
                  :alt="`DN Media Group`">
              </div>
            </div>
            <a
              :href="__('nhst.id.definition.link')"
              class="text-sm-left"
              target="_blank"
              rel="noopener">{{ __('nhst.id.popup.definition') }}</a>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import {
  isEmpty,
  _isNil,
  alertLocalStorageUtil,
  companyAccessLocalStorageUtil,
} from 'global-utils';

export default {
  name: 'user-logout-menu-item',
  components: {
    FontAwesomeIcon
  },
  inject: ['isStandaloneView'],
  data() {
    const pubs = [
      'recharge',
      'dn',
      'tradewinds',
      'upstream',
      'intrafish_com',
      'fiskeribladet',
      'europower'
    ];
    return {
      myProfile: this.$store.state.publication.myprofile,
      myCompany: this.$store.state.publication.mycompany,
      hideMyPageLink: this.$store.state.publication.hideMyPageLink,
      expandNhstIdLogout: false,
      pubs,
      mounted: false,
      loading: false,
      spinner: faSpinner,
      entireNhstLogout: true,
    };
  },
  computed: {
    publication() {
      return this.$store.state.publication;
    }
  },
  asyncComputed: {
    showMyCompanyLink() {
      return this.getCompanyAdminAccess();
    }
  },
  mounted() {
    const pubName = this.publication.name === 'intrafish_no' ? 'intrafish_com' : this.publication.name;
    this.pubs = [pubName, ...this.pubs.filter(pub => pub !== pubName)];
    this.mounted = true;
  },
  methods: {
    async getCompanyAdminAccess() {
      const companyAccess = await companyAccessLocalStorageUtil.getItem();
      if (!_isNil(companyAccess)) {
        return !isEmpty(companyAccess);
      }

      try {
        const response = await axios.get(`${this.getCompanyAccessCheckEndpoint()}?t=${new Date().getTime()}`);
        console.info(`Found company access: ${JSON.stringify(response.data)}`);
        await companyAccessLocalStorageUtil.setItem(response.data);
        return true;
      } catch (e) {
        console.error(`[Company access not found] ${e}`);
        await companyAccessLocalStorageUtil.setItem({});
      }
      return false;
    },
    async logoutThisDevice(e) {
      e.preventDefault();
      return this.doLogout('THIS_DEVICE');
    },
    async logoutThisSite(e) {
      e.preventDefault();
      return this.doLogout('JUST_HERE');
    },
    async doLogout(where) {
      await companyAccessLocalStorageUtil.clearStorage();
      await alertLocalStorageUtil.clearStorage();
      window.top.postMessage({ logout: true }, '*');
      this.loading = true;

      try {
        let maxCnt = 10;
        while (!window._nhstFeatures && maxCnt >= 0) {
          await new Promise(resolve => setTimeout(resolve, 500));
          maxCnt--;
        }
        const authServices = window._nhstFeatures.serviceFactory.getAuthServices();
        await authServices.logout(where);
      } catch (err) {
        console.error(`[Logout Error] ${err}`);
      }

      this.loading = false;
      window.top.location.href = '/';
    },
    getCompanyAccessCheckEndpoint() {
      return this.$store.state.publication.companyAccessCheckEndpoint;
    }
  }
};
</script>
<style lang="scss" scoped>

@import 'src/assets/scss/_themes';

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

@each $pub, $config in $themes {
  .#{$pub} {
    $primary: map-deep-get($config, 'primary');

    .loader {
      color: $primary;

      svg {
        color: $primary;
        fill: $primary;
      }
    }

    a {
      color: $primary;

      &:hover {
        filter: brightness(150%);
      }
    }
  }
}

.logout-form {
  a {
    text-decoration: underline;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    z-index: 2000;
    position: fixed;
    left: 0;
    top: 0;
    background-color: white;
    opacity: 0.8;
  }

  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #343434;

  .text-sm-left {
    font-size: 12px;
    line-height: 14px;
    justify-content: left;
    white-space: normal !important;
  }

  .nhst-id-logout-form {
    .arrow {
      svg {
        transition: transform .2s ease;
      }
    }

    .arrow.expand {
      svg {
        transform: rotate(180deg);
      }
    }

    .nhst-id-img-wrapper {
      margin-right: 5px;
      padding-top: 5px;
      padding-bottom: 5px;

      &:nth-of-type(4n) {
        margin-right: 0;
      }

      img {
        width: 70px;
        height: 35px;
      }
    }
  }

  $red: #CC2128;

  .btn-primary-red {
    background-color: $red;
    border-color: $red;
    color: #FFFFFF;
    border-radius: 4px;
    height: 36px;
    width: 100%;
    font-weight: 600;

    &:hover {
      background-color: #FFFFFF;
      border-color: $red;
      color: $red;
    }
  }
}

.expand-enter-active, .expand-leave-active {
  transition: all 0.2s ease-out;
  overflow: hidden;
}

.expand-enter, .expand-leave-to {
  height: 0;
}

.expand-enter-to, .expand-leave {
  height: 100px;
}

</style>
