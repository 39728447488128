<template>
  <div>
    <ArticleImageByline v-if="hasImageByline"/>
    <ArticleTextByline v-else/>
  </div>
</template>

<script>
import ArticleTextByline from './TextByline';
import ArticleImageByline from './ImageByline';

export default {
  name: 'article-byline',
  components: {
    ArticleTextByline,
    ArticleImageByline,
  },
  computed: {
    hasImageByline() {
      const categoryMatch = this.$pubCtx.imageBylineCategories.includes(this.$store.state.article.mainCategory.id);
      const hasProfileImage = this.$store.state.article.authors.find(author => (author.options && author.options.profileImage));
      return categoryMatch && hasProfileImage;
    },
  }
};
</script>
