<template>
  <div>
    <div class="switch-checkbox">
      <input
        id="checkbox"
        @change="toggleTheme"
        type="checkbox"
        class="switch-checkbox">
      <label
        for="checkbox"
        class="switch-label">
        <div
          :class="{ 'switch-toggle-checked': userTheme === 'dark-theme', 'switch-toggle-unchecked': userTheme === 'light-theme' }"
          class="switch-toggle"></div>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    setTheme(theme) {
      localStorage.setItem('user-theme', theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },
    toggleTheme() {
      const activeTheme = localStorage.getItem('user-theme');
      if (activeTheme === 'light-theme') {
        this.setTheme('dark-theme');
      } else {
        this.setTheme('light-theme');
      }
    },
  }
};
</script>
