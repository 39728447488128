<template>
  <div class="wrap">
    <div class="row">
      <div class="col-sm-3">
        <h3 class="group_header">
          <span>500</span>
        </h3>
        <h3 class="group_header">
          <span>{{ __('error500.title') }}</span>
        </h3>
      </div>
      <div class="col-sm-6">
        <h1 class="title">{{ __('error500.info') }}</h1>
        <div class="leadtext">
          <div v-html="__('error500.message')"></div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Page } from 'global-components';

export default {
  extends: Page
};
</script>
