<template>
  <div
    v-if="mounted && !isUserLoggedIn && !$pubCtx.isFreePublication"
    class="login-menu-item io-subscription-block mr-3">
    <a
      id="btn-nhst-login"
      v-text="__('login')"
      href="javascript:void(0)"
      class="fs-md io-subscription-button">
    </a>
  </div>
</template>
<script>
import { checkUserLoginCookie } from 'global-utils';

export default {
  name: 'login-menu-item',
  data() {
    return {
      isUserLoggedIn: checkUserLoginCookie(this),
      mounted: false
    };
  },
  mounted() {
    this.isUserLoggedIn = checkUserLoginCookie(this);
    this.mounted = true;
  }
};
</script>

<style lang="scss" scoped>
  .login-menu-item {
    margin-top: 0.1rem;
  }
</style>
